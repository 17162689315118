import * as types from '../constants/capabilities'

const initialState = {
    hasLogo: true,
    hasWatermark: false,
    hasStudioMode: true,
    hasAudioMixer: false,
    isAbUpdate: true,
    useLegacyPublisher: false,
    hasRtmpAuth: false,
    canDefineDisplayOutput: false,
    canDefineResolution: false,
    canDefineLayout: false,
    canDefineStudio: false,
    hasFactoryReset: false,
    averageMoPerHour: 1024 * 2.5,
    isV3: null,
    canUploadNoauth: false
}
export default function cameraReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.HAS_LOGO:
            return { ...state, hasLogo: action.hasLogo }

        case types.HAS_WATERMARK:
            return { ...state, hasWatermark: action.hasWatermark }

        case types.HAS_STUDIO_MODE:
            return { ...state, hasStudioMode: action.hasStudioMode }

        case types.HAS_AUDIO_MIXER:
            return { ...state, hasAudioMixer: action.hasAudioMixer }

        case types.IS_AB_UPDATE:
            return { ...state, isAbUpdate: action.isAbUpdate }

        case types.USE_LEGACY_PUBLISHER:
            return { ...state, useLegacyPublisher: action.useLegacyPublisher }

        case types.HAS_RTMP_AUTH:
            return { ...state, hasRtmpAuth: action.hasRtmpAuth }

        case types.CAN_DEFINE_DISPLAY_OUTPUT:
            return { ...state, canDefineDisplayOutput: action.canDefineDisplayOutput }

        case types.CAN_DEFINE_RESOLUTION:
            return { ...state, canDefineResolution: action.canDefineResolution }

        case types.CAN_DEFINE_LAYOUT:
            return { ...state, canDefineLayout: action.canDefineLayout }

        case types.CAN_DEFINE_STUDIO_CAPTURE_MODE:
            return { ...state, canDefineStudio: action.canDefineStudio }

        case types.HAS_FACTORY_RESET:
            return {...state, hasFactoryReset: action.hasFactoryReset}

        case types.AVERAGE_MO_PER_HOUR:
            return { ...state, averageMoPerHour: action.averageMoPerHour }

        case types.IS_V3:
            return { ...state, isV3: action.isV3 }

        case types.RESET_TO_DEFAULTS:
            return { ...initialState }

        case types.CAN_UPLOAD_NOAUTH:
            return { ...state, canUploadNoauth: action.canUploadNoauth }


        default:
            return state || initialState;
    }
}
