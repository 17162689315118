import React from 'react'
import { Platform, View, StyleSheet } from 'react-native'

import { bindActionCreators } from 'redux'
import * as globalsActions from '../actions/globals'
import * as utils from '../utils'
import { connect } from 'react-redux'

import IconedButton from '../components/IconedButton'

// Icons
var backIcon = require('../design/icons/back.png')
var studioIcon = require('../design/icons/switch_mode.png')
var publishIcon = require('../design/icons/option_broadcast.png')
var cameraIcon = require('../design/icons/option_camera.png')
var micIcon = require('../design/icons/mic.png')
var networkIcon = require('../design/icons/option_network.png')
var myKastBoxIcon = require('../design/icons/option_my_kast_box.png')
var logoIcon = require('../design/icons/option_checklist.png')
var watermarkIcon = require('../design/icons/option_checklist.png')
var backgroundIcon = require('../design/icons/option_template.png')
var displayOutputIcon = require('../design/icons/option_studio.png')
var resolutionIcon = require('../design/icons/option_resolution.png')
var layoutModeIcon = require('../design/icons/option_layout.png')
class OptionsPanel extends React.Component {

    getIcon(image, onPress, label) {
        return (
            <IconedButton
                image={image}
                onPress={onPress}
                label={label}
                style={styles.icon}
                maxIconSize={this.props.maxIconSize}
                key={label}
            />
        )
    }

    getIcons() {
        const {
            isStudio,
            onPressStudioButton,
            onPressCaptureButton
        } = this.props

        let icons = []

        icons.push(this.getIcon(
            backIcon,
            () => { this.props.onPressBackButton() },
            'Back'
        ))

        // Studio - Capture
        if (this.props.capabilitiesState.hasStudioMode) {
            icons.push(this.getIcon(
                studioIcon,
                () => { this.props.onPressStudioButton() },
                'Switch Mode'
            ))
        }

        // Camera
        icons.push(this.getIcon(
            cameraIcon,
            () => { this.props.onPressCameraButton() },
            'Camera'
        ))

        // Logo - if allowed
        if (this.props.capabilitiesState.hasLogo) {
            icons.push(this.getIcon(
                logoIcon,
                () => { this.props.onPressLogoButton() },
                'Logo'
            ))
        }

        
        // Watermark - if allowed
        if (this.props.capabilitiesState.hasWatermark) {
            icons.push(this.getIcon(
                watermarkIcon,
                () => { this.props.onPressWatermarkButton() },
                'Watermark'
            ))
        }

        // Network
        icons.push(this.getIcon(
            networkIcon,
            () => { this.props.onNetworkNetworkButton() },
            'Network'
        ))

        // Display output
        if (this.props.capabilitiesState.canDefineDisplayOutput) {
            icons.push(this.getIcon(
                displayOutputIcon,
                () => { this.props.onPressOutputModeButton() },
                'Output Mode'
            ))
        }

        // Audio
        icons.push(this.getIcon(
            micIcon,
            () => { this.props.onPressAudioButton() },
            'Audio'
        ))

        // Background
        icons.push(this.getIcon(
            backgroundIcon,
            () => { this.props.onPressBackgroundButton() },
            'Background'
        ))

        // Publishing
        icons.push(this.getIcon(
            publishIcon,
            () => { this.props.onPressPublishButton() },
            'Publishing'
        ))

        // Resolution
        if (this.props.capabilitiesState.hasFactoryReset) {
            icons.push(this.getIcon(
                resolutionIcon,
                () => { this.props.onPressResolutionButton() },
                'Resolution'
            ))
        }

        // Layout Mode
        if (this.props.capabilitiesState.canDefineLayout) {
            icons.push(this.getIcon(
                layoutModeIcon,
                () => { this.props.onPressLayoutModeButton() },
                'Layout Mode'
            ))
        }

        // My kast box
        icons.push(this.getIcon(
            myKastBoxIcon,
            () => { this.props.onPressAboutButton() },
            'My Kast Box'
        ))

        return icons
    }

    render() {
        const { isHorizontal } = this.props
        const buttons = this.getIcons()

        const halfSize = Math.trunc(buttons.length / 2)
        const isOdd = buttons.length % 2 ? true : false

        const iconsGroupA = buttons.slice(0, halfSize)
        const iconsGroupB = buttons.slice(halfSize, halfSize * 2)
        const iconsGroupC = buttons.slice(halfSize * 2, halfSize * 2 + 1)
        return (
            <View style={{
                flex: 1,
                backgroundColor: '#262626',
            }}>
                <View onLayout={this.props.onLayout} style={{
                    flex: halfSize,
                    flexDirection: (isHorizontal ? 'column' : 'row')
                }}>
                    <View style={(isHorizontal ? styles.row : styles.column)}>
                        {iconsGroupA.map((icon) => { return icon; })}
                    </View>

                    <View style={(isHorizontal ? styles.row : styles.column)}>
                        {iconsGroupB.map((icon) => { return icon; })}
                    </View>

                </View>
                {(isOdd) ?
                    <View style={{ flex: 1 }}>
                        <View style={(isHorizontal ? styles.row : styles.column)}>
                            {iconsGroupC.map((icon) => { return icon; })}
                        </View>
                    </View>
                    : null}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    column: {
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center'
    },
    icon: {
        flex: 1,
        margin: utils.moderateScale(0)
    },
    containerScroll: {
        flex: 1,
        padding: utils.moderateScale(10)
    }
})

function mapStateToProps(state) {
    return {
        globalsState: state.globalsReducer,
        capabilitiesState: state.capabilitiesReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalsActions: bindActionCreators(globalsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPanel)