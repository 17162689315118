import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'


export default class CommonListItem extends React.Component {

    render() {
        if (this.props.onClick) {
            return (
                <TouchableOpacity
                    key={this.props.item.key}
                    onPress={ () => this.props.onClick(this.props.item) }>
                    <Text style={ styles.text }>{ this.props.item.key }</Text>
                </TouchableOpacity>
            )
        } else {
            return (
                <Text
                    style={ styles.text }
                    key={this.props.item.key}
                >
                    { this.props.item.key }
                </Text>      
            )      
        }
    }
}

const styles = StyleSheet.create({
    text: {
        margin: 15,
        fontSize: 15,
        color: '#FFFFFF'
    }
})