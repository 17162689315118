import React from 'react'
import { View, Text, Platform, StyleSheet, TouchableOpacity } from 'react-native'
import { Actions } from '../../../navigation/Navigator'
import WebVideoPlayer from '../../../components/optionsPanel/video/WebVideoPlayer'
import UsbExporter from '../../../components/UsbExporter'

import { connect } from 'react-redux'

import IconedButton from '../../../components/IconedButton'
import Prompt from 'react-native-prompt';
import Messenger from '../../../components/Messenger'

import ServicesExporter from "../../../components/ServicesExporter"
import ModalContainer from "../../../containers/ModalContainer"

import * as utils from '../../../utils'

const PropTypes = require('prop-types')

class VideoDetailContainer extends React.Component {

    mount = false

    constructor(props, context) {
        super(props, context)
        let session;
        try {
            if (Platform.OS == "web")
                session = this.props.history.location.state.session
            else
                session = this.props.session
        } catch (error) {
            Actions.replace("videoOverlay", { videoId: this.props.match.params.videoId });
        }

        this.state = {
            connectors: undefined,
            session: session,
            titlePromptVisible: false,
            exporterVisible: false,
            version: undefined
        }


        this.context.webSocketClient.system.onAbout(
            this.onAbout, this
        )

        this.context.webSocketClient.system.about()
    }

    onAbout(data) {
        this.setState({
            version: data.version.replace(/\D/g, '')
        })
    }

    componentWillMount() {
    }

    componentWillUnmount() {
        this.mount = false

        this.context.webSocketClient.system.offAbout(
            this.onAbout
        )
    }

    componentDidMount() {
        this.mount = true
    }

    remove() {
        this.context.webSocketClient.video.remove({
            id: this.state.session.id
        })

        if (this.props.removeSessionFromList)
            this.props.removeSessionFromList()

        Actions.pop()
    }

    confirmRemove() {
        Messenger.prompt(
            'Delete video', 'Are you sure you want to remove this video ?', [
                { text: 'Cancel', style: 'cancel' },
                { text: 'OK', onPress: this.remove.bind(this) },
            ], { cancelable: false }
        )
    }

    confirmExport(device) {
        Messenger.prompt(
            'Export video', 'Are you sure you want to export this video ?', [
                { text: 'Cancel', style: 'cancel' },
                { text: 'OK', onPress: () => this.export(device) },
            ], { cancelable: false }
        )
    }

    editTitle() {
        this.setState({ titlePromptVisible: true })
    }

    titleEdited(newTitle) {
        this.context.webSocketClient.video.update({
            id: this.state.session.id,
            title: newTitle
        })

        if (this.props.updateTitleInList)
            this.props.updateTitleInList(newTitle)
        else {
            let state = { ...this.state }
            state.session.title = newTitle
            this.setState(state);
        }

        this.hideTitlePrompt()
    }

    hideTitlePrompt() {
        this.setState({ titlePromptVisible: false })
    }

    onCancelExport() {
        this.setState({ exporterVisible: false })
    }

    exportVideo() {
        this.setState({ exporterVisible: true })
    }
    
    onExportStarted() {
        this.setState({ exporterVisible: false })
    }

    renderServicesExporter() {
        if (this.state.version >= 201807042049 && !this.props.capabilitiesState.useLegacyPublisher) {
            return <ServicesExporter
                isVisible={this.state.exporterVisible}
                videos={[this.state.session.id]}
                onCancel={this.onCancelExport.bind(this)}
                onExportStarted={this.onExportStarted.bind(this)}
                usbOnly={false}
                connectors={this.state.connectors}
            />
        } else {
            return <ServicesExporter
                isVisible={this.state.exporterVisible}
                videos={[this.state.session.id]}
                onCancel={this.onCancelExport.bind(this)}
                onExportStarted={this.onExportStarted.bind(this)}
                usbOnly={true}
            />
        }
    }

    render() {
        if (!this.state.session)
            return (<View><Text>No session information</Text></View>)

        const videoUri = this.context.mediaServer + this.state.session.url


        const { record_stopped_at, record_started_at } = this.state.session
        let duration = 0
        if (record_stopped_at && record_started_at) {
            let milli = record_stopped_at - record_started_at
            duration = utils.getVideoDuration(milli)
        }

        let recorded_at = undefined
        let date_offset = undefined
        
        if (record_started_at) {
            recorded_at = utils.getVideoRecordDate(record_started_at)
            date_offset = utils.getDateOffset(new Date(record_started_at))
        }

        const sessionNameEditor = <Prompt
            title="Edit session name"
            placeholder="Session name"
            defaultValue={this.state.session.title}
            visible={this.state.titlePromptVisible}
            onCancel={() => this.hideTitlePrompt()}
            onSubmit={(value) => this.titleEdited(value)}
        />
        let platformSessionNameEditor;
        if (Platform.OS === "web")
        {
            platformSessionNameEditor = (
                <ModalContainer
                    visible={this.state.titlePromptVisible}
                    overlayClassName="session-name-editor-modal-container"
                >
                    {sessionNameEditor}
                </ModalContainer>
            )
        } else {
            platformSessionNameEditor = sessionNameEditor;
        }

        return (
            <View style={{ flex: 1 }}>
                <View style={styles.container}>
                    <View style={styles.videoTitleWrapper}>
                        <Text style={styles.videoTitle}>{utils.getVideoName(this.state.session)}</Text>
                        <Text style={styles.videoDetails}>Duration : {duration}</Text>
                        <Text style={styles.videoDetails}>Record date : {date_offset} - {recorded_at}</Text>
                    </View>

                    <WebVideoPlayer url={videoUri} />
                    
                </View>
                
                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.confirmRemove.bind(this)}>Remove</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.exportVideo.bind(this)}>Export</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.editTitle.bind(this)}>Edit title</Text>
                    </TouchableOpacity>
                </View>
                {platformSessionNameEditor}
                {this.renderServicesExporter()}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    videoTitleWrapper: {
        justifyContent: 'center',
    },
    videoTitle: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(15),
        textAlign: 'center',
        marginVertical: utils.moderateScale(10)
    },
    videoDetails: {
        color: '#888888',
        fontSize: utils.moderateScale(13),
        textAlign: 'center',
        marginVertical: utils.moderateScale(5)
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#262626',
        justifyContent: 'space-between',
        paddingBottom: utils.moderateScale(15)
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10)
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(14),
    }
})

VideoDetailContainer.contextTypes = {
    mediaServer: PropTypes.string,
    webSocketClient: PropTypes.object
};

function mapStateToProps(state) {
    return {
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetailContainer)