import { Dimensions } from 'react-native'
import Messenger from './components/Messenger'

function pad2(number) {
    return (number < 10 ? '0' : '') + number
}

export function equals(a, b, key) {
    return a[key] === b[key];
}

export function isValidKastSSID(ssid) {
    return ssid.startsWith('kast')
}

export function filterAccessPoints(accessPoints) {
    let validAccessPoints = []
    accessPoints.forEach(accessPoint => {
        if (accessPoint.SSID.startsWith('kast'))
            validAccessPoints.push(accessPoint)
    })

    return validAccessPoints
}

export function getVideoName(session, showTime = false) {
    const takeStr = "Take " + session.takeNb
    let title = ((session.title) ? session.title : undefined)
    title = ((title !== undefined) ? takeStr + ' (' + title + ')' : takeStr)

    return title
}

export function getVideoRecordDate(milli) {

    var date = new Date(milli)
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    var date = new Date(date.getTime() + userTimezoneOffset);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

export function getDateOffset(date) {
    const today = new Date()
    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)

    if (date.toLocaleDateString() == today.toLocaleDateString())
        return 'Today'

    else if (date.toLocaleDateString() == yesterday.toLocaleDateString())
        return 'Yesterday'

    return date.toLocaleDateString('en-US', { weekday: "long", year: "numeric", month: "long", day: "numeric" })
}

export function getStatusBarVideoDuration(milli) {
    const time = new Date(milli);

    const minutes = time.getUTCMinutes() + (time.getUTCHours() * 60)
    const seconds = time.getUTCSeconds()
    const milliseconds = time.getUTCMilliseconds()

    return pad2(minutes) + '\'' + pad2(seconds) + '"'
}

export function getVideoDuration(duration, showMilli = true, padNumbers = false) {

    let milliseconds = parseInt(duration % 1000);
    let seconds = parseInt((duration / 1000) % 60);
    let minutes = parseInt((duration / (1000 * 60)) % 60);
    let hours = parseInt(duration / (1000 * 60 * 60));
    
    var time = "";

    if (hours != 0) {
        time = hours + "h ";
    }

    if (minutes != 0 || time !== "") {
        minutes = (padNumbers && minutes < 10 && time !== "") ? "0" + minutes : String(minutes);
        time += minutes + "m ";
    }

    if (seconds != 0 || time !== "") {
        seconds = (padNumbers && seconds < 10 && time !== "") ? "0" + seconds : String(seconds);
        time += seconds + "s ";
    }

    if (showMilli && milliseconds != 0 && seconds == 0) {
        if (time === "") {
            time = milliseconds + "ms";
        }
        else {
            time += (padNumbers && milliseconds < 10) ? "0" + milliseconds : String(milliseconds) + "ms";
        }
    }

    return time;
}

export function getGridSize() {
    return 20
}

export function getSize() {
    const screenSize = Dimensions.get('screen')
    const isHorizontal = screenSize.width > screenSize.height

    return {
        height: screenSize.height,
        width: screenSize.width,
        scale: screenSize.scale,
        fontScale: screenSize.fontScale,
        isHorizontal
    }
}

export function getGridUnit() {
    const gridHeight = getSize().height / getGridSize()
    const gridWidth = getSize().width / getGridSize()
    return Math.min(gridHeight, gridWidth)
}

export function getButtonGridUnit() {
    return getGridUnit() * 1.5
}

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

export function scale(size) {
    return Math.min(getSize().width, getSize().height) / guidelineBaseWidth * size
}

export function moderateScale(size, factor = 0.5) {
    return size + (scale(size) - size) * factor
}

export function startInterval(callback, timer) {
    callback()
    return setInterval(callback, timer)
}

export function humanFileSize(bytes, si) {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

// Common updating functions
let updatePromptVisible = false
let sessionState = undefined
let context = undefined
let updateMethod = undefined

function cancelUpdate() {
    updatePromptVisible = false
}

function onCurrentSession(session) {
    const sessionState = session.state

    if (sessionState == "LI") {
        if (!updatePromptVisible) {
            updatePromptVisible = true
            Messenger.prompt(
                'Currently recording',
                'Would you like to stop recording and start updating now ?',
                [
                    { text: 'Cancel', onPress: cancelUpdate, style: 'cancel' },
                    { text: 'Stop and update', onPress: updateMethod },
                ],
                { cancelable: false }
            )
        }
    } else {
        updateMethod()
    }

    context.webSocketClient.video.offCurrentSession(
        onCurrentSession
    )
}

function startUpdate() {
    updatePromptVisible = false

    context.webSocketClient.video.onCurrentSession(
        onCurrentSession, this
    )

    context.webSocketClient.video.currentSession()
}

export function onFileSelected(_context, path, device_id) {
    context = _context
    updateMethod = () => {
        context.webSocketClient.video.stopRecord()
        context.webSocketClient.update.fromUsb({
            path, device_id
        })
    }
    if (!updatePromptVisible) {
        startUpdate()
    }
}

export function onUpdateAvailable(_context) {
    context = _context
    updateMethod = () => {
        context.webSocketClient.video.stopRecord()
        context.webSocketClient.update.fromRepository()
    }
    if (!updatePromptVisible) {
        updatePromptVisible = true
        Messenger.prompt(
            'Update available',
            'An update is available, do you want to update now ?',
            [
                { text: 'Cancel', onPress: () => cancelUpdate(), style: 'cancel' },
                { text: 'Update', onPress: () => startUpdate() },
            ],
            { cancelable: false }
        )
    }
}

export function diff(o1, o2) {
    return Object.keys(o2).reduce((diff, key) => {
        if (o1[key] === o2[key]) return diff
        return {
            ...diff,
            [key]: o2[key]
        }
    }, {})
}

export function getPublishingIcon(publisherFileName) {

    const iconsPaths = {
        'publisher_adobeconnect.png': require('./design/icons/publisher_icons/publisher_adobeconnect.png'),
        'publisher_dropbox.png': require('./design/icons/publisher_icons/publisher_dropbox.png'),
        'publisher_ftp.png': require('./design/icons/publisher_icons/publisher_ftp.png'),
        'publisher_googledrive.png': require('./design/icons/publisher_icons/publisher_googledrive.png'),
        'publisher_icloud.png': require('./design/icons/publisher_icons/publisher_icloud.png'),
        'publisher_libcast.png': require('./design/icons/publisher_icons/publisher_libcast.png'),
        'publisher_youtube.png': require('./design/icons/publisher_icons/publisher_youtube.png'),
        'publisher_facebook.png': require('./design/icons/publisher_icons/publisher_facebook.png'),
        'publisher_rtmp.png': require('./design/icons/publisher_icons/rtmp.png'),
        'publisher_pod.png': require('./design/icons/publisher_icons/publisher_pod.png'),
        'publisher_skype.png': require('./design/icons/publisher_icons/publisher_skype.png'),
        'publisher_twitter.png': require('./design/icons/publisher_icons/publisher_twitter.png'),
        'publisher_custom.png': require('./design/icons/publisher_icons/publisher_custom.png'),
        'publisher_webex.png': require('./design/icons/publisher_icons/publisher_webex.png'),
        'publisher_zoom.png': require('./design/icons/publisher_icons/publisher_zoom.png'),
    }

    return iconsPaths[publisherFileName]
}