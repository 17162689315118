import React from 'react'
import { View, Text, StyleSheet, Image, Alert, TouchableHighlight } from 'react-native'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as systemActions from '../../actions/system'

import * as utils from '../../utils'
import Messenger from '../../components/Messenger'

import USBImporter from '../../components/UsbImporter'

import Button from 'react-native-button';

const PropTypes = require('prop-types')

const appLogo = require('../../design/icons/app_icons/Media.xcassets/ios-marketing.imageset/ios-marketing-no-bg.png')
class OptionsAboutContainer extends React.Component {
    mount = false

    constructor(props, context) {
        super(props, context)

        this.state = {
            aboutData: {},
            usbImporterVisible: false,
            hasNetAccess: false,
            count: 0
        }

        this.context.webSocketClient.system.onAbout(
            this.onAbout, this
        )

        this.context.webSocketClient.update.onAvailable(
            this.onUpdateAvailable, this
        )

        this.context.webSocketClient.update.onNoAvailable(
            this.onNoUpdateAvailable, this
        )

        this.context.webSocketClient.context.onUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.context.get()
        this.context.webSocketClient.system.about()
    }

    count(){
        this.setState({count: this.state.count + 1})
        if(this.state.count == 20){
            this.context.webSocketClient.socket.send("system/dev_mode", {});
        }
    }

    componentDidMount() {
        this.mount = true
    }

    componentWillUnmount() {
        this.mount = false

        this.context.webSocketClient.system.offAbout(
            this.onAbout, this
        )

        this.context.webSocketClient.update.offAvailable(
            this.onUpdateAvailable
        )

        this.context.webSocketClient.update.offNoAvailable(
            this.onNoUpdateAvailable
        )

        this.context.webSocketClient.context.offUpdated(
            this.onContextUpdated, this
        )
    }

    onContextUpdated(context) {
        this.setState({ hasNetAccess: context.network.is_connected })
    }

    onUpdateAvailable() {
        utils.onUpdateAvailable(this.context)
    }

    onNoUpdateAvailable() {
        Messenger.notify('No update available')
    }

    onAbout(aboutData) {
        this.setState({ aboutData })
    }

    showCantUpdateError() {
        Messenger.error("Updating can't be done now because an updating process is already running.")
    }

    usbUpdate() {
        if (this.props.systemState.canStartUpdate)
            this.setState({ usbImporterVisible: true })
        else
            this.showCantUpdateError()
    }

    repositoryUpdate() {
        if (this.props.systemState.canStartUpdate)
            this.context.webSocketClient.update.check()
        else
            this.showCantUpdateError()
    }

    factoryResetSender() {
        this.context.webSocketClient.system.factoryReset()
    }

    alertFactoryResetPressed() {
        Alert.alert(
            "All data are going to be deleted, please make sure to save your work", 
            "",
            [

              { text: "cancel", onPress: () => console.log("cancel Pressed") },
              { text: "continue", onPress: () => this.factoryResetSender() }
            ],
            { cancelable: false }
          )
    }

    onFileSelected(path, device_id) {
        utils.onFileSelected(this.context, path, device_id)
    }

    onCancel() {
        this.setState({
            usbImporterVisible: false
        })
    }

    filterEntry(entry) {
        return (entry.is_dir) ? true : entry.name.endsWith('.zip') || entry.name.endsWith('.enc')
    }

    getOnlineUpdateButton() {   
        if (this.state.hasNetAccess) {
            return (
                <Button
                    containerStyle={[styles.buttonContainer]}
                    onPress={this.repositoryUpdate.bind(this)}
                    style={[styles.buttonText]}
                >Online update</Button>
            )
        }
    }


    factoryResetButton() {
        if (this.props.capabilitiesState.hasFactoryReset === true)
            return(
                <Button 
                containerStyle={[styles.buttonContainer]}
                onPress={this.alertFactoryResetPressed.bind(this)}
                style={[styles.buttonText]}
                >Factory Reset</Button>
            )
    }

    render() {
        const { hasNetAccess } = this.state;
        return (
            <View style={styles.container} className="options-about-container">
                    <Image
                        source={appLogo}
                        style={styles.logo}
                        resizeMode='cover'
                    />

                <View style={styles.floatingContainer}>
                        <Text onPress={() => {this.count()}} style={[styles.text, styles.textBig]}>Kast Remote</Text>

                    <View style={styles.subContainer}>
                        <Text style={styles.text}>Kast version : {this.state.aboutData.version}</Text>
                        <Text style={styles.text}>Architecture : {this.state.aboutData.arch}</Text>
                    </View>
                    <View style={styles.buttonsWrapper} className="options-about-container-buttons">
                        {this.getOnlineUpdateButton()}
                        <Button
                            containerStyle={[styles.buttonContainer]}
                            onPress={this.usbUpdate.bind(this)}
                            style={[styles.buttonText]}
                        >USB update...</Button>
                        {this.factoryResetButton()}
                    </View>
                </View>
                <USBImporter
                    isVisible={this.state.usbImporterVisible}
                    onFileSelected={this.onFileSelected.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    filterEntry={this.filterEntry.bind(this)}
                />
            </View>
        )
    }
}

OptionsAboutContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    floatingContainer: {
        alignSelf: 'center',
        flex: 0
    },
    buttonsWrapper: {
        flexDirection: 'row',
        flexShrink: 15,
        justifyContent: 'center'
    },
    buttonContainer: {
        padding: 10,
        backgroundColor: '#555555',
        marginHorizontal: utils.moderateScale(7.5)
    },
    buttonText: {
        color: '#FFFFFF'
    },
    subContainer: {
        marginBottom: utils.moderateScale(15),
    },
    logo: {
        alignSelf: 'center',
        width: '30%',
        height: '30%',
        aspectRatio: 1
    },
    text: {
        color: '#FFFFFF',
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontSize: utils.moderateScale(13)
    },
    textBig: {
        fontSize: utils.moderateScale(15),
        marginBottom: utils.moderateScale(15)
    }
})

function mapStateToProps(state) {
    return {
        systemState: state.systemReducer,
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        systemActions: bindActionCreators(systemActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsAboutContainer)