export const HAS_LOGO = "HAS_LOGO";
export const HAS_WATERMARK = "HAS_WATERMARK";
export const HAS_STUDIO_MODE = "HAS_STUDIO_MODE";
export const HAS_AUDIO_MIXER = "HAS_AUDIO_MIXER";
export const IS_AB_UPDATE = "IS_AB_UPDATE";
export const USE_LEGACY_PUBLISHER = "USE_LEGACY_PUBLISHER";
export const RESET_TO_DEFAULTS = "RESET_TO_DEFAULTS";
export const HAS_RTMP_AUTH = "HAS_RTMP_AUTH";
export const CAN_DEFINE_DISPLAY_OUTPUT = "CAN_DEFINE_DISPLAY_OUTPUT";
export const CAN_DEFINE_RESOLUTION = "CAN_DEFINE_RESOLUTION";
export const CAN_DEFINE_STUDIO_CAPTURE_MODE = "CAN_DEFINE_STUDIO_CAPTURE_MODE";
export const CAN_DEFINE_LAYOUT = "CAN_DEFINE_LAYOUT";
export const HAS_FACTORY_RESET = "HAS_FACTORY_RESET";
export const AVERAGE_MO_PER_HOUR = "AVERAGE_MO_PER_HOUR";
export const IS_V3 = "IS_V3";
export const CAN_UPLOAD_NOAUTH = "CAN_UPLOAD_NOAUTH";