import React from 'react'
import { View, Text, ScrollView, KeyboardAvoidingView, StyleSheet, TouchableOpacity } from 'react-native'
import { TextField } from 'react-native-material-textfield'
import { Switch } from 'react-native-switch';

import { Actions } from 'react-native-router-flux'
import * as utils from '../../utils'

import { Platform } from 'react-native'

const PropTypes = require('prop-types')

export default class OptionsNetworkContainer extends React.Component {
    mount = false
    tintColor = null
    animationSpeed = 100

    constructor(props, context) {
        super(props, context)

        if (Platform.OS === 'android') {
            this.animationSpeed = 0
        }

        this.state = {
            autoConf: true,
            ipAddress: undefined,
            ipAddressError: '',
            subnetMask: undefined,
            subnetMaskError: '',
            gateway: undefined,
            gatewayError: '',
            dns: undefined,
            dnsError: '',
            httpProxyServer: undefined,
            httpProxyServerError: '',
            httpsProxyServer: undefined,
            httpsProxyServerError: '',
            macAddress: undefined,
            inputColor: "#888888"
        }

        this.context.webSocketClient.network.onUpdated(
            this.onUpdated, this
        )

        this.context.webSocketClient.network.get()
    }

    componentWillUnmount() {
        this.mount = false

        this.context.webSocketClient.network.offUpdated(
            this.onUpdated
        )
    }

    onUpdated(networkData) {
        if (this.mount && networkData) {
            this.setState({
                autoConf: (networkData.auto === 1) ? true : false,
                ipAddress: networkData.ip,
                subnetMask: networkData.netmask,
                gateway: networkData.gateway,
                dns: networkData.dns,
                macAddress: networkData.mac
            })
            this.refreshInputColor()
        }
    }

    onFocus() {
        this.setState({ inputFocusColor: '#FFFFFF' })
    }

    onBlur() {
        this.setState({ inputFocusColor: '#FFFFFF' })
    }

    componentDidMount() {
        this.mount = true
    }

    componentDidUpdate(oldProps, oldState) {
        if (this.state.autoConf !== oldState.autoConf) {
            this.refreshInputColor()

            if (this.state.autoConf) {
                this.setState({
                    ipAddressError: '',
                    subnetMaskError: '',
                    gatewayError: '',
                    dnsError: ''
                })
            }
        }
    }

    autoConfSwitched(activated) {
        this.setState({ autoConf: activated })
    }

    refreshInputColor() {
        this.setState({
            inputColor: (this.state.autoConf) ? "#888888" : '#FFFFFF'
        })
    }

    save() {
        const invalidIpError = 'IP address is invalid'

        let isValidIP = true
        let isValidSubnetMask = true
        let isValidGateway = true
        let isValidDns = true

        if (!this.state.autoConf) {
            isValidIP = this.isIp(this.state.ipAddress)
            isValidSubnetMask = this.isIp(this.state.subnetMask)
            isValidGateway = this.isIp(this.state.gateway)
            isValidDns = this.isIp(this.state.dns)

            this.setState({
                ipAddressError: (!isValidIP) ? invalidIpError : '',
                subnetMaskError: (!isValidSubnetMask) ? invalidIpError : '',
                gatewayError: (!isValidGateway) ? invalidIpError : '',
                dnsError: (!isValidDns) ? invalidIpError : ''
            })
        }

        if (isValidIP && isValidSubnetMask && isValidGateway && isValidDns) {
            this.context.webSocketClient.network.set({
                auto: this.state.autoConf,
                ip: this.state.ipAddress,
                netmask: this.state.subnetMask,
                gateway: this.state.gateway,
                dns: this.state.dns,
            })

            Actions.pop()
        }
    }

    isIp(ip) {
        if (ip) {
            var arrIp = ip.split(".");
            if (arrIp.length !== 4) return false
            for (let oct of arrIp) {
                if (isNaN(oct) || Number(oct) < 0 || Number(oct) > 255)
                    return false
            }
            return true
        }

        return false
    }

    render() {
        let keyboardType = "numeric"

        if (Platform.OS === "web")
            keyboardType = null;

        return (
            <KeyboardAvoidingView enabled behavior="padding" style={styles.container}>
                <ScrollView style={[styles.container, styles.formWrapper]}>

                    <View style={styles.input}>
                        <View style={styles.row}>
                            <Text style={styles.text}>Automatic configuration</Text>
                            <Switch
                                onValueChange={this.autoConfSwitched.bind(this)}
                                value={this.state.autoConf}
                                backgroundActive='#02FF24'
                                activeText=''
                                inActiveText=''
                                circleSize={utils.moderateScale(20)}
                            />
                        </View>
                    </View>

                    <View style={styles.input}>
                        <TextField
                            keyboardType={keyboardType}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            animationDuration={this.animationSpeed}
                            label='IP Address'
                            value={this.state.ipAddress}
                            onChangeText={(ipAddress) => this.setState({ ipAddress })}
                            textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                            disabled={this.state.autoConf}
                            error={this.state.ipAddressError}
                        />
                    </View>

                    <View style={styles.input}>
                        <TextField
                            keyboardType={keyboardType}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            animationDuration={this.animationSpeed}
                            label='Subnet mask'
                            value={this.state.subnetMask}
                            onChangeText={(subnetMask) => this.setState({ subnetMask })}
                            textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                            disabled={this.state.autoConf}
                            error={this.state.subnetMaskError}
                        />
                    </View>

                    <View style={styles.input}>
                        <TextField
                            keyboardType={keyboardType}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            animationDuration={this.animationSpeed}
                            label='Gateway'
                            value={this.state.gateway}
                            onChangeText={(gateway) => this.setState({ gateway })}
                            textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                            disabled={this.state.autoConf}
                            error={this.state.gatewayError}
                        />
                    </View>

                    <View style={styles.input}>
                        <TextField
                            keyboardType={keyboardType}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            animationDuration={this.animationSpeed}
                            label='DNS'
                            value={this.state.dns}
                            onChangeText={(dns) => this.setState({ dns })}
                            textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                            disabled={this.state.autoConf}
                            error={this.state.dnsError}
                        />
                    </View>

                    {/*<View style={styles.input}>
                    <TextField
                        labelFontSize={utils.moderateScale(10)}
                        fontSize={utils.moderateScale(12)}
                        animationDuration={this.animationSpeed}
                        label='HTTP Proxy server'
                        value={this.state.httpProxyServer}
                        onChangeText={(httpProxyServer) => this.setState({ httpProxyServer })}
                        textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                        disabled={this.state.autoConf}
                        error={this.state.httpProxyServerError}
                    />
                </View>

                <View style={styles.input}>
                    <TextField
                        labelFontSize={utils.moderateScale(10)}
                        fontSize={utils.moderateScale(12)}
                        animationDuration={this.animationSpeed}
                        label='HTTPS Proxy server'
                        value={this.state.httpsProxyServer}
                        onChangeText={(httpsProxyServer) => this.setState({ httpsProxyServer })}
                        textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                        disabled={this.state.autoConf}
                        error={this.state.httpsProxyServerError}
                    />
                </View>*/}

                    <View style={styles.input}>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            animationDuration={this.animationSpeed}
                            label='MAC address'
                            value={this.state.macAddress}
                            onChangeText={(macAddress) => this.setState({ macAddress })}
                            textColor='#888888' tintColor='#888888' baseColor='#888888'
                            disabled={true}
                        />
                    </View>

                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.save.bind(this)}>Save modifications</Text>
                    </TouchableOpacity>
                </View>
            </KeyboardAvoidingView>
        )
    }
}

OptionsNetworkContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        padding: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        alignContent: 'center',
        padding: utils.moderateScale(10)
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    global: {
        color: '#FFFFFF'
    },
    input: {
        marginTop: utils.moderateScale(10)
    }
})