import React from 'react'
import { Platform, WebView, View, Text, StyleSheet } from 'react-native'
import { Actions } from '../../navigation/Navigator'

const PropTypes = require('prop-types')
class WebViewContainer extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.started = false
        this.code = ""
        this.load = ""
        this.state = {
            founded: false,
            url: ''
        }
        this.url = ""
    }

    _onNavigationStateChange(webViewState) {
        this.url = webViewState.url
        if (this.url.match(/code=([^&]*)/)) {
            let found = this.url.match(/code=([^&]*)/)
            this.code = found[1]
            this.setState({
                founded: true
            })
            
            if (Platform.OS === 'web'){
                // -*- Bypassing react native exception -*-
                try{
                    let RCTNetworking=require("RCTNetworking")
                    RCTNetworking.clearCookies(() => {})
                }
                catch{

                }
            }


            
        } else if (this.url.match(/error=([^&]*)/)) {
            let found = this.url.match(/error=([^&]*)/)
            this.code = found[1]
            this.setState({
                founded: true
            })
        }
        this.context.webSocketClient.publication.getToken({code: this.url})
    }
        
        

    _onError() {
        if (this.code != null) {
            return(
                <View style={styles.text_container}>
                    <Text style={styles.text}>We found the code :</Text>
                    <Text>{this.code}</Text>
                </View>
            )
        }
        return (
            <View style={styles.error_container}>
                <Text>You will be redirected to the app soon....</Text>
                <Text>{this.code}</Text>
            </View>
        )
    }

    render() {
        if (!this.state.founded) {
            return (
                <View style={{
                    flex: 1,
                    overflow: 'hidden',
                    justifyContent: 'center',
                }}>
                    <WebView
                        style={{
                            backgroundColor: '#262626',
                            aspectRatio: 1920 / 1080,
                            alignSelf: 'center',
                            maxWidth: '100%',
                            marginTop: 20,
                            flex: 1
                        }}
                        onNavigationStateChange={this._onNavigationStateChange.bind(this)}
                        source={{uri: this.props.url}}
                        userAgent="Chrome"
                    />
                </View>
        )} else if (this.state.founded) {
            return (
                <View>
                    {Actions.pop()}
                    {this.props.onWebViewEnd(this.code, this.props.providerId)}
                </View>
            )
        }
    }
}

WebViewContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        marginTop: 20
    },
    text: {
        fontSize: 35,
        fontWeight: 'bold'
    },
    text_container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    error_container: {
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
    }
  });

export default WebViewContainer
/*
*/