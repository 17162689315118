import React from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import Slider from "react-native-slider"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Switch } from 'react-native-switch';

import * as utils from '../../utils'

const PropTypes = require('prop-types')

class OptionsAudioMixerContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            inputs: {},
            canScroll: true
        }

        this.context.webSocketClient.audio.onContext(
            this.onContext, this
        )
    }

    componentDidMount() {
        this.context.webSocketClient.audio.context()
    }

    componentWillUnmount() {
        this.context.webSocketClient.audio.offContext(
            this.onContext
        )
    }

    onContext(inputs) {
        this.setState({ inputs })
    }

    onVolumeChanged(inputName, volume) {
        this.context.webSocketClient.audio.setVolume({
            'source': inputName,
            'volume': volume
        })
    }

    onVolumeChangedDone(inputName,) {
        this.context.webSocketClient.audio.saveVolume({
            'source': inputName
        })
    }

    toggleInputActivation(inputName, isActive) {
        this.context.webSocketClient.audio.toggleMute({
            'source': inputName,
            'mute': isActive
        })
    }

    renderInputMixer(index, inputName, inputData) {
        const muted = inputData.muted
        const inputMixerWrapperStyle = {
            backgroundColor: '#333333' + (index % 2 === 0 ? "" : "50")
        }

        let volume = inputData.volume > 100 ? 100 : inputData.volume
        volume = volume < 0 ? 0 : volume
        volume = parseInt(volume)

        return (
            <View
                key={"input-" + index + " - " + inputName}
                style={styles.inputMixer}
            >
                <View style={[styles.inputMixerWrapper, inputMixerWrapperStyle]}>
                    <Text style={styles.inputMixerInputName}>
                        {inputName} : {muted ? 'disabled' : inputData.volume + '%'}
                    </Text>
                    <View style={styles.inputMixerSlider}>
                        <Slider
                            minimumValue={0}
                            maximumValue={100}
                            onValueChange={(volume) => {
                                this.setState({ canScroll: true }, () => {
                                    if (volume != inputData.volume)
                                        this.onVolumeChanged(inputName, volume)
                                })
                            }}
                            step={2}
                            value={volume}
                            thumbTintColor={muted ? '#444' : '#00FF24'}
                            minimumTrackTintColor={muted ? '#AAAAAA20' : '#AAA'}
                            maximumTrackTintColor={muted ? '#55555520' : '#555'}
                            thumbStyle={styles.thumb}
                            disabled={muted}
                            onSlidingStart={() => { this.setState({ canScroll: false }) }}
                            onSlidingComplete={(volume) => {
                                this.setState({ canScroll: true }, () => {
                                    if (volume != inputData.volume)
                                        this.onVolumeChangedDone(inputName)
                                })
                            }}
                        />
                    </View>
                    <View style={styles.muteSwitch}>
                        <Text style={[styles.text, styles.switchState]}>{muted ? "Muted" : ""}</Text>
                        <Switch
                            onValueChange={(isActive) => {
                                this.toggleInputActivation(inputName, isActive)
                            }}
                            value={muted}
                            backgroundActive='#02FF24'
                            circleSize={utils.moderateScale(18)}
                            changeValueImmediately={true}
                            renderActiveText={false}
                            renderInActiveText={false}
                        />
                    </View>
                </View>
            </View>
        )
    }

    render() {
        return (
            <View style={styles.container}>
                <ScrollView
                    style={styles.containerScroll}
                    contentContainerStyle={styles.containerScrollContent}
                    scrollEnabled={this.state.canScroll}
                >
                    <Text style={[styles.text, styles.title]}>Audio inputs</Text>
                    {Object.keys(this.state.inputs).map((inputName, index) => this.renderInputMixer(
                        index,
                        inputName,
                        this.state.inputs[inputName]
                    ))}
                </ScrollView>
            </View>
        )
    }
}

OptionsAudioMixerContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    title: {
        textAlign: 'center',
        marginTop: utils.moderateScale(20),
        marginBottom: utils.moderateScale(20),
    },
    inputMixerWrapper: {
        paddingLeft: utils.moderateScale(20),
        paddingVertical: utils.moderateScale(20),
        backgroundColor: '#222',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center'
    },
    inputMixer: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    inputMixerInputName: {
        color: '#FFFFFF',
        textAlign: 'left',
        fontSize: utils.moderateScale(12),
        paddingRight: utils.moderateScale(20),
        minWidth: '20%'
    },
    inputMixerSlider: {
        flex: 1,
        alignSelf: 'center'
    },
    thumb: {
        width: utils.moderateScale(18),
        height: utils.moderateScale(18),
        borderRadius: utils.moderateScale(18) / 2,
    },
    muteSwitch: {
        minWidth: '20%',
        alignSelf: 'center',
        alignItems: 'center'
    },
    switchState: {
        position: 'absolute',
        fontSize: utils.moderateScale(8),
        top: -utils.moderateScale(15)
    }
})

function mapStateToProps(state) {
    return {
        systemState: state.systemReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsAudioMixerContainer)