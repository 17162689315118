import React from "react"
import { View, Text, TouchableOpacity, ScrollView, Image, LayoutAnimation, StyleSheet, WebView, Dimensions, StatusBar, FlatList } from "react-native"

import { TextField } from 'react-native-material-textfield'
import Grid from 'react-native-photo-grid'

import { Actions } from 'react-native-router-flux'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../../actions/globals'

import Messenger from '../../../components/Messenger'

import * as utils from '../../../utils'
import ModalContainer from "../../ModalContainer"

const PropTypes = require('prop-types')

const formatData = (data, numColumns) => {
    const numberOfFullRows = Math.floor(data.length / numColumns);
  
    let numberOfElementsLastRow = data.length - (numberOfFullRows * numColumns);
    while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow !== 0) {
      data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
      numberOfElementsLastRow++;
    }
  
    return data;
};
class PublishingContainerCreator extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            itemsPerRow: 4,
            pickedConnectorType: null,
            sftpMetadata: {
                host: '',
                port: 0,
                username: '',
                password: '',
                name: ''
            },
            ftpMetadata: {
                host: '',
                port: 0,
                username: '',
                password: '',
                name: ''
            },
            rtmpMetadata: {
                url: '',
                username: '',
                password: '',
                key: '',
                name: ''
            },
            url: undefined
        }

        this.context.webSocketClient.publication.onGetAuthUrl(
            this.onGetAuthUrl, this
        )
    }

    componentDidMount() {
        
    }

    onGetAuthUrl(data) {
       this.setState({
            url: data.url
        })
    }

    onConnectorTypeSelect(pickedConnectorType) {
        this.context.webSocketClient.publication.getAuthUrl({provider_id: pickedConnectorType.providerId})
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        this.setState({ 
            pickedConnectorType: pickedConnectorType
        })
    }

    onLayout() {
        this.setState({ itemsPerRow: 4 })
    }

    renderCommonSectionTitle() {
        return (
            <Text style={[styles.text, styles.title]}>Select the desired publishing service</Text>
        )
    }

    renderConnectorType(connectorType) {
        const activeStyle = (connectorType === this.state.pickedConnectorType) ? { backgroundColor: '#555' } : {}

        return (
            <View key={connectorType.name} style={{
                width: size,
                justifyContent: 'center',
            }}
                className="services-creator"
            >
                <TouchableOpacity style={[styles.service, activeStyle, {
                    height: 100,
                    alignSelf: 'center',
                }]} onPress={() => { this.onConnectorTypeSelect(connectorType) }}>
                    <Image
                        resizeMode="cover"
                        style={[{
                            aspectRatio: 1,
                            height: 50,
                            marginBottom: 50//utils.moderateScale(10)
                        }]}
                        source={utils.getPublishingIcon(connectorType.iconPath)}
                    />
                    <Text style={[styles.text, { textAlign: "center" }]}>{connectorType.verbose_name}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    renderConnectorTypes() {
        return (
            /*<Grid
                data={this.props.data}
                itemsPerRow={this.state.itemsPerRow}
                itemMargin={utils.moderateScale(20)}
                renderItem={this.renderConnectorType.bind(this)}
            />*/
            <FlatList
              data={formatData(this.props.data, 3)}
              style={styles.container}
              renderItem={this.renderItem}
              numColumns={3}
            />
        )
    }
      
    renderItem = ({ item, index }) => {
        const activeStyle = (item === this.state.pickedConnectorType) ? { backgroundColor: '#555' } : {}
        
        if (item.empty === true) {
        return <View style={[styles.item, styles.itemInvisible]} />;
        }
        return (
            <View key={item.name} style={{
                justifyContent: 'center',
            }}
                className="services-creator"
            >
                <TouchableOpacity style={[styles.service, activeStyle, {
                    alignSelf: 'center',
                }]} onPress={() => { this.onConnectorTypeSelect(item) }}>
                    <Image
                        resizeMode="cover"
                        style={[{
                            width: utils.moderateScale(50),
                            height: utils.moderateScale(50),
                        }]}
                        source={utils.getPublishingIcon(item.iconPath)}
                    />
                    <Text style={[styles.text, { textAlign: "center" }]}>{item.verbose_name}</Text>
                </TouchableOpacity>
            </View>
        );
    };

    sftpFields() {
        
    }

    renderFieldsSectionTitle() {
        if (this.state.pickedConnectorType) {
            return (
                <Text style={[styles.text, styles.title, styles.marginTop]}>Enter a name for your <Text style={{ color: "#00FF24" }}>{this.state.pickedConnectorType.verbose_name}</Text> publishing profile (e.g., Work, Personnal) </Text>
            )
        }
    }

    onNavigationStateChange = (webViewState) => {

    }

    renderNameField(url) {
        if (this.state.pickedConnectorType && this.state.pickedConnectorType.providerType == 1 && url) {
            this.props.onCancel()
            Actions.execute("push", "WebViewContainer", {url: url, onWebViewEnd: this.props.onWebViewEnd, providerId: this.state.pickedConnectorType.providerId})
        } else if (this.state.pickedConnectorType && this.state.pickedConnectorType.providerId === 0) {
            const color = "#FFFFFF"
            return (
                <View>
                    <ScrollView >
                        <Text style={[styles.text, styles.title, styles.marginTopBis]}>Enter the Parameters of your <Text style={{ color: "#00FF24" }}>SFTP Connection</Text></Text>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Host"
                            value={this.state.sftpMetadata.host}
                            onChangeText={(host) => { this.setState({
                                sftpMetadata: {host,
                                    port: this.state.sftpMetadata.port,
                                    username: this.state.sftpMetadata.username,
                                    password: this.state.sftpMetadata.password,
                                    name: this.state.sftpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="hostField"
                        />
                        <TextField
                            defaultValue={null}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            keyboardType='phone-pad'
                            label="Port"
                            value={this.state.sftpMetadata.port}
                            onChangeText={(port) => { this.setState({
                                sftpMetadata: {host: this.state.sftpMetadata.host,
                                    port,
                                    username: this.state.sftpMetadata.username,
                                    password: this.state.sftpMetadata.password,
                                    name: this.state.sftpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="portField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Username"
                            value={this.state.sftpMetadata.username}
                            onChangeText={(username) => { this.setState({
                                sftpMetadata: {host: this.state.sftpMetadata.host,
                                    port: this.state.sftpMetadata.port,
                                    username,
                                    password: this.state.sftpMetadata.password,
                                    name: this.state.sftpMetadata.name}}) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="usernameField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Password"
                            value={this.state.sftpMetadata.password}
                            onChangeText={(password) => { this.setState({
                                sftpMetadata: {host: this.state.sftpMetadata.host,
                                port: this.state.sftpMetadata.port,
                                username: this.state.sftpMetadata.username,
                                password,
                                name: this.state.sftpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="passwordField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Name"
                            value={this.state.sftpMetadata.name}
                            onChangeText={(name) => { this.setState({
                                sftpMetadata: {host: this.state.sftpMetadata.host,
                                port: this.state.sftpMetadata.port,
                                username: this.state.sftpMetadata.username,
                                password: this.state.sftpMetadata.password,
                                name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="nameField"
                        />
                    </ScrollView>
                </View>
            )
        } else if (this.state.pickedConnectorType && this.state.pickedConnectorType.providerId === 6) {
            const color = "#FFFFFF"
            return (
                <View>
                    <ScrollView >
                        <Text style={[styles.text, styles.title, styles.marginTop]}>Enter the Parameters of your <Text style={{ color: "#00FF24" }}>FTP Connection</Text></Text>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Host"
                            value={this.state.ftpMetadata.host}
                            onChangeText={(host) => { this.setState({
                                ftpMetadata: {host,
                                    port: this.state.ftpMetadata.port,
                                    username: this.state.ftpMetadata.username,
                                    password: this.state.ftpMetadata.password,
                                    name: this.state.ftpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="hostField"
                        />
                        <TextField
                            defaultValue={null}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            keyboardType='phone-pad'
                            label="Port"
                            value={this.state.ftpMetadata.port}
                            onChangeText={(port) => { this.setState({
                                ftpMetadata: {host: this.state.ftpMetadata.host,
                                    port,
                                    username: this.state.ftpMetadata.username,
                                    password: this.state.ftpMetadata.password,
                                    name: this.state.ftpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="portField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Username"
                            value={this.state.ftpMetadata.username}
                            onChangeText={(username) => { this.setState({
                                ftpMetadata: {host: this.state.ftpMetadata.host,
                                    port: this.state.ftpMetadata.port,
                                    username,
                                    password: this.state.ftpMetadata.password,
                                    name: this.state.ftpMetadata.name}}) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="usernameField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Password"
                            value={this.state.ftpMetadata.password}
                            onChangeText={(password) => { this.setState({
                                ftpMetadata: {host: this.state.ftpMetadata.host,
                                port: this.state.ftpMetadata.port,
                                username: this.state.ftpMetadata.username,
                                password,
                                name: this.state.ftpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="passwordField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Name"
                            value={this.state.ftpMetadata.name}
                            onChangeText={(name) => { this.setState({
                                ftpMetadata: {host: this.state.ftpMetadata.host,
                                port: this.state.ftpMetadata.port,
                                username: this.state.ftpMetadata.username,
                                password: this.state.ftpMetadata.password,
                                name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="nameField"
                        />
                    </ScrollView>
                </View>
            )
        } else if (this.state.pickedConnectorType && this.state.pickedConnectorType.providerId === 5) {
            const color = "#FFFFFF"
            return (
                <View>
                    <ScrollView>
                        <Text style={{color: '#FFFFFF'}}></Text>
                        <Text style={[styles.text, styles.title, styles.marginTop]}>Enter the Parameters of your <Text style={{ color: "#00FF24" }}>RTMP Connection</Text></Text>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="URL"
                            value={this.state.rtmpMetadata.url}
                            onChangeText={(url) => { this.setState({
                                rtmpMetadata: {url,
                                    username: this.state.rtmpMetadata.username,
                                    password: this.state.rtmpMetadata.password,
                                    key: this.state.rtmpMetadata.key,
                                    name: this.state.rtmpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="urlField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Username"
                            value={this.state.rtmpMetadata.username}
                            onChangeText={(username) => { this.setState({
                                rtmpMetadata: {url: this.state.rtmpMetadata.url,
                                    username,
                                    password: this.state.rtmpMetadata.password,
                                    key: this.state.rtmpMetadata.key,
                                    name: this.state.rtmpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="usernameField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Password"
                            autoCompleteType='password'
                            value={this.state.rtmpMetadata.password}
                            onChangeText={(password) => { this.setState({
                                rtmpMetadata: {url: this.state.rtmpMetadata.url,
                                    username: this.state.rtmpMetadata.username,
                                    password,
                                    key: this.state.rtmpMetadata.key,
                                    name: this.state.rtmpMetadata.name}}) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="passwordField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Key"
                            value={this.state.rtmpMetadata.key}
                            onChangeText={(key) => { this.setState({
                                rtmpMetadata: {url: this.state.rtmpMetadata.url,
                                username: this.state.rtmpMetadata.username,
                                password: this.state.rtmpMetadata.password,
                                key,
                                name: this.state.rtmpMetadata.name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="keyField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Name"
                            value={this.state.rtmpMetadata.name}
                            onChangeText={(name) => { this.setState({
                                rtmpMetadata: {url: this.state.rtmpMetadata.url,
                                username: this.state.rtmpMetadata.username,
                                password: this.state.rtmpMetadata.password,
                                key: this.state.rtmpMetadata.key,
                                name} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="nameField"
                        />
                    </ScrollView>
                </View>
            )
        }
    }

    create() {
        if (this.state.sftpMetadata.name === '' && this.state.pickedConnectorType.providerId === 0) {
            this.context.webSocketClient.publication.addNoauthUser({
                provider_id: 0,
                host: this.state.sftpMetadata.host,
                port: this.state.sftpMetadata.port,
                username: this.state.sftpMetadata.username,
                password: this.state.sftpMetadata.password,
                name: this.state.sftpMetadata.username
            })
        } else if (this.state.ftpMetadata.name === '' && this.state.pickedConnectorType.providerId === 6) {
            this.context.webSocketClient.publication.addNoauthUser({
                provider_id: 6,
                host: this.state.ftpMetadata.host,
                port: this.state.ftpMetadata.port,
                username: this.state.ftpMetadata.username,
                password: this.state.ftpMetadata.password,
                name: this.state.ftpMetadata.username
            })
        } else if (this.state.rtmpMetadata.name === '' && this.state.pickedConnectorType.providerId === 5) {
            this.context.webSocketClient.publication.addNoauthUser({
                provider_id: 5,
                host: this.state.rtmpMetadata.url,
                port: "-1",
                username: this.state.rtmpMetadata.username,
                password: this.state.rtmpMetadata.password,
                key: this.state.rtmpMetadata.key,
                name: this.state.rtmpMetadata.username
            })
        } else if (this.state.pickedConnectorType.providerId === 0) {
            this.context.webSocketClient.publication.addNoauthUser({
                provider_id: 0,
                host: this.state.sftpMetadata.host,
                port: this.state.sftpMetadata.port,
                username: this.state.sftpMetadata.username,
                password: this.state.sftpMetadata.password,
                name: this.state.sftpMetadata.name
            })
        } else if (this.state.pickedConnectorType.providerId === 6) {
            this.context.webSocketClient.publication.addNoauthUser({
                provider_id: 6,
                host: this.state.ftpMetadata.host,
                port: this.state.ftpMetadata.port,
                username: this.state.ftpMetadata.username,
                password: this.state.ftpMetadata.password,
                name: this.state.ftpMetadata.name
            })
        } else if (this.state.pickedConnectorType.providerId === 5) {
            this.context.webSocketClient.publication.addNoauthUser({
                provider_id: 5,
                host: this.state.rtmpMetadata.url,
                port: "-1",
                username: this.state.rtmpMetadata.username,
                password: this.state.rtmpMetadata.password,
                key: this.state.rtmpMetadata.key,
                name: this.state.rtmpMetadata.name
            })
        }
        this.cancel()
    }

    cancel() {
        this.props.onCancel()
    }

    render() {
        let createDisableStyle = { color: '#FFFFFF50' }
        let filledFields = 1
        if (this.state.sftpMetadata.host.length > 0 && this.state.sftpMetadata.port.length > 0 && this.state.sftpMetadata.username.length > 0 && this.state.sftpMetadata.password.length > 0 && this.state.pickedConnectorType.providerId === 0) {
            filledFields = 0
            createDisableStyle = {}
        } else if (this.state.ftpMetadata.host.length > 0 && this.state.ftpMetadata.port.length > 0 && this.state.ftpMetadata.username.length > 0 && this.state.ftpMetadata.password.length > 0 && this.state.pickedConnectorType.providerId === 6) {
            filledFields = 0
            createDisableStyle = {}
        } else if (this.state.rtmpMetadata.url.length > 0 && this.state.rtmpMetadata.key.length > 0 && this.state.pickedConnectorType.providerId === 5) {
            filledFields = 0
            createDisableStyle = {}
        }

        return (
            <View style={styles.container} onLayout={this.onLayout.bind(this)} className="publishing-container-creator">
                <ScrollView ref={ref => this.scrollView = ref}
    onContentSizeChange={(contentWidth, contentHeight)=>{        
        this.scrollView.scrollToEnd({animated: true});
    }}>
                    <View style={[styles.container, styles.formWrapper, { flex: 1 }]}>

                        {this.renderCommonSectionTitle()}
                        {this.renderConnectorTypes()}
                        {this.renderNameField(this.state.url)}
                    </View>
                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button} onPress={this.cancel.bind(this)}>
                        <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.button} disabled={filledFields} onPress={this.create.bind(this)}>
                        <Text style={[styles.buttonText, createDisableStyle]}>Create</Text>
                    </TouchableOpacity>

                </View>
            </View>
        )
    }
}


function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

PublishingContainerCreator.contextTypes = {
    webSocketClient: PropTypes.object,
    buildIsDemo: PropTypes.bool
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        margin: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    marginTop: {
        marginTop: utils.moderateScale(20),
    },
    marginTopBis: {
        marginTop: utils.moderateScale(21),
    },
    service: {
        borderRadius: utils.moderateScale(5),
        justifyContent: 'center',
        alignItems: 'center',
        padding: utils.moderateScale(10),
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(PublishingContainerCreator)