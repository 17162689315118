import * as types from '../constants/capabilities'

/** public **/
export function setHasAudioMixer(hasAudioMixer) {
    return {
        type: types.HAS_AUDIO_MIXER,
        hasAudioMixer
    }
}

export function setHasLogo(hasLogo) {
    return {
        type: types.HAS_LOGO,
        hasLogo
    }
}

export function setHasStudioMode(hasStudioMode) {
    return {
        type: types.HAS_STUDIO_MODE,
        hasStudioMode
    }
}

export function setHasWatermark(hasWatermark) {
    return {
        type: types.HAS_WATERMARK,
        hasWatermark
    }
}

export function setIsAbUpdate(isAbUpdate) {
    return {
        type: types.IS_AB_UPDATE,
        isAbUpdate
    }
}

export function setUseLegacyPublisher(useLegacyPublisher) {
    return {
        type: types.USE_LEGACY_PUBLISHER,
        useLegacyPublisher
    }
}

export function setHasRtmpAuth(hasRtmpAuth) {
    return {
        type: types.HAS_RTMP_AUTH,
        hasRtmpAuth
    }
}

export function setCanDefineDisplayOutput(canDefineDisplayOutput) {
    return {
        type: types.CAN_DEFINE_DISPLAY_OUTPUT,
        canDefineDisplayOutput
    }
}

export function setCanDefineStudioCaptureMode(canDefineStudioCaptureMode) {
    return {
        type: types.CAN_DEFINE_STUDIO_CAPTURE_MODE,
        canDefineStudioCaptureMode
    }
}

export function setCanDefineResolution(canDefineResolution) {
    return {
        type: types.CAN_DEFINE_RESOLUTION,
        canDefineResolution
    }
}

export function setCanDefineLayout(canDefineLayout) {
    return {
        type: types.CAN_DEFINE_LAYOUT,
        canDefineLayout
    }
}

export function setHasFactoryReset(hasFactoryReset) {
    return {
        type: types.HAS_FACTORY_RESET,
        hasFactoryReset
    }
}

export function setAverageMoPerHour(averageMoPerHour) {
    return {
        type: types.AVERAGE_MO_PER_HOUR,
        averageMoPerHour
    }
}

export function setIsV3(isV3) {
    return {
        type: types.IS_V3,
        isV3
    }
}

export function resetToDefaults() {
    return {
        type: types.RESET_TO_DEFAULTS
    }
}

export function setCanUploadNoauth(canUploadNoauth) {
    return {
        type: types.CAN_UPLOAD_NOAUTH,
        canUploadNoauth
    }
}