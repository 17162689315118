import * as types from '../constants/mixer'

const initialState = {
    currentScene: undefined,
    watermarkEnabled: false
}

export default function mixerReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SCENE_SPEAKER:
            return { ...state, currentScene: types.SCENE_SPEAKER }

        case types.SCENE_SCREEN:
            return { ...state, currentScene: types.SCENE_SCREEN }

        case types.SCENE_SPEAKER_SCREEN:
            return { ...state, currentScene: types.SCENE_SPEAKER_SCREEN }

        case types.SCENE_FULL:
            return { ...state, currentScene: types.SCENE_FULL }

        case types.WATERMARK_ENABLED_STATE:
            return { ...state, watermarkEnabled: action.watermarkEnabled }

        default:
            return state || initialState;
    }
}