import * as types from '../constants/system'

const initialState = {
    canStartUpdate: true,
    websocketConnected: false
}

export default function systemReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.CAN_START_UPDATE:
            return { ...state, canStartUpdate: action.canStartUpdate }

        case types.WEBSOCKET_CONNECTED:
            return { ...state, websocketConnected: action.websocketConnected }

        default:
            return state || initialState;
    }
}