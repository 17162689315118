import React from 'react'
import { LayoutAnimation, Text, View } from 'react-native'

import MainScreenBottomMenu from '../../components/mainScreen/MainScreenBottomMenu'

import * as mixerActions from '../../actions/mixer'
import * as mixerConstants from '../../constants/mixer'

import * as cameraActions from '../../actions/camera'

import * as globalsActions from '../../actions/globals'
import { Actions } from 'react-native-router-flux'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as utils from '../../utils'

const PropTypes = require('prop-types')

class MainScreenBottomMenuContainer extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            slideMenuPosLeft: undefined,
            slideMenuOpened: false,
            activatedScene: undefined,
            previousScene: undefined,
            activatedView: undefined,
            version: undefined
        }

        this.context.webSocketClient.context.onUpdated(
            this.contextUpdated, this
        )

        this.context.webSocketClient.system.onAbout(
            this.onAbout, this
        )

        this.context.webSocketClient.system.about()
    }

    componentWillUnmount() {
        this.context.webSocketClient.context.offUpdated(
            this.contextUpdated
        )
        this.context.webSocketClient.system.offAbout(
            this.onAbout
        )
    }

    contextUpdated(context) {
        this.setState({
            activatedScene: context.mixer.scene_id,
            activatedView: context.mixer.mode,
        })
    }

    onAbout(data) {
        this.setState({
            version: data.version
        })
    }

    refreshInterface() {
        this.setState({
            slideMenuPosLeft: this.state.slideMenuOpened ? 0 : -utils.getSize().width
        })
    }

    onLayoutUpdate(event) {
        this.refreshInterface()
    }

    componentDidMount() {
        this.refreshInterface()
    }

    setSlideMenuOpened(_slideMenuOpened) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        this.setState({ slideMenuOpened: _slideMenuOpened })

        this.props.globalsActions.refreshPlayerLayout()
    }

    isStudioScene(scene_id) {
        const { scenes } = this.context.webSocketClient.mixer
        return scene_id >= scenes.SPEAKER_ABOVE_SCREEN
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.slideMenuOpened !== this.state.slideMenuOpened)
            this.refreshInterface()

        const { scenes } = this.context.webSocketClient.mixer
        const { views } = this.context.webSocketClient.camera

        const newScene = this.state.activatedScene
        const isInStudio = this.isStudioScene(newScene)

        const oldScene = prevState.activatedScene
        const wasInStudio = this.isStudioScene(oldScene)

        const { colorPickerActive } = this.props.globalsState
        const wasColorPickerActive = prevProps.globalsState.colorPickerActive

        let colorPickingScene = scenes.SPEAKER

        if (this.state.version) {
            const version = this.state.version.replace(/\D/g,'')
            if (version >= 201802281440) {
                colorPickingScene = scenes.CHROMAKEY_PREVIEW
            }
        }

        if (colorPickerActive && newScene != colorPickingScene) {
            // Switch to chroma key preview scene if available
            this.switchScene(colorPickingScene)
        } else if (!colorPickerActive) {

            if (wasColorPickerActive)
                this.switchScene(this.state.previousScene)

            if (newScene !== oldScene) {

                this.setState({
                    previousScene: newScene
                })

                if (isInStudio !== undefined) {
                    if (isInStudio) {

                        this.context.webSocketClient.mixer.getAlphaRgb()
                        this.context.webSocketClient.mixer.getAlphaAngle()
                        this.context.webSocketClient.mixer.getAlphaBw()

                        // Preparation
                        if (wasInStudio === false) {

                            // No tracking in studio mode
                            this.context.webSocketClient.camera.stopTrack()

                            // Switch to medium view
                            if (this.state.activatedView !== views.MEDIUM)
                                this.switchView(views.MEDIUM)

                            // Switch to studio mode
                            this.props.globalsActions.switchMainScreenVideoMode('studio')
                        }
                    } else {
                        this.props.globalsActions.switchMainScreenVideoMode('default')
                    }
                }
            }
        }
    }

    switchScene(scene) {
        this.context.webSocketClient.mixer.switchScene({
            scene_id: scene
        })
    }

    switchView(view) {
        this.context.webSocketClient.editing.switchViewWithTracking(view)
        this.context.webSocketClient.camera.switchView(view)
    }

    render() {
        return <MainScreenBottomMenu
            height={this.props.height}
            onLayoutUpdate={this.onLayoutUpdate.bind(this)}
            slideMenuPosLeft={this.state.slideMenuPosLeft}
            setSlideMenuOpened={this.setSlideMenuOpened.bind(this)}
            setVisibleMenu={this.props.setVisibleMenu}
            slideMenuOpened={this.state.slideMenuOpened}
            activatedView={this.state.activatedView}
            activatedScene={this.state.activatedScene}
            switchView={this.switchView.bind(this)}
            switchScene={this.switchScene.bind(this)}
            maxIconSize={utils.getGridUnit()}
            maxFloatingIconSize={utils.getButtonGridUnit()}
            menuHorizontalPadding={utils.getGridUnit() * 0.25}
            isVisible={this.props.isVisible}
        />
    }
}

function mapStateToProps(state) {
    return {
        mixerState: state.mixerReducer,
        cameraState: state.cameraReducer,
        globalsState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        mixerActions: bindActionCreators(mixerActions, dispatch),
        cameraActions: bindActionCreators(cameraActions, dispatch),
        globalsActions: bindActionCreators(globalsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreenBottomMenuContainer)

MainScreenBottomMenuContainer.contextTypes = {
    webSocketClient: PropTypes.object
}