import React from 'react'
import { View, TouchableOpacity, Text, Image, StyleSheet, Dimensions } from 'react-native'

import Grid from 'react-native-photo-grid';

import UsbImporter from '../../components/UsbImporter'
import Messenger from '../../components/Messenger'
import * as utils from '../../utils'

const PropTypes = require('prop-types')

const defaultBackground = ''

export default class OptionsBackgroundContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            backgrounds: [],
            currentBackground: undefined,
            backgroundsPerRow: 2,
            importingBackgroundVisible: false
        }
    }

    componentDidMount() {

        this.context.webSocketClient.background.onList(
            this.onList, this
        )

        this.context.webSocketClient.background.onGetCurrent(
            this.onGetCurrent, this
        )

        this.refreshList()
    }

    componentWillUnmount() {
        this.context.webSocketClient.background.offList(
            this.onList
        )

        this.context.webSocketClient.background.offGetCurrent(
            this.onGetCurrent
        )
    }

    onList(list) {
        this.setState({
            backgrounds: list.backgrounds.filter((background) => {
                return background !== defaultBackground
            })
        }, () => {
            this.context.webSocketClient.background.getCurrent()
        })
    }

    onGetCurrent(currentBackground) {
        this.setState({ currentBackground: currentBackground.background })
    }

    refreshList() {
        this.context.webSocketClient.background.get()
    }

    import() {
        this.setState({ importingBackgroundVisible: true })
    }

    closeImportBackground() {
        this.setState({ importingBackgroundVisible: false })
    }

    importFile(filePath, device) {
        this.context.webSocketClient.background.usbCopy({
            device_id: device,
            path: filePath
        })
    }

    delete(background) {
        Messenger.prompt(
            'Remove background',
            'Are you sure you want to remove the current background ' + background + ' ?',
            [
                { text: 'Cancel', onPress: () => { }, style: 'cancel' },
                { text: 'Remove', onPress: () => { this.removeBackground(background) } },
            ],
            { cancelable: false }
        )
    }

    removeBackground(background) {
        this.context.webSocketClient.background.remove({
            background: background
        })
        this.refreshList()
    }

    imageTouched(background) {
        this.setState({
            currentBackground: background
        }, () => {
            this.context.webSocketClient.background.set({
                background: background
            })
        })
    }

    renderItem(background, backgroundSize) {
        const uri = this.context.mediaServer + '/backgrounds/' + background

        return (
            <View key={background} className="options-background-container-item">
                <TouchableOpacity
                    style={{ width: backgroundSize, height: backgroundSize }}
                    onPress={() => this.imageTouched(background)}
                >
                    <Image
                        resizeMode="cover"
                        style={[
                            styles.background,
                            (this.state.currentBackground === background) ? styles.activeBackground : {}
                        ]}
                        source={{ uri }}
                    />
                </TouchableOpacity>
                <View style={[
                    styles.toolbar,
                    {}
                ]}>
                    <TouchableOpacity onPress={() => this.delete(background)} disabled={(this.state.currentBackground === background)} style={styles.button}>
                        <Text style={[
                            styles.buttonText,
                            (this.state.currentBackground === background) ? styles.disabledButtonText : {}
                        ]}>Delete</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    onLayout() {
        const dim = Dimensions.get('screen')
        this.setState({ backgroundsPerRow: (dim.height >= dim.width) ? 2 : 3 })
    }

    filterEntry(entry) {
        return (entry.is_dir) ? true : entry.name.endsWith('.png')
    }

    render() {
        const { backgrounds } = this.state

        return (
            <View style={styles.container} onLayout={this.onLayout.bind(this)} className="options-background-container">
                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.import.bind(this)}>Import</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flex: 1 }} className="options-background-container-grid">
                    <Grid
                        data={backgrounds}
                        itemsPerRow={this.state.backgroundsPerRow}
                        itemMargin={utils.moderateScale(30)}
                        renderItem={this.renderItem.bind(this)}
                    />
                </View>
                <UsbImporter
                    isVisible={this.state.importingBackgroundVisible}
                    onCancel={this.closeImportBackground.bind(this)}
                    onFileSelected={this.importFile.bind(this)}
                    filterEntry={this.filterEntry.bind(this)}
                />
            </View>
        )
    }
}

OptionsBackgroundContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    boxIP: PropTypes.string,
    mediaServer: PropTypes.string,
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    containerScroll: {
        flex: 1,
        padding: utils.moderateScale(10)
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        alignContent: 'center',
        padding: utils.moderateScale(10)
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    disabledButtonText: {
        color: '#333333',
    },
    background: {
        flex: 1,
    },
    activeBackground: {
        borderWidth: 2,
        borderColor: '#02FF24'
    }
})