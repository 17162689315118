import React from 'react'
import { View, Text, StyleSheet, ScrollView, Alert } from 'react-native'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as systemActions from '../../actions/system'

import * as utils from '../../utils'
import IconedButton from '../../components/IconedButton'

var Icon720p = require('../../design/icons/option_resolution_1280_720.png')
var Icon1080p = require('../../design/icons/option_resolution_1920_1080.png')

const PropTypes = require('prop-types')

class OptionsResolutionContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            resolution:{
                "width" : -1,
                "height" : -1
            }
        }
        
        this.context.webSocketClient.mixer.onGetResolution(
            this.onGetResolution, this
        )

        this.context.webSocketClient.mixer.getResolution();
    }

    componentWillUnmount() {
        this.context.webSocketClient.mixer.offGetResolution(
            this.onGetResolution
        );
    }

    onGetResolution(data) {
        this.setState({
            resolution: (data !== null && data !== undefined) ? data : -1
        })
    }

    getIcon(image, resolution, label) {
        return (
            <IconedButton
                image={image}
                onPress={() => this.onPress(resolution)}
                label={label}
                style={[styles.icon, (this.state.resolution.width == resolution.width) ? styles.activeIcon : {}]}
                maxIconSize={utils.moderateScale(100)}
                key={label}
            />
        )
    }

    getIcons() {
        let icons = []

        // 1280 x 720
        icons.push(this.getIcon(Icon720p, {"width": 1280, "height": 720}, ''))

        // 1920 x 1080
        icons.push(this.getIcon(Icon1080p, {"width": 1920, "height": 1080}, ''))

        return icons
    }

    alertPressed() {
        Alert.alert(
            "Restart the camera to apply modifications",
            "",
            [
              { text: "OK", onPress: () => console.log("OK Pressed") },
            ],
            { cancelable: false }
          )
    }

    onPress(resolution) {
        this.context.webSocketClient.mixer.setResolution(resolution)
        this.alertPressed()

    }

    render() {
        return (
            <View style={styles.container}>
                <ScrollView style={styles.containerScroll}>
                    <Text style={[styles.text, styles.centered]}>Select the desired resolution</Text>
                    <View style={[styles.row, styles.margin, styles.centered]}>
                        {this.getIcons().map((icon) => { return icon; })}
                    </View>
                </ScrollView>
            </View>
        )
    }
}

OptionsResolutionContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    text: {
        color: '#FFFFFF',
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontSize: utils.moderateScale(13)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerScroll: {
        padding: utils.moderateScale(10),
        flex: 1
    },
    centered: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    margin: {
        margin: utils.moderateScale(10),
    },
    icon: {
        margin: utils.moderateScale(20),
        padding: utils.moderateScale(20),
        backgroundColor: '#000',
        alignItems: 'flex-start',
        backgroundColor: '#333333',
        borderRadius: utils.moderateScale(10)
    },
    activeIcon: {
        padding: utils.moderateScale(20) - 2,
        borderWidth: 2,
        borderColor: '#02FF24',
        backgroundColor: '#111'
    },
})

function mapStateToProps(state) {
    return {
        systemState: state.systemReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        systemActions: bindActionCreators(systemActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsResolutionContainer)