import * as types from '../constants/camera'

/** public **/
export function goToSpeakerView() {
    return {
        type: types.VIEW_SPEAKER
    }
}

export function goToMediumView() {
    return {
        type: types.VIEW_MEDIUM
    }
}

export function goToFullView() {
    return {
        type: types.VIEW_FULL
    }
}

export function setTracking(setTrackingActive) {
    return {
        type : types.SET_TRACKING,
        setTrackingActive
    }
}