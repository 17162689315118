import React from 'react'
import * as utils from '../utils'

import { View, Text, TouchableOpacity, StyleSheet, Switch } from 'react-native'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Actions } from '../navigation/Navigator'

import AudioFeedback from '../components/AudioFeedback'

import * as capabilitiesActions from '../actions/capabilities'
import * as mixerActions from '../actions/mixer'
import * as globalActions from '../actions/globals'
import * as videoConstants from '../constants/video'

import BatteryManager from '../components/BatteryManager'

import IconedButton from '../components/IconedButton'

// Rec
var recIcon = require('../design/icons/rec_start2.png')
var recActiveIcon = require('../design/icons/rec_start_2.png')

// Live
var liveIcon = require('../design/icons/option_broadcast_green.png')
var liveWarningIcon = require('../design/icons/option_broadcast_green_warning.png')
var liveDisabledIcon = require('../design/icons/option_broadcast_greyed.png')
var liveActiveIcon = require('../design/icons/option_broadcast_red.png')

// Web access
var netAccessIcon = require('../design/icons/net_access.png')
var netAccessActiveIcon = require('../design/icons/net_access_active.png')

const PropTypes = require('prop-types')

class StatusBar extends React.Component {

    
    elapsedTimeIntervalId = undefined
    mounted = false

    constructor(props, context) {
        super(props, context)

        this.state = {
            session: undefined,
            sessionState: undefined,
            curTime: undefined,
            autoLive: undefined,
            statusWidth: undefined,
            liveIconHeight: undefined,
            elapsedTimeMs: 0,
            debugTest: '',
            hasNetAccess: false
        }

        this.context.webSocketClient.video.onCurrentSession(
            this.onCurrentSession, this
        )

        this.context.webSocketClient.audio.onContext(
            this.onAudioContext, this
        )

        this.context.webSocketClient.publication.onGetLiveData(
            this.onGetLiveData, this
        )

        this.context.webSocketClient.context.onUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.mixer.onGetWatermarkEnabled(
            this.onGetWatermarkEnabled, this
        )

        this.context.webSocketClient.video.currentSession()
        this.context.webSocketClient.audio.context()
        this.context.webSocketClient.publication.getLiveData()
        this.context.webSocketClient.mixer.getWatermarkEnabled()
    }

    componentWillUnmount() {
        this.context.webSocketClient.video.offCurrentSession(
            this.onCurrentSession
        )

        this.context.webSocketClient.audio.offContext(
            this.onAudioContext
        )

        this.context.webSocketClient.publication.offGetLiveData(
            this.onGetLiveData
        )

        this.context.webSocketClient.context.offUpdated(
            this.onContextUpdated
        )

        this.context.webSocketClient.mixer.offGetWatermarkEnabled(
            this.onGetWatermarkEnabled
        )
    }

    componentWillMount() {
        this.setHour()
        setInterval(() => {
            this.setHour()
        }, 5000)
    }

    setHour() {
        const date = new Date()

        this.setState({
            curTime: ('0' + date.getHours()).slice(-2) + ':' +
                ('0' + (date.getMinutes())).slice(-2)
        })
    }

    onCurrentSession(session) {
        this.setState({
            sessionState: session.state,
            session,
            elapsedTimeMs: session.current_record_duration
        })
    }

    onAudioContext(context) {
        this.setState({ muted: context.muted })
    }

    onGetLiveData(params) {
        this.setState({ autoLive: params.auto })
    }

    onGetWatermarkEnabled(data) {
        this.props.mixerActions.setWatermarkEnabledState(data.enabled)
    }

    renderElapsedTime(elapsedTimeMs) {
        const isRecording = this.state.sessionState === videoConstants.SESSION_STATES.LIVE
        const time = utils.getStatusBarVideoDuration(elapsedTimeMs)
        if (isRecording) {
            return (
                <Text style={styles.elapsedTime}>
                    {time}
                </Text>
            )
        }
    }

    getStatus() {
        const isRecording = this.state.sessionState === videoConstants.SESSION_STATES.LIVE

        let text = (isRecording) ? 'REC ' : 'READY'
        const color = (isRecording) ? '#FF0000' : '#02FF24'

        return (
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <IconedButton
                    image={recIcon}
                    activeImage={recActiveIcon}
                    isActive={isRecording}
                    style={{ padding: 0 }}
                    maxIconSize={utils.getButtonGridUnit() / 3}
                    isDisabled={true}
                />
                <Text style={[styles.text, {
                    marginLeft: utils.moderateScale(utils.getButtonGridUnit() * 0.1)
                }]}>
                    {text}
                    {this.renderElapsedTime(this.state.elapsedTimeMs)}
                </Text>
            </View>
        )
    }

    onPressedLiveIcon() {
        this.props.globalActions.setPopAllOnClose(true)
        Actions.execute("replace", "OptionsPublishingContainer")
    }

    onPressedMicIcon() {
        this.props.globalActions.setPopAllOnClose(true)
        if (this.props.capabilitiesState.hasAudioMixer)
            Actions.execute("replace", "OptionsAudioMixerContainer")
        else
            Actions.execute("replace", "OptionsAudioContainer")
    }

    onAudioFeedbackLayout(layout) {
        const { width } = layout.nativeEvent.layout
        this.setState({ statusWidth: width })
    }

    onLiveIconLayout(layout) {
        const { height } = layout.nativeEvent.layout
        this.setState({ liveIconHeight: height })
    }

    onContextUpdated(context) {
        const { is_connected } = context.network
        this.setState({ hasNetAccess: is_connected })

        if (context.capabilities) {
            if (context.capabilities.hasOwnProperty('has_audio_mixer'))
                this.props.capabilitiesActions.setHasAudioMixer(context.capabilities.has_audio_mixer)

            if (context.capabilities.hasOwnProperty('has_logo'))
                this.props.capabilitiesActions.setHasLogo(context.capabilities.has_logo)

            if (context.capabilities.hasOwnProperty('has_studio_mode'))
                this.props.capabilitiesActions.setHasStudioMode(context.capabilities.has_studio_mode)

            if (context.capabilities.hasOwnProperty('has_watermark'))
                this.props.capabilitiesActions.setHasWatermark(context.capabilities.has_watermark)

            if (context.capabilities.hasOwnProperty('is_ab_update'))
                this.props.capabilitiesActions.setIsAbUpdate(context.capabilities.is_ab_update)

            if (context.capabilities.hasOwnProperty('use_legacy_publisher'))
                this.props.capabilitiesActions.setUseLegacyPublisher(context.capabilities.use_legacy_publisher)

            if (context.capabilities.hasOwnProperty('has_rtmp_auth'))
                this.props.capabilitiesActions.setHasRtmpAuth(context.capabilities.has_rtmp_auth)

            if (context.capabilities.hasOwnProperty('can_define_display_output'))
                this.props.capabilitiesActions.setCanDefineDisplayOutput(context.capabilities.can_define_display_output)

            if (context.capabilities.hasOwnProperty('can_define_studio_capture_mode'))
                this.props.capabilitiesActions.setCanDefineStudioCaptureMode(context.capabilities.can_define_studio_capture_mode)
            
            if (context.capabilities.hasOwnProperty('can_define_resolution'))
                this.props.capabilitiesActions.setCanDefineResolution(context.capabilities.can_define_resolution)

            if (context.capabilities.hasOwnProperty('can_define_layout'))
                this.props.capabilitiesActions.setCanDefineLayout(context.capabilities.can_define_layout)

            if (context.capabilities.hasOwnProperty('has_factory_reset'))
                this.props.capabilitiesActions.setHasFactoryReset(context.capabilities.has_factory_reset)

            if (context.capabilities.hasOwnProperty('average_mo_per_hour'))
                this.props.capabilitiesActions.setAverageMoPerHour(context.capabilities.average_mo_per_hour)

            if (context.capabilities.hasOwnProperty('can_upload_noauth'))
                this.props.capabilitiesActions.setCanUploadNoauth(context.capabilities.can_upload_noauth)

                this.props.capabilitiesActions.setIsV3(true)
        } else {
            this.props.capabilitiesActions.resetToDefaults()
            this.props.capabilitiesActions.setIsV3(false)
        }
    }

    updateElapsedTimeMs(newTime) {
        if (this.state.elapsedTimeMs !== newTime)
            this.setState({
                elapsedTimeMs: newTime
            })
    }

    componentDidUpdate(oldProps, oldState) {
        const isRecording = this.state.sessionState === videoConstants.SESSION_STATES.LIVE
        const wasRecording = oldState.sessionState === videoConstants.SESSION_STATES.LIVE

        if (isRecording) {
            if (isRecording !== wasRecording) {
                this.elapsedTimeIntervalId = setInterval(() => {
                    this.updateElapsedTimeMs(this.state.elapsedTimeMs + 1000)
                }, 1000)
            }
        } else {
            this.updateElapsedTimeMs(0)
            clearInterval(this.elapsedTimeIntervalId)
        }
    }

    render() {
        const autoLive = this.state.autoLive
        const isRecording = this.state.sessionState === videoConstants.SESSION_STATES.LIVE

        const { hasNetAccess } = this.state;
        // let hasNetAccess = false;


        let pickedLiveIcon = liveDisabledIcon
        if (autoLive) {
            pickedLiveIcon = hasNetAccess ? liveIcon : liveWarningIcon

            if (isRecording && hasNetAccess)
                pickedLiveIcon = liveActiveIcon
        }

        let text = 'OFF'
        if (autoLive) {
            text = 'ON'

            if (isRecording && hasNetAccess)
                text = 'ON AIR'
        }

        let color = '#555555'
        if (autoLive && hasNetAccess) {
            color = "#FFFFFF"

            if (isRecording)
                color = '#FF0000'
        }

        return (
            <View style={styles.container}>
                <View
                    style={{ flexDirection: 'row', flex: 1 }}
                >
                    <TouchableOpacity
                        style={{ flexDirection: 'row' }}
                        onPress={this.onPressedLiveIcon.bind(this)}
                    >
                        <IconedButton
                            image={pickedLiveIcon}
                            style={{ padding: 0 }}
                            aspectRatio={1.756849315068493}
                            maxIconSize={utils.getButtonGridUnit() / 3}
                            isDisabled={true}
                        />
                        <Text style={[styles.text, {
                            flex: 0,
                            color,
                            marginLeft: utils.moderateScale(utils.getButtonGridUnit() * 0.1)
                        }]}>{text}</Text>
                    </TouchableOpacity>
                </View>
                {this.getStatus()}
                <Text style={styles.text}>{this.state.curTime}</Text>
                <AudioFeedback
                    style={styles.blockItemLeft}
                    onLayout={this.onAudioFeedbackLayout.bind(this)}
                    onPress={this.onPressedMicIcon.bind(this)}
                />
                {/*
                <IconedButton
                    image={netAccessIcon}
                    activeImage={netAccessActiveIcon}
                    isActive={this.state.hasNetAccess}
                    style={{ padding: 0 }}
                    aspectRatio={1.042328042328042}
                    maxIconSize={utils.getButtonGridUnit() / 3}
                    isDisabled={true}
                />
                */}
                <BatteryManager
                    style={styles.blockItemRight}
                />
            </View>
        )
    }
}

StatusBar.contextTypes = {
    webSocketClient: PropTypes.object
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer,
        sessionState: state.sessionReducer,
        capabilitiesState: state.capabilitiesReducer,
        mixerState: state.mixerReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch),
        capabilitiesActions: bindActionCreators(capabilitiesActions, dispatch),
        mixerActions: bindActionCreators(mixerActions, dispatch)
    };
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#222222',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: utils.getButtonGridUnit(),
        padding: utils.moderateScale(utils.getButtonGridUnit() * 0.1),
        zIndex: 10
    },
    blockItemLeft: {
        marginRight: utils.moderateScale(utils.getButtonGridUnit() * 0.1),
        flex: 1
    },
    blockItemRight: {
        marginLeft: utils.moderateScale(utils.getButtonGridUnit() * 0.1),
    },
    blockWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 0
    },
    blockWrapperClock: {
        flex: 0.5,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 0
    },
    subBlockWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 0
    },
    left: {
        justifyContent: 'flex-start'
    },
    center: {
        justifyContent: 'center'
    },
    right: {
        justifyContent: 'flex-end'
    },
    spaced: {
        justifyContent: 'space-between'
    },
    textIconStatus: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(12),
        flex: 1
    },
    elapsedTime: {
        color: '#FF0000'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar)