import React from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as systemActions from '../../actions/system'

import * as utils from '../../utils'
import IconedButton from '../../components/IconedButton'

var half_scene_zero = require('../../design/icons/option_layout_half_0.png')
var half_scene_one= require('../../design/icons/option_layout_half_1.png')
var mixed_scene_zero = require('../../design/icons/option_layout_mixed_0.png')
var mixed_scene_one = require('../../design/icons/option_layout_mixed_1.png')
var mixed_scene_two = require('../../design/icons/option_layout_mixed_2.png')

const PropTypes = require('prop-types')

class OptionsLayoutMode extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            halfMode: 0,
            mixedMode: 0
        }

        this.context.webSocketClient.mixer.onGetHalfLayoutMode(
            this.onGetHalfLayoutMode, this
        );

        this.context.webSocketClient.mixer.onGetMixedLayoutMode(
            this.onGetMixedLayoutMode, this
        );

        this.context.webSocketClient.mixer.getHalfLayoutMode();
        this.context.webSocketClient.mixer.getMixedLayoutMode();
    }

    componentWillUnmount() {
        this.context.webSocketClient.mixer.offGetHalfLayoutMode(
            this.onGetHalfLayoutMode
        );
        this.context.webSocketClient.mixer.offGetMixedLayoutMode(
            this.onGetMixedLayoutMode
        );
    }

    onGetHalfLayoutMode(data) {
        this.setState({
            halfMode: (data.id !== null && data.id !== undefined) ? data.id : -1
        })
    }

    onGetMixedLayoutMode(data) {
        this.setState({
            mixedMode: (data.id !== null && data.id !== undefined) ? data.id : -1
        })
    }

    getHalfIcon(image, halfMode, label) {
        return (
            <IconedButton
                image={image}
                onPress={() => this.onHalfPress(halfMode)}
                label={label}
                style={[styles.icon, (this.state.halfMode == halfMode) ? styles.activeIcon : {}]}
                maxIconSize={utils.moderateScale(100)}
                key={label}
            />
        )
    }

    getMixedIcon(image, mixedMode, label) {
        return (
            <IconedButton
                image={image}
                onPress={() => this.onMixedPress(mixedMode)}
                label={label}
                style={[styles.icon, (this.state.mixedMode == mixedMode) ? styles.activeIcon : {}]}
                maxIconSize={utils.moderateScale(100)}
                key={label}
            />
        )
    }

    getHalfIcons() {
        let icons = []

        // Half Scene 0
        icons.push(this.getHalfIcon(half_scene_zero, 0, ''))

        // Half Scene 1
        icons.push(this.getHalfIcon(half_scene_one, 1, ''))

        return icons
    }

    getMixedIcons() {
        let icons = []

        // Mixed Scene 0
        icons.push(this.getMixedIcon(mixed_scene_zero, 0, ''))

        // Mixed Scene 1
        icons.push(this.getMixedIcon(mixed_scene_one, 1, ''))

        // Mixed Scene 2
        icons.push(this.getMixedIcon(mixed_scene_two, 2, ''))

        return icons
    }

    onHalfPress(id) {
        this.context.webSocketClient.mixer.setHalfLayoutMode({ id })
    }

    onMixedPress(id) {
        this.context.webSocketClient.mixer.setMixedLayoutMode({ id })
    }

    render() {
        return (
                <View style={styles.container}>
                    <ScrollView style={styles.containerScroll}>
                        <Text style={[styles.text, styles.centered]}>Half View</Text>
                        <View style={[styles.row, styles.margin, styles.centered]}>
                            {this.getHalfIcons().map((icon) => { return icon; })}
                        </View>
                    </ScrollView>
                    <ScrollView style={styles.containerScroll}>
                        <Text style={[styles.text, styles.centered]}>Mixed View</Text>
                        <View style={[styles.row, styles.margin, styles.centered]}>
                            {this.getMixedIcons().map((icon) => { return icon; })}
                        </View>
                    </ScrollView>
                </View>
        )
    }
}

OptionsLayoutMode.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    text: {
        color: '#FFFFFF',
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontSize: utils.moderateScale(13)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerScroll: {
        padding: utils.moderateScale(10),
        flex: 1
    },
    centered: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    margin: {
        margin: utils.moderateScale(10),
    },
    icon: {
        margin: utils.moderateScale(20),
        padding: utils.moderateScale(20),
        backgroundColor: '#000',
        alignItems: 'flex-start',
        backgroundColor: '#333333',
        borderRadius: utils.moderateScale(10)
    },
    activeIcon: {
        padding: utils.moderateScale(20) - 2,
        borderWidth: 2,
        borderColor: '#02FF24',
        backgroundColor: '#111'
    },
})

function mapStateToProps(state) {
    return {
        systemState: state.systemReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        systemActions: bindActionCreators(systemActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsLayoutMode)