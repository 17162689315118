import React from 'react'
import createBrowserHistory from "history/createBrowserHistory"

import { Provider } from 'react-redux'

import Navigator from "./navigation/Navigator"
import navigation from "./navigation/routes"

import configureStore from './configureStore'

import { ToastContainer, Slide } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const KastWebSocketClient = require('kast-client')
const PropTypes = require('prop-types')
const customHistory = createBrowserHistory();
const store = configureStore();

export default class WebApp extends React.Component {
    getChildContext() {
        // Box IP
        this.boxIP = "192.168.86.21"

        // Video Flux
        this.rtspFlux = 'rtsp://' + this.boxIP + ':8554/main.h264'

        // Static media server
        this.mediaServer = 'http://' + this.boxIP + ':80'

        // WebSocket server
        this.wsServer = 'ws://' + this.boxIP + ':8000'
        this.webSocketClient = new KastWebSocketClient(this.wsServer)

        return {
            webSocketClient: this.webSocketClient,
            boxIP: this.boxIP,
            mediaServer: this.mediaServer,
            rtspFlux: this.rtspFlux,
            buildIsDemo: this.buildIsDemo,
            videoConnectionTimeout: this.videoConnectionTimeout
        }
    }

    render() {
        return (
            <Provider store={store}>
                <Navigator navigation={navigation} history={customHistory} />
                <ToastContainer closeButton={false} transition={Slide} />
            </Provider>
        )
    }
}

WebApp.childContextTypes = {
    boxIP: PropTypes.string,
    mediaServer: PropTypes.string,
    kwsServer: PropTypes.string,
    webSocketClient: PropTypes.object,
    rtspFlux: PropTypes.string,
    mainScreenVideoActive: PropTypes.bool,
    createWebsocketConnection: PropTypes.func,
    buildIsDemo: PropTypes.bool,
    videoConnectionTimeout: PropTypes.number
}