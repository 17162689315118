import React from 'react'
import { View, Text, ScrollView, TouchableOpacity, LayoutAnimation, Image, StyleSheet, Platform, FlatList } from 'react-native'
import Grid from 'react-native-photo-grid'

import Messenger from '../components/Messenger'
import UsbExporter from '../components/UsbExporter'

import ModalContainer from "../containers/ModalContainer";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../navigation/Navigator'

import * as utils from '../utils'
import IconedButton from './IconedButton'
import DropdownPodUsers from './DropdownPodUsers'
const PropTypes = require('prop-types')

var usbIcon = require('../design/icons/publisher_usb.png')

const formatData = (data, numColumns) => {
    const numberOfFullRows = Math.floor(data.length / numColumns);
  
    let numberOfElementsLastRow = data.length - (numberOfFullRows * numColumns);
    while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow !== 0) {
      data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
      numberOfElementsLastRow++;
    }
  
    return data;
};
export default class ServicesExporter extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            connectors: undefined,
            selectedConnector: undefined,
            connectorTypes: [],
            usbExporterVisible: false,
            exportDevices: [],
            version: undefined,
            podUsers: [],
            activePodUser: null,
            exportReady: false
        }

        this.context.webSocketClient.publication.onGetConnectors(
            this.onGetConnectors, this
        )

        this.context.webSocketClient.publication.onGetConnectorTypes(
            this.onGetConnectorTypes, this
        )

        this.context.webSocketClient.publication.onGetIsValid(
            this.onGetIsValid, this
        )

        this.context.webSocketClient.usb.onDeviceList(
            this.onDeviceList, this
        )

        this.context.webSocketClient.usb.onDeviceAdded(
            this.onDeviceAdded, this
        )

        this.context.webSocketClient.usb.onDeviceRemoved(
            this.onDeviceRemoved, this
        )
        
        this.context.webSocketClient.publication.onGetUsers(
            this.onGetUsers, this
        )

        this.context.webSocketClient.publication.getConnectorTypes()

        this.context.webSocketClient.publication.onGetPodUsers(
            this.onGetPodUsers, this
        )
    }

    componentWillUnmount() {
        this.context.webSocketClient.publication.offGetConnectors(
            this.onGetConnectors
        )

        this.context.webSocketClient.publication.offGetConnectorTypes(
            this.onGetConnectorTypes
        )

        this.context.webSocketClient.publication.offGetIsValid(
            this.onGetIsValid
        )

        this.context.webSocketClient.usb.offDeviceList(
            this.onDeviceList
        )

        this.context.webSocketClient.usb.offDeviceAdded(
            this.onDeviceAdded
        )

        this.context.webSocketClient.usb.offDeviceRemoved(
            this.onDeviceRemoved
        )
    }

    componentWillMount() {
        this.context.webSocketClient.publication.getUsers()
        this.context.webSocketClient.publication.getPodUsers()
    }

    onGetPodUsers = (usersRow) => {
        let users = JSON.parse(usersRow)
        console.log("USERS", users)
        if ('error' in users) {
            console.log("OH MY GOD, ERROR zXZXZXzxzxZXZX  \\o/")
            this.setState({
                podUsers: -1
            })
        }else if ('results' in users) {
            this.setState({
                podUsers: users.results
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isVisible && this.props.isVisible && this.props.usbOnly)
        {
            this.context.webSocketClient.usb.deviceList()
        }
    }

    onGetUsers(results) {
        this.setState({
            ...this.state,
            connectors: results
        })
    }

    onGetConnectorTypes(_connectorTypes) {
        let connectorTypes = []

        for (const connectorTypeName in _connectorTypes) {
            const connectorType = _connectorTypes[connectorTypeName]
            connectorType.iconPath = connectorType.icon
            connectorTypes.push(connectorType)
        }
        this.setState({ connectorTypes }, () => {
            this.context.webSocketClient.publication.getConnectors()
        })
    }

    getConnectorIcon(connector) {
        for (const connectorTypeI in this.state.connectorTypes) {
            let connectorType = this.state.connectorTypes[connectorTypeI]
            if (connectorType.name === connector.name) {
                return utils.getPublishingIcon(connectorType.iconPath)
            }
        }
    }


    onDeviceList(devices) {
        this.setState({
            exportDevices: Object.keys(devices).map((deviceId) => {
                const device = devices[deviceId]
                return {
                    key: device.id,
                    label: device.device_name + ' - ' + device.friendly_name
                }
            })
        })
    }


    onDeviceAdded(device) {
        let newDevice = null
        for (let i in this.state.exportDevices) {
            const _device = this.state.exportDevices[i]
            if (_device.key === device.id)
                newDevice = _device
        }

        if (!newDevice) {
            newDevice = {
                key: device.id,
                label: device.device_name + ' - ' + device.friendly_name
            }

            console.log("adding new device : ", newDevice)

            let newDevices = this.state.exportDevices
            newDevices.push(newDevice)

            this.setState({
                exportDevices: newDevices
            })
        }
    }

    onDeviceRemoved(deviceId) {
        let oldDevice = null
        for (let i in this.state.exportDevices) {
            const _device = this.state.exportDevices[i]
            if (_device.key === deviceId)
                oldDevice = _device
        }

        if (oldDevice) {
            console.log("removing device : ", deviceId)

            let newDevices = this.state.exportDevices
            newDevices.splice(this.state.exportDevices.indexOf(oldDevice), 1)

            this.setState({
                exportDevices: newDevices
            })
        }
    }

    getUsbConnector() {
        return {
            name: "usb",
            created: false,
            typename: 'usb',
            verbose_name: 'USB',
            fields: { name: { value: 'USB' } },
            iconObj: usbIcon,
            types: ["vod"],
            isValid: true
        }
    }

    onGetConnectors(connectors) {
        let formatedConnectors = []

        for (const connectordId in connectors) {
            const connector = connectors[connectordId]
            if (connector.types.indexOf("vod") > -1) {
                connector.iconObj = this.getConnectorIcon(connector)
                formatedConnectors.push(connector)
            }
        }

        formatedConnectors.unshift(this.getUsbConnector())
        this.setState({
            connectors: formatedConnectors,
        }, () => {
            for (let i = 0; i < this.state.connectors.length; i++) {
                const connector = this.state.connectors[i]
                this.context.webSocketClient.publication.getIsValid({
                    'service': connector
                })
            }
        })
    }

    onGetIsValid(isValidData) {
        if ('is_valid' in isValidData && 'service' in isValidData) {
            const isValid = isValidData.is_valid
            const _connector = isValidData.service

            let connectors = [... this.state.connectors]

            for (let i = connectors.length - 1; i >= 0; i--) {
                const connector = connectors[i]
                if (connector.id == _connector.id && !isValid) {
                    connectors.splice(i, 1)
                    break
                }
            }

            this.setState({ connectors })
        }
    }

    onCancel() {
        if (this.props.onCancel)
            this.props.onCancel()
    }

    onCancelUsbExport() {
        this.setState({ usbExporterVisible: false })
        this.props.onCancel()
    }

    onExport() {
        if (this.state.selectedConnector.name === 'usb') {
            this.setState({ usbExporterVisible: true }, () => {
                this.context.webSocketClient.usb.deviceList()
            })
        } else {
            Messenger.prompt(
                'Export',
                'Are you sure you want to export selection to ' + this.state.selectedConnector.name + '?',
                [
                    { text: 'Cancel', onPress: () => { }, style: 'cancel' },
                    { text: 'Export', onPress: () => this.exportToService() },
                ],
                { cancelable: true }
            )
        }
    }

    onSelectedExportDevice(device) {
        for (const i in this.props.videos) {
            const id = parseInt(this.props.videos[i])
            this.context.webSocketClient.video.usbCopy({
                video_id: id,
                device_id: device
            })
        }
        this.setState({ usbExporterVisible: false }, () => {
            if (this.props.onExportStarted)
                this.props.onExportStarted()
        })
        this.onCancel()
    }

    exportToService() {
        let metadata
        this.props.videos.forEach(function (_video) {
            switch (this.state.selectedConnector.provider_id) {
                case 1:
                    metadata = {
                        title: "Video Session n°" + _video.toString(),
                        description: "Session propulsed by Kast by Kalyzée©",
                        privacy: "private"
                    }
                    break;
                default:
                    metadata = {
                        name: "Video Session n°" + _video.toString()
                    }
            }
            const id = parseInt(_video)
            let query = {
                ...metadata,
                user_id: this.state.selectedConnector.user_id,
                pod_user: this.state.selectedConnector.provider_id === 7 ? this.state.activePodUser.id : 0,
                provider_id: this.state.selectedConnector.provider_id,
                video_id: id
            }
            this.context.webSocketClient.publication.addPublishSession(query)
            this.confirmExport()

        }, this)
        this.props.onCancel()
    }

    confirmExport() {
        if (this.props.onExportStarted)
            this.props.onExportStarted()
    }

    onLayout() {
        this.setState({ itemsPerRow: 4 })
    }

    onConnectorSelect(connector) {
        this.setState({
            selectedConnector: connector
        })
        if (connector.provider_id !== 7) {
            this.setState({
                exportReady: true
            })
        } else if (this.state.activePodUser) {
            this.setState({
                exportReady: true
            })
        }

    }

    getIconFromId(id) {
        switch (id) {
            case 0: 
                return(require("../design/icons/publisher_icons/publisher_ftp.png"))
            case 1: 
                return(require("../design/icons/publisher_icons/publisher_youtube.png"))
            case 2: 
                return(require("../design/icons/publisher_icons/publisher_dropbox.png"))
            case 3: 
                return(require("../design/icons/publisher_icons/publisher_googledrive.png"))
            case 5:
                return(require("../design/icons/publisher_icons/publisher_custom.png"))
            case 6:
                return(require("../design/icons/publisher_icons/publisher_ftp.png"))
            case null:
                return(require("../design/icons/publisher_usb.png"))
            default:
                return(require("../design/icons/publisher_icons/publisher_custom.png"))

        }
    }

    onPressPublishButton() {
        //this.props.globalsActions.displayExportIsVisible(false)
        this.props.onCancel()
        Actions.execute("replace", "OptionsPublishingContainer")
    }

    renderConnectorType(connector, size) {
        const activeStyle = (connector === this.state.selectedConnector) ? { backgroundColor: '#555' } : {}
        const source = this.getIconFromId(connector.provider_id)
        if (connector.provider_id === 7) {
            return(
                <View
                style={{
                    width: size,
                    justifyContent: 'center',
                }}
                className="connector-type-button">
                    <TouchableOpacity style={[styles.service, activeStyle, {
                        height: size,
                        alignSelf: 'center',
                    }]} onPress={() => { this.onConnectorSelect(connector) }}>
                        <Image
                        resizeMode="cover"
                        style={[{
                            aspectRatio: 1,
                            height: size / 2,
                            marginBottom: utils.moderateScale(10)
                        }]}
                        source={source}/>
                        { this.state.podUsers !== -1 ? <DropdownPodUsers
                            defaultValue={(this.state.activePodUser != null) ? this.state.activePodUser.username + "   ▼" : "select user   ▼"}
                            onSelect={this.onPodUserSelected.bind(this)}
                            options={this.state.podUsers}
                            renderRowText={(connector) => { return connector.name }}
                            renderRowImage={true}
                            ref={"liveDropdown"}
                        /> : <Text style={[styles.text, { textAlign: "center" }]}>No users found</Text> }
                        { this.state.podUsers !== -1 ? <Text style={[styles.text, { textAlign: "center" }]}>
                            {connector.name}
                        </Text> : <TouchableOpacity style={[styles.service, {
                                                height: 120,
                                                alignSelf: 'center',
                                            }]} onPress={() => {this.onPressPublishButton()}}>
                                                <Text style={[styles.text, { textAlign: "center" }]}>Enter Server Credentials</Text>
                                        </TouchableOpacity> }
                    </TouchableOpacity>
                </View>
            )
        } else if (connector.provider_id != 4 && connector.provider_id != 5) {
            return (
                <View
                style={{
                    width: size,
                    justifyContent: 'center',
                }}
                className="connector-type-button">
                    <TouchableOpacity style={[styles.service, activeStyle, {
                        height: size,
                        alignSelf: 'center',
                    }]} onPress={() => { this.onConnectorSelect(connector) }}>
                        <Image
                        resizeMode="cover"
                        style={[{
                            aspectRatio: 1,
                            height: size / 2,
                            marginBottom: utils.moderateScale(10)
                        }]}
                        source={source}/>
                        <Text style={[styles.text, { textAlign: "center" }]}>{connector.name}BKEJDDGE</Text>
                    </TouchableOpacity>
                </View>
            )
        }
    }

    onPodUserSelected(_, user) {
        this.setState({
            activePodUser: user,
        })
        if (this.state.selectedConnector) {
            this.setState({
                exportReady: true
            })
        }
    }

    activeUsbExporter() {
        this.setState({ usbExporterVisible: true }, () => {
                this.context.webSocketClient.usb.deviceList()
            })
    }

    grider() {
        return(
            <FlatList
              data={this.state.connectors}
              style={styles.container}
              renderItem={this.renderItem}
              numColumns={3}
            />
        )
    }

    renderItem = ({ item, index }) => {
        const activeStyle = (item === this.state.pickedConnectorType) ? { backgroundColor: '#555' } : {}
        const source = this.getIconFromId(item.provider_id)
        if (item.empty === true) {
            return <View style={[styles.item, styles.itemInvisible]} />;
        }
        if (item.provider_id === 7) {
            return (
                <View key={item.name} style={{
                    justifyContent: 'center',
                }}
                    className="services-creator"
                >
                    <TouchableOpacity style={[styles.service, activeStyle, {
                        flex: 1
                    }]} onPress={() => { this.onConnectorSelect(item) }}>
                        <Image
                            resizeMode="cover"
                            style={[{
                                flex: 1,
                                width: utils.moderateScale(50),
                                height: utils.moderateScale(50),
                            }]}
                            source={source}
                        />
                        { this.state.podUsers !== -1 ? <DropdownPodUsers
                            defaultValue={(this.state.activePodUser != null) ? this.state.activePodUser.username + "   ▼" : "select user   ▼"}
                            onSelect={this.onPodUserSelected.bind(this)}
                            options={this.state.podUsers}
                            renderRowText={(item) => { return item.name }}
                            renderRowImage={true}
                            ref={"liveDropdown"}
                        /> : <Text style={[styles.text, { textAlign: "center" }]}>No users found</Text> }
                        { this.state.podUsers !== -1 ? <Text style={[styles.text, { textAlign: "center" }]}>
                            {item.name}
                        </Text> : <TouchableOpacity style={[styles.service, {
                                                height: 120,
                                                alignSelf: 'center',
                                            }]} onPress={() => {this.onPressPublishButton()}}>
                                                <Text style={[styles.text, { textAlign: "center" }]}>Enter Server Credentials</Text>
                                        </TouchableOpacity> }
                    </TouchableOpacity>
                </View>
            );
        } else if (item.provider_id != 4 && item.provider_id != 5) {
            return (
                <View key={item.name} style={{
                    justifyContent: 'center',
                }}
                    className="services-creator"
                >
                    <TouchableOpacity style={[styles.service, activeStyle, {
                        //height: utils.moderateScale(5),
                        //alignSelf: 'center',
                        flex: 1
                    }]} onPress={() => { this.onConnectorSelect(item) }}>
                        {console.log("FUUUUUUUUUUUUUUUUUUUU", item)}
                        <Image
                            resizeMode="cover"
                            style={[{
                                flex: 1,
                                //aspectRatio: 1,
                                width: utils.moderateScale(50),
                                height: utils.moderateScale(50),
                                //height: utils.moderateScale(50)
                                //marginBottom: utils.moderateScale(10)
                            }]}
                            source={source}
                        />
                        <Text style={[styles.text, { textAlign: "center" }]}>{item.name}</Text>
                    </TouchableOpacity>
                </View>
            );
        }
    };

    render() {
        const exportDisableStyle = !this.state.exportReady ? { color: '#FFFFFF50' } : {}

        //Hackish way to refresh listing
        const exportDevices = [...this.state.exportDevices]

        return (
            <View className="services-exporter-container">
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.props.isVisible && !this.state.usbExporterVisible && this.props.usbOnly}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-exporter-modal-container"
                >
                    <View style={[styles.modalWrapper]} className="services-exporter">
                        <View style={styles.modal}>
                            <View style={styles.container} onLayout={this.onLayout.bind(this)}>
                                <ScrollView>
                                    <View style={[styles.container, styles.formWrapper, { flex: 1 }]}>
                                        <Text style={[styles.title, styles.text, {
                                            marginBottom: utils.moderateScale(5)
                                        }]}>Choose a service to export the selected video</Text>
                                        {this.grider()}
                                        <View 
                                        style={{flex: 1, flexDirection: 'row', alignSelf: 'center'}}>
                                            <TouchableOpacity style={[styles.service, {
                                                height: 120,
                                                alignSelf: 'center',
                                            }]} onPress={() => {this.activeUsbExporter()}}>
                                                <Image
                                                resizeMode="cover"
                                                style={[{
                                                    aspectRatio: 1,
                                                    height: 120 / 2,
                                                    marginBottom: utils.moderateScale(10)
                                                }]}
                                                source={require("../design/icons/publisher_usb.png")}/>
                                                <Text style={[styles.text, { textAlign: "center" }]}>USB Export</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={[styles.service, {
                                                height: 120,
                                                alignSelf: 'center',
                                            }]} onPress={() => {this.onPressPublishButton()}}>
                                                <Image
                                                resizeMode="cover"
                                                style={[{
                                                    aspectRatio: 1,
                                                    height: 120 / 2,
                                                    marginBottom: utils.moderateScale(10)
                                                }]}
                                                source={require("../design/icons/add_connector.png")}/>
                                                <Text style={[styles.text, { textAlign: "center" }]}>Add Account</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </ScrollView>
                                <View style={styles.toolbar}>
                                    <TouchableOpacity style={styles.button} onPress={this.onCancel.bind(this)}>
                                        <Text style={styles.buttonText}>Cancel</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={styles.button} disabled={!this.state.exportReady} onPress={this.onExport.bind(this)}>
                                        <Text style={[styles.buttonText, exportDisableStyle]}>Export</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                </ModalContainer>
                <UsbExporter
                    isVisible={this.state.usbExporterVisible || (!this.props.usbOnly && this.props.isVisible)}
                    onSelect={this.onSelectedExportDevice.bind(this)}
                    onCancel={this.onCancelUsbExport.bind(this)}
                    options={exportDevices}
                    title='Select device'
                    noResultsText='Waiting for USB flash drive...'
                    overlayClassName="modal_overlay_usb_exporter"
                />
            </View>
        )
    }
}

ServicesExporter.contextTypes = {
    webSocketClient: PropTypes.object,
    boxIP: PropTypes.string
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#262626',
    },
    modalWrapper: {
        justifyContent: 'center',
        alignContent: 'center',
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#000000AA'
    },
    modal: {
        flex: 1,
        maxWidth: '90%',
        maxHeight: '90%',
        alignSelf: 'center'
    },
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        padding: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    marginTop: {
        marginTop: utils.moderateScale(20),
    },
    service: {
        borderRadius: utils.moderateScale(5),
        justifyContent: 'center',
        alignItems: 'center',
        padding: utils.moderateScale(10),
    }
})