import React from 'react'
import { View, StyleSheet } from 'react-native'
import Button from 'react-native-button';

import RoomConfiguratorContainer from "./camera/RoomConfiguratorContainer"
import JoystickContainer from "./camera/JoystickContainer"
import AdvancedSettingsContainer from "./camera/AdvancedSettingsContainer"

import GstPlayerContainer from '../GstPlayerContainer'

import * as cameraActions from '../../actions/camera'
import * as globalsActions from '../../actions/globals'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as utils from '../../utils'

const PropTypes = require('prop-types')

class OptionsCameraContainer extends React.Component {

    mount = false

    constructor(props, context) {
        super(props, context)

        this.state = {
            roomsList: [],
            currentRoom: undefined,
            rtspFlux: context.rtspFlux,
            newRoomPromptVisible: false,
            isHorizontal: utils.getSize().isHorizontal,
            hasInteractiveOverlay: true,
            showAdvancedSettings: false
        }

        this.context.webSocketClient.camera.onGetRoomList(
            this.onGetRoomList, this
        )

        this.context.webSocketClient.camera.onGetCurrentRoom(
            this.onGetCurrentRoom, this
        )

        this.context.webSocketClient.camera.onSetRoom(
            this.onSetRoom, this
        )

        const { scenes } = this.context.webSocketClient.mixer
        this.context.webSocketClient.mixer.switchScene({
            scene_id: scenes.SPEAKER
        })

        this.context.webSocketClient.context.get()
        this.refreshList()
    }

    componentDidMount() {
        this.mount = true
        this.refreshList()
        this.setTracking(false)
    }

    componentWillUnmount() {
        this.mount = false
        this.context.webSocketClient.camera.endSettingTrackingZone()

        this.context.webSocketClient.camera.offGetRoomList(
            this.onGetRoomList
        )

        this.context.webSocketClient.camera.offGetCurrentRoom(
            this.onGetCurrentRoom
        )

        this.context.webSocketClient.camera.offSetRoom(
            this.onSetRoom
        )
        this.props.globalsActions.switchPauseRenderPlayer(false)
    }

    onGetRoomList(roomList) {
        let _roomsList = []
        if (this.mount) {
            roomList.sort().forEach((value, i) => {
                _roomsList.push({ key: value.toLowerCase(), label: value })
            }, this)
            this.setState({ roomsList: _roomsList })
        }
    }

    onGetCurrentRoom(room) {
        if (this.mount) {
            this.state.roomsList.forEach(function (_room) {
                if (_room.label === room)
                    this.setState({ currentRoom: _room })
            }, this)
        }
    }

    onSetRoom(room) {
        if (this.mount) {
            this.refreshList()
        }
    }

    toggleEditMode() {
        if (this.mount)
            this.setState({ editModeActive: true })
    }

    addNewRoom() {
        this.setState({ newRoomPromptVisible: true })
    }

    refreshList() {
        this.context.webSocketClient.camera.getRoomList()
        this.context.webSocketClient.camera.getCurrentRoom()
    }

    onLayout() {
        if (this.state.isHorizontal !== utils.getSize().isHorizontal)
            this.setState({ isHorizontal: utils.getSize().isHorizontal })
    }

    onRequestClose() { }

    setTracking(isTracking) {
        if (this.mount) {
            if (isTracking)
                this.context.webSocketClient.camera.startTrack()
            else
                this.context.webSocketClient.camera.stopTrack()

            if (this.state.isTracking !== isTracking)
                this.setState({ isTracking })
        }
    }

    onDragEnter() {
        if (this.state.hasInteractiveOverlay) {
            if (this.props.cameraState.setTrackingActive) {
                this.context.webSocketClient.camera.mouseDown()
            }
        }
    }

    cameraMax = { x: 952, y: 535 }
    canvasToCameraValueX(width, x) {
        return Math.ceil((x * this.cameraMax.x) / width);
    }

    canvasToCameraValueY(height, y) {
        return Math.ceil((y * this.cameraMax.y) / height);
    }

    onDrag(width, height, x, y) {
        if (this.state.hasInteractiveOverlay) {
            if (this.props.cameraState.setTrackingActive) {
                this.context.webSocketClient.camera.mousePosition({
                    posX: this.canvasToCameraValueX(width, x),
                    posY: this.canvasToCameraValueY(height, y)
                })
            }
        }
    }

    onDragLeave() {
        if (this.state.hasInteractiveOverlay) {
            if (this.props.cameraState.setTrackingActive) {
                this.context.webSocketClient.camera.mouseUp()
            }
        }
    }

    getRightPanelSettingsToggleButton() {
        return (
            <View
                pointerEvents='box-none'
                style={{
                    position: 'absolute',
                    bottom: utils.moderateScale(10),
                    left: utils.getButtonGridUnit() * 3.7
                }}
            >
                <Button
                    containerStyle={[styles.buttonContainer, {
                        paddingHorizontal: utils.moderateScale(10),
                        height: utils.getButtonGridUnit(),
                    }]}
                    onPress={() => {
                        this.setState({
                            showAdvancedSettings: !this.state.showAdvancedSettings
                        })
                    }}
                    style={styles.buttonTextM}
                >{this.state.showAdvancedSettings ? "Zoom Settings" : "Image Settings"}</Button>
            </View>
        )
    }

    getRightPanel() {
        if (this.state.showAdvancedSettings) {
            return (
                <AdvancedSettingsContainer
                    style={styles.roomConfigurator}
                />
            )
        } else {
            return (
                <RoomConfiguratorContainer
                    style={styles.roomConfigurator}
                    setTracking={this.setTracking.bind(this)}
                    isTracking={this.state.isTracking}
                />
            )
        }
    }

    render() {
        let absolutePos = { top: 0, bottom: 0, left: 0, right: 0 }
        if (this.state.isHorizontal)
            delete absolutePos.left, delete absolutePos.right
        else
            delete absolutePos.top, delete absolutePos.bottom

        return (
            <View style={styles.container} onLayout={this.onLayout.bind(this)} className="options-camera-container">

                <View style={styles.container}>

                    <GstPlayerContainer
                        showBorders={this.props.cameraState.setTrackingActive}
                        uri={this.state.rtspFlux}
                        onDragEnter={this.onDragEnter.bind(this)}
                        onDragLeave={this.onDragLeave.bind(this)}
                        onDrag={this.onDrag.bind(this)}
                    />

                    <View
                        style={styles.tools}
                        pointerEvents='box-none'
                    >
                        <JoystickContainer
                            style={styles.joystick}
                            isTracking={this.state.isTracking}
                        />
                        {this.getRightPanelSettingsToggleButton()}
                        {this.getRightPanel()}
                    </View>
                </View>
            </View>
        )
    }
}

OptionsCameraContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    rtspFlux: PropTypes.string
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderWidth: 0,
        borderColor: '#FF0000'
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        padding: utils.moderateScale(10)
    },
    dropdown: {
        width: '100%'
    },
    joystick: {
        position: 'absolute',
        bottom: utils.moderateScale(10),
        left: utils.moderateScale(10),
        zIndex: 20
    },
    roomConfigurator: {
        position: 'absolute',
        bottom: utils.moderateScale(10),
        right: utils.moderateScale(10),
        zIndex: 20,
    },
    button: {
        backgroundColor: '#222'
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    player: {
        position: 'absolute',
        aspectRatio: 16 / 9,
    },
    tools: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#22222244",
    },
    buttonContainer: {
        backgroundColor: '#222222',
        borderRadius: 100,
        flexDirection: 'row',
    },
    buttonTextM: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(12),
        alignSelf: 'center',
        margin: utils.moderateScale(5)
    }
})

function mapStateToProps(state) {
    return {
        cameraState: state.cameraReducer,
        globalsState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        cameraActions: bindActionCreators(cameraActions, dispatch),
        globalsActions: bindActionCreators(globalsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsCameraContainer)