import React from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Dimensions } from 'react-native'
import { TextField } from 'react-native-material-textfield'

import * as utils from '../../../utils'

const PropTypes = require('prop-types')

class NamingContainer extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            name: ''
        }
    }

    exportProfile () {
        this.context.webSocketClient.publication.getToken({ provider_id: this.props.selectedProvider, code: this.props.code, name: this.state.name })
        this.props.onCancel()
    }

    render() {
        let createDisableStyle = { textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF50' }
        let filledFields = 1
        if (this.state.name) {
            filledFields = 0
            createDisableStyle = {textAlign: 'center',
            padding: utils.moderateScale(10),
            fontSize: utils.moderateScale(13),
            color: '#FFFFFF'}
        }

        return(
            <View style={styles.container}>
                <Text style={{ fontSize: utils.moderateScale(13), color: '#FFFFFF', marginTop: utils.moderateScale(10), marginLeft: utils.moderateScale(10)}}>Enter a name for the new profile :</Text>
                <View style={[styles.container, styles.formWrapper, { flex: 1}]} >
                    <TextField
                        value={this.state.name}
                        onChangeText={(name) => this.setState({ name: name })}
                        label= "Profile-name"
                        labelTextStyle={{ color: "white" }}
                        baseColor= "#565656"
                        labelFontSize={utils.moderateScale(10)}
                        fontSize={utils.moderateScale(12)}
                        textColor= "white"
                    />
                </View>

                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button} onPress={this.props.onCancel.bind(this)}>
                        <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.button} disabled={filledFields} onPress={this.exportProfile.bind(this)}>
                        <Text style={createDisableStyle}>Create Profile</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
}

NamingContainer.contextTypes = {
    webSocketClient: PropTypes.object
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        margin: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    buttonText2: {
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    }
})

export default NamingContainer