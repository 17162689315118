import * as types from '../constants/globals'

/** public **/
export function bottomMenuUpdated(newWidth, newHeight, x, y) {
    return {
        type: types.MAIN_SCREEN_BOTTOM_MENU_UPDATED,
        newWidth,
        newHeight,
        x,
        y
    }
}

export function setMainScreenVideoPlayerActivity(isActive) {
    return {
        type: types.MAIN_SCREEN_VIDEO_PLAYER_ACTIVITY,
        isActive
    }
}

export function setPlayerInstanceVolume(volume) {
    return {
        type: types.PLAYER_INSTANCE_VOLUME,
        volume
    }
}

export function switchMainScreenVideoMode(mainScreenVideoMode) {
    return {
        type: types.MAIN_SCREEN_VIDEO_MODE,
        mainScreenVideoMode
    }
}

export function refreshPlayerLayout() {
    return {
        type: types.REFRESH_PLAYER_LAYOUT
    }
}

export function setAppState(appState) {
    return {
        type: types.APP_STATE,
        appState
    }
}

export function setVideoPlayerInstance(videoPlayerInstance) {
    return {
        type: types.VIDEO_PLAYER_INSTANCE,
        videoPlayerInstance
    }
}

export function setColorPickerActive(isActive) {
    return {
        type: types.COLOR_PICKER_ACTIVE,
        isActive
    }
}

export function setColorPickerCoordinates(colorPickerCoordinates) {
    return {
        type: types.COLOR_PICKER_COORDINATES,
        colorPickerCoordinates
    }
}

export function setPopAllOnClose(popAllOnClose) {
    return {
        type: types.POP_ALL_ON_CLOSE,
        popAllOnClose
    }
}

export function switchPauseRenderPlayer(isActive) {
    return {
        type: types.PAUSE_RENDER_PLAYER,
        isActive
    }
}

export function displayExportIsVisible(isActive) {
    return {
        type: types.EXPORT_IS_VISIBLE,
        isActive
    }
}