import React from 'react'
import { View, Platform } from 'react-native'
import OptionsPanel from '../components/OptionsPanel'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../navigation/Navigator'

import Messenger from '../components/Messenger'

import * as globalsActions from '../actions/globals'
import * as utils from '../utils'

const PropTypes = require('prop-types')

let instance = undefined
class OptionsContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        instance = this
        this.state = { isHorizontal: undefined }
    }

    onPressBackButton(){
        Actions.pop();
    }

    onPressCameraButton() {
        Actions.push("OptionsCameraContainer")
        this.props.globalsActions.switchPauseRenderPlayer(true)
    }

    onNetworkNetworkButton() {
        Actions.OptionsNetworkContainer()
    }

    switchScene(scene) {
        this.context.webSocketClient.mixer.switchScene({
            scene_id: scene
        })
    }

    onPressStudioButton() {
        {/*const { scenes } = this.context.webSocketClient.mixer
    this.switchScene(scenes.SPEAKER_ABOVE_SCREEN)*/}
        Actions.push("OptionsStudioMode")
    }

    onPressPublishButton() {
        Actions.push("OptionsPublishingContainer")
    }

    onPressAudioButton() {
        if (this.props.capabilitiesState.hasAudioMixer)
            Actions.push("OptionsAudioMixerContainer")
        else
            Actions.push("OptionsAudioContainer")
    }

    onPressAboutButton() {
        Actions.push("OptionsAboutContainer")
    }

    onPressLogoButton() {
        Actions.push("OptionsLogoContainer")
    }

    onPressWatermarkButton() {
        Actions.push("OptionsWatermarkContainer")
    }

    onPressBackgroundButton() {
        Actions.push("OptionsBackgroundContainer")
    }

    onPressOutputModeButton() {
        Actions.push("OptionsOutputMode")
    }

    onPressResolutionButton() {
        Actions.push("OptionsResolutionContainer")
    }

    onPressLayoutModeButton() {
        Actions.push("OptionsLayoutMode")
    }

    onLayout() {
        this.setState({ isHorizontal: utils.getSize().isHorizontal })
    }

    render() {
        return (
            <View style={{ flex: 1 }} className="option-panel-container">
                <OptionsPanel onLayout={this.onLayout.bind(this)}
                    isHorizontal={this.state.isHorizontal}
                    onPressBackButton={() => this.onPressBackButton() }
                    onPressCameraButton={() => this.onPressCameraButton()}
                    onNetworkNetworkButton={() => this.onNetworkNetworkButton()}
                    onPressStudioButton={() => this.onPressStudioButton()}
                    onPressPublishButton={() => this.onPressPublishButton()}
                    onPressAudioButton={() => this.onPressAudioButton()}
                    onPressAboutButton={() => this.onPressAboutButton()}
                    onPressLogoButton={() => this.onPressLogoButton()}
                    onPressBackgroundButton={() => this.onPressBackgroundButton()}
                    onPressWatermarkButton={() => this.onPressWatermarkButton()}
                    onPressOutputModeButton={() => this.onPressOutputModeButton()}
                    onPressResolutionButton={() => this.onPressResolutionButton()}
                    onPressLayoutModeButton={() => this.onPressLayoutModeButton()}
                    maxIconSize={utils.getGridUnit() * 1.5}
                    isStudio={this.props.globalsState.mainScreenVideoMode === 'studio'}
                />
            </View>
        )
    }
}

OptionsContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

function mapStateToProps(state) {
    return {
        globalsState: state.globalsReducer,
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalsActions: bindActionCreators(globalsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsContainer)