import React from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform, Image } from 'react-native';
//import Swiper from 'react-native-swiper';

import OpenSettings from 'react-native-open-settings';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

import * as utils from '../../utils'

// Slide Menu Opener/Closer
var slideMenuOpenerIcon = require('../../design/icons/open_menu_arrow.png')
var slideMenuCloserIcon = require('../../design/icons/open_menu_arrow_reversed.png')

import ModalContainer from "../ModalContainer";

const steps = [
    {
        component: Step1,
        title: "Enable and check Wi-Fi connectivity"
    },
    {
        component: Step2,
        title: "Select the correct Wi-Fi Access-Point"
    },
    {
        component: Step3,
        title: "Enter the Wi-Fi password"
    },
    {
        component: Step4,
        title: "You are done"
    },
];

class ConnectionAssistWrapper extends React.Component {

    onCancel() {
        if (this.props.onCancel)
            this.props.onCancel()
    }

    renderStep(step, index) {
        const StepComponent = step.component
        return (
            <View style={{ flex: 1 }} key={index}>
                <View style={[styles.toolbar, styles.toolbarRounded, styles.stepToolbar]}>
                    <Text style={[styles.text, styles.title, styles.green]}>Step {(index + 1)} : {step.title}</Text>
                </View>

                <View style={[styles.step]}>
                    <StepComponent />
                </View>
            </View>

        )
    }

    renderPrevButton() {
        return (
            <View style={{ alignSelf: 'center', padding: utils.moderateScale(10), marginLeft: - utils.moderateScale(15)}}>
                <Image
                    source={slideMenuCloserIcon}
                    style={{
                        aspectRatio: 1,
                        resizeMode: 'contain',
                        alignSelf: 'center',
                        maxHeight: utils.moderateScale(25)
                    }}
                />
            </View>
        )
    }

    renderNextButton() {
        return (
            <View style={{ alignSelf: 'center', padding: utils.moderateScale(10), marginRight: - utils.moderateScale(15) }}>
                <Image
                    source={slideMenuOpenerIcon}
                    style={{
                        aspectRatio: 1,
                        resizeMode: 'contain',
                        alignSelf: 'center',
                        maxHeight: utils.moderateScale(25)
                    }}
                />
            </View>
        )
    }

    render() {

        const isAndroid = Platform.OS == "android"
        const settingsLabel = (isAndroid) ? "Wi-Fi Settings" : "Settings"

        const prevButton = this.renderPrevButton();
        const nextButton = this.renderNextButton();

        return (
            <ModalContainer
                animationType="fade"
                transparent={true}
                visible={this.props.isVisible}
                supportedOrientations={['portrait', 'landscape']}
                onRequestClose={() => { }}
                overlayClassName="connection-assist-wrapper"
            >
                <View style={[styles.modalWrapper]} className="services-exporter">
                    <View style={styles.modal}>
                        <View style={styles.container}>
 
                        </View>
                        <View style={styles.menuToolbar}>
                            <TouchableOpacity style={{justifyContent: 'center', alignContent: 'center',}} onPress={this.onCancel.bind(this)}>
                                <Text style={styles.buttonText}>Hide</Text>
                            </TouchableOpacity>
                            {/*<TouchableOpacity style={styles.button} onPress={() => OpenSettings.openSettings()}>
                                <Text style={styles.buttonText}>{settingsLabel}</Text>
                            </TouchableOpacity>*/}
                        </View>
                    </View>
                </View>
            </ModalContainer>
        )
    }
}

export default ConnectionAssistWrapper;

const styles = StyleSheet.create({
    modalWrapper: {
        justifyContent: 'center',
        alignContent: 'center',
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#000000AA'
    },
    modal: {
        flex: 1,
        maxWidth: '85%',
        maxHeight: '85%',
        alignSelf: 'center',
        backgroundColor: '#262626',
        borderRadius: utils.moderateScale(5),
        overflow: 'hidden'
    },
    container: {
        flex: 1,
    },
    scrollview: {
        flex: 1,
        height: '100%',
        width: '100%'
    },
    formWrapper: {
        padding: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    subtitle: {
        fontSize: utils.moderateScale(10),
        margin: utils.moderateScale(10)
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    menuToolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
        borderBottomLeftRadius: utils.moderateScale(5),
        borderBottomRightRadius: utils.moderateScale(5),
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        padding: utils.moderateScale(10)
    },
    toolbarRounded: {
        borderTopLeftRadius: utils.moderateScale(5),
        borderTopRightRadius: utils.moderateScale(5),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    marginTop: {
        marginTop: utils.moderateScale(20),
    },
    service: {
        borderRadius: utils.moderateScale(5),
        justifyContent: 'center',
        alignItems: 'center',
        padding: utils.moderateScale(10),
    },
    stepToolbar: {
        backgroundColor: '#00000005',
    },
    step: {
        flexGrow: 1,
        backgroundColor: '#FFFFFF07',
        flexDirection: 'row'
    },
    lastStep: {
        marginRight: utils.moderateScale(5)
    },
    green: {
        color: '#00FF24'
    },
    dotStyle: {
        top: utils.moderateScale(12)
    },
    activeDotStyle: {
        top: utils.moderateScale(12)
    }
})