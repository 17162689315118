import { Alert, Platform } from 'react-native'
var Toast = require('react-native-toast');
import { toast } from 'react-toastify';


function webToastOptions(type) {
    return {
        autoClose: 2000,
        type: type,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: true
    };
}

function isString(value) {
    return (typeof value === 'string' || value instanceof String)
}

export default class Messenger {
    static error(message) {
        if (!isString(message))
            message = ""

        if (message.length > 0) {
            if (Platform.OS !== "web")
            {
                Alert.alert(
                    'Error', message,
                    [{ text: 'OK', onPress: () => { }, style: 'cancel' }],
                    { cancelable: false }
                )
            } else {
                toast(message, webToastOptions(toast.TYPE.ERROR))
            }
        }
    }

    static warning(message) {
        if (!isString(message))
            message = ""

        if (message.length > 0) {
            if (Platform.OS !== "web")
            {
                Alert.alert(
                    'Warning', message,
                    [{ text: 'OK', onPress: () => { }, style: 'cancel' }],
                    { cancelable: false }
                )
            } else {
                toast(message, webToastOptions(toast.TYPE.WARNING))
            }
        }
    }

    static prompt(title, message, buttons, options) {
        if (Platform.OS !== "web")
        {
            Alert.alert(title, message, buttons, options)
        } else {
            if (confirm(message)) {
                for (const buttonIndex in buttons)
                {
                    const button = buttons[buttonIndex];
                    if (button.style === "cancel")
                        continue

                    if (button.onPress)
                        button.onPress()
                }
            }
        }
            
    }

    static notify(message) {
        if (!isString(message))
            message = ""

        if (message.length > 0 && Platform.OS !== 'web')
            Toast.show(message)

        toast(message, webToastOptions(toast.TYPE.INFO))
    }

    static notifySuccess(message) {
        if (!isString(message))
            message = ""

        if (message.length > 0 && Platform.OS !== 'web')
            Toast.show(message)

        toast(message, webToastOptions(toast.TYPE.SUCCESS))
    }
}