import React from 'react'
import { View, Text, LayoutAnimation, StyleSheet } from 'react-native'
import { Switch } from 'react-native-switch';

import { connect } from 'react-redux'

import Button from 'react-native-button';

import * as utils from '../../../utils'

const PropTypes = require('prop-types')

class AdvancedSettingsContainer extends React.Component {

    requestedMode = undefined

    constructor(props, context) {
        super(props, context)

        this.state = {
            focusValue: 3,
            autoFocus: true,
            autoWhiteBalance: true,
            whiteBalanceChoices: [],
            whiteBalanceModalVisible: false,
            whiteBalanceCurrentChoice: undefined,
            canChangeWhiteBalance: true,
        }

        this.context.webSocketClient.camera.onGetWhiteBalanceMode(
            this.onGetWhiteBalanceMode, this
        )

        this.context.webSocketClient.camera.onGetFocusAuto(
            this.onGetFocusAuto, this
        )

        this.context.webSocketClient.camera.onGetFocus(
            this.onGetFocus, this
        )

        this.context.webSocketClient.camera.listWhiteBalanceModes()
        this.context.webSocketClient.camera.getWhiteBalanceMode()

        this.context.webSocketClient.camera.getFocus()
        this.context.webSocketClient.camera.getFocusAuto()
    }

    componentWillUnmount() {
        this.context.webSocketClient.camera.offGetWhiteBalanceMode(
            this.onGetWhiteBalanceMode
        )

        this.context.webSocketClient.camera.offGetFocusAuto(
            this.onGetFocusAuto
        )

        this.context.webSocketClient.camera.offGetFocus(
            this.onGetFocus
        )
    }

    onGetFocus(newValue) {
        console.log('onGetFocus', newValue)
    }

    onGetFocusAuto(newValue) {
        this.setState({
            autoFocus: newValue.auto
        })
    }

    onAutoFocusChanged(newValue) {
        if (this.state.autoFocus !== newValue) {
            this.context.webSocketClient.camera.setFocusAuto({
                auto: newValue
            })
        }
    }

    onAutoWhiteBalanceChanged(newValue) {
        if (this.state.autoWhiteBalance !== newValue) {
            if (newValue) { }
            const newMode = newValue ? 'Auto' : 'One Push'
            this.setWhiteBalanceMode(newMode)
        }
    }

    onWhiteBalancePickerCanceled() {
        this.setState({
            whiteBalanceModalVisible: false
        })
    }

    onGetWhiteBalanceMode(mode) {
        mode = mode.mode

        if (mode) {
            this.setState({
                whiteBalanceCurrentChoice: mode,
                autoWhiteBalance: (mode === 'Auto')
            })
        } else {
            setTimeout(() => {
                this.context.webSocketClient.camera.setWhiteBalanceMode({ mode: this.requestedMode })
            }, 350)
        }
    }

    setWhiteBalanceMode(mode) {
        this.requestedMode = mode
        if (this.state.whiteBalanceCurrentChoice !== mode)
            this.context.webSocketClient.camera.setWhiteBalanceMode({ mode })

        if (mode === 'One Push')
            this.context.webSocketClient.camera.onePushWhiteBalance()
    }

    focusIn() {
        this.context.webSocketClient.camera.focusNear()
    }

    focusOut() {
        this.context.webSocketClient.camera.focusFar()
    }

    focusStop() {
        this.context.webSocketClient.camera.focusStop()
    }

    flip(){
        this.context.webSocketClient.camera.flip()
    }

    renderFocusButtons() {
        if (!this.state.autoFocus) {
            return (
                <View style={[styles.row, {
                    justifyContent: 'space-between'
                }]}>
                    <Button
                        containerStyle={[styles.buttonContainer, {
                            height: utils.getButtonGridUnit() / 1.5,
                        }]}
                        onPressIn={this.focusOut.bind(this)}
                        onPressOut={ this.focusStop.bind(this)}
                        style={[styles.buttonText, {
                            paddingHorizontal: utils.moderateScale(utils.getButtonGridUnit() / 4),
                            margin: 0
                        }]}
                    >-</Button>
                    <Button
                        containerStyle={[styles.buttonContainer, {
                            height: utils.getButtonGridUnit() / 1.5,
                        }]}
                        onPressIn={this.focusIn.bind(this)}
                        onPressOut={ this.focusStop.bind(this)}
                        style={[styles.buttonText, {
                            paddingHorizontal: utils.moderateScale(utils.getButtonGridUnit() / 4),
                            margin: 0
                        }]}
                    >+</Button>
                </View>
            )
        }
    }

    renderOnePushButton() {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        if (!this.state.autoWhiteBalance) {
            return (
                <View style={[styles.row, {
                    justifyContent: 'flex-end'
                }]}>
                    <Button
                        containerStyle={[styles.buttonContainer, {
                            height: utils.getButtonGridUnit() / 1.5
                        }]}
                        onPress={() => this.setWhiteBalanceMode('One Push')}
                        style={styles.buttonText}
                        disabled={this.state.autoWhiteBalance}
                        
                    >Set white balance</Button>
                </View>
            )
        }
    }

    render() {
        let containerHeight = utils.getButtonGridUnit() * 5

        if (!this.state.autoWhiteBalance)
            containerHeight += utils.getButtonGridUnit()

        if (!this.state.autoFocus)
            containerHeight += utils.getButtonGridUnit()

        return (
            
            <View style={{ flex: 1 }} pointerEvents='box-none'>
                <View
                    style={StyleSheet.flatten([styles.container, {
                        height: containerHeight
                    }, this.props.style])}
                    pointerEvents='box-none'
                >
                    <View style={{ flex: 1, opacity: 1 }}>
                        <Text style={styles.text}>Focus</Text>
                        <View style={styles.row}>
                            <Text style={styles.inlineText}>Auto</Text>
                            <Switch
                                onValueChange={this.onAutoFocusChanged.bind(this)}
                                value={this.state.autoFocus}
                                backgroundActive='#02FF24'
                                activeText=''
                                inActiveText=''
                                circleSize={utils.moderateScale(18)}
                                changeValueImmediately={true}
                            />

                        </View>
                        {this.renderFocusButtons()}
                    </View>

                    <View style={{ flex: 1, opacity: 1 }}>
                        <View style={styles.column}>
                            <Text style={styles.text}>White Balance</Text>
                            <View style={styles.row}>
                                <Text style={styles.inlineText}>Auto</Text>
                                <Switch
                                    onValueChange={this.onAutoWhiteBalanceChanged.bind(this)}
                                    value={this.state.autoWhiteBalance}
                                    backgroundActive='#02FF24'
                                    activeText=''
                                    inActiveText=''
                                    circleSize={utils.moderateScale(18)}
                                    changeValueImmediately={true}
                                />
                            </View>
                            {this.renderOnePushButton()}
                        </View>
                    </View>                  
                    <View style={{ flex: 1, opacity: 1 }}>
                        <View style={styles.column}>                            
                            <View style={styles.row}>
                                <Button
                                    containerStyle={[styles.buttonContainer, {
                                        height: utils.getButtonGridUnit() / 1.5
                                    }]}
                                    onPress={() => this.flip()}
                                    style={styles.buttonText}
                                >Flip</Button>
                            </View>
                        </View>
                    </View>                      
                </View>                
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        width: utils.getButtonGridUnit() * 4
    },
    icon: {
        position: 'absolute',
        padding: utils.moderateScale(utils.getButtonGridUnit() / 8),
        backgroundColor: '#222222',
        borderRadius: utils.moderateScale(utils.getButtonGridUnit()),
        width: utils.getButtonGridUnit(),
        height: utils.getButtonGridUnit()
    },
    text: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(14),
        alignSelf: 'flex-end',
        paddingTop: utils.moderateScale(10),
    },
    inlineText: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(14),
        paddingHorizontal: utils.moderateScale(10),
    },
    buttonContainer: {
        backgroundColor: '#222222',
        borderRadius: 100,
        flexDirection: 'row',
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(12),
        alignSelf: 'center',
        margin: utils.moderateScale(5)
    },
    columnText: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(13),
        alignSelf: 'flex-end',
        paddingTop: utils.moderateScale(10),
    },
    column: {
        flexDirection: 'column',
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
})

AdvancedSettingsContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

function mapStateToProps(state) {
    return {
        globalsState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSettingsContainer)