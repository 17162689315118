import React from 'react';
import { View, Image, Text, StyleSheet, Platform } from 'react-native';

import * as utils from '../../utils'

const ios_step = require("../../design/screenshots/ios_step_1.png");
const android_step = require("../../design/screenshots/android_step_1.png");

export default class Step1 extends React.Component {
    renderAndroidText() {
        return (
            <View style={styles.textContainer}>
                <Text style={[styles.text, styles.title]}>We need to ensure the Wi-Fi is enabled</Text>
                <Text style={[styles.text]}>1. If the Wi-Fi is not activated, please enable it</Text>
            </View>
        )
    }

    renderIosText() {
        return (
            <View style={styles.textContainer}>
                <Text style={[styles.text, styles.title]}>We need to ensure the Wi-Fi is enabled</Text>
                <Text style={[styles.text]}>1. Enter the Wi-Fi section</Text>
                <Text style={[styles.text]}>2. If it is not enabled, please enable it</Text>
            </View>
        );
    }

    render() {

        const isAndroid = Platform.OS == "android"
        const image = isAndroid ? android_step : ios_step;
        const aspectRatio = isAndroid ? 16 / 10 : 4 / 3;

        return (
            <View style={styles.container} >
                {isAndroid ? this.renderAndroidText() : this.renderIosText()}

                <View style={styles.imageHolder}>
                    <Image
                        resizeMode="contain"
                        style={{ aspectRatio, flex: 1, maxHeight: '85%', maxWidth: '85%', borderRadius: utils.moderateScale(5) }}
                        source={image}
                    />
                </View>
            </View >
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignContent: 'center',
        flexDirection: 'column'
    },
    imageHolder: {
        flexGrow: 3,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    textContainer: {
        padding: utils.moderateScale(20),
        paddingBottom: 0
    },
    text: {
        fontSize: utils.moderateScale(11),
        color: '#FFFFFF',
        textAlign: 'center',
    },
    verticalCenter: {
        flexGrow: 1,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center'
    },
    title: {
        marginBottom: utils.moderateScale(20),
    },
})