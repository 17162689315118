import React from "react"
import { View, Text, TouchableOpacity, ScrollView, Image, LayoutAnimation, StyleSheet, WebView, Dimensions, StatusBar } from "react-native"

import { TextField } from 'react-native-material-textfield'
import Grid from 'react-native-photo-grid'

import { Actions } from 'react-native-router-flux'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../../actions/globals'

import Messenger from '../../../components/Messenger'

import * as utils from '../../../utils'
import ModalContainer from "../../ModalContainer"

const PropTypes = require('prop-types')

class EditContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            connectorData: {
                provider_id: 0,
                user_id: 0,
                host: '',
                port: 0,
                username: '',
                password: '',
                key: '',
                name: ''
            },
            claimed: false
        }

        this.context.webSocketClient.publication.onGetAuthUrl(
            this.onGetAuthUrl, this
        )

        this.context.webSocketClient.publication.onStartModification(
            this.onStartModification, this
        )
    }

    componentDidMount() {
        if (this.state.claimed == false) {
            this.context.webSocketClient.publication.startModification({user_id: this.props.data.user_id, provider_id: this.props.data.provider_id})
        }
    }

    onStartModification(data) {
        this.setState({
            connectorData: data,
            claimed: true
        })
    }

    onLayout() {
        this.setState({ itemsPerRow: 4 })
    }

    renderCommonSectionTitle() {
        return (
            <Text style={[styles.text, styles.title]}>Select the desired publishing service</Text>
        )
    }

    renderNameField() {
        if (this.props.data.provider_id === 0) {
            const color = "#FFFFFF"
            return (
                <View>
                    <ScrollView >
                        <Text style={[styles.text, styles.title, styles.marginTopBis]}>Edit the Parameters of your <Text style={{ color: "#00FF24" }}>SFTP Connection</Text></Text>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Host"
                            value={this.state.connectorData.host}
                            onChangeText={(host) => { this.setState({
                                connectorData: {host,
                                    port: this.state.connectorData.port,
                                    username: this.state.connectorData.username,
                                    password: this.state.connectorData.password,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id,
                                    key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="hostField"
                        />
                        <TextField
                            defaultValue={null}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            keyboardType='phone-pad'
                            label="Port"
                            value={this.state.connectorData.port.toString()}
                            onChangeText={(port) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                    port,
                                    username: this.state.connectorData.username,
                                    password: this.state.connectorData.password,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id,
                                    key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="portField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Username"
                            value={this.state.connectorData.username}
                            onChangeText={(username) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                    port: this.state.connectorData.port,
                                    username,
                                    password: this.state.connectorData.password,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id,
                                    key: this.state.connectorData.key}}) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="usernameField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Password"
                            value={this.state.connectorData.password}
                            onChangeText={(password) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                port: this.state.connectorData.port,
                                username: this.state.connectorData.username,
                                password,
                                name: this.state.connectorData.name,
                                provider_id: this.state.connectorData.provider_id,
                                user_id: this.state.connectorData.user_id,
                                key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="passwordField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Name"
                            value={this.state.connectorData.name}
                            onChangeText={(name) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                port: this.state.connectorData.port,
                                username: this.state.connectorData.username,
                                password: this.state.connectorData.password,
                                name,
                                provider_id: this.state.connectorData.provider_id,
                                user_id: this.state.connectorData.user_id,
                                key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="nameField"
                        />
                    </ScrollView>
                </View>
            )
        } else if (this.props.data.provider_id === 6) {
            const color = "#FFFFFF"
            return (
                <View>
                    <ScrollView >
                        <Text style={[styles.text, styles.title, styles.marginTop]}>Edit the Parameters of your <Text style={{ color: "#00FF24" }}>FTP Connection</Text></Text>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Host"
                            value={this.state.connectorData.host}
                            onChangeText={(host) => { this.setState({
                                connectorData: {host,
                                    port: this.state.connectorData.port,
                                    username: this.state.connectorData.username,
                                    password: this.state.connectorData.password,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id,
                                    key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="hostField"
                        />
                        <TextField
                            defaultValue={null}
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            keyboardType='phone-pad'
                            label="Port"
                            value={this.state.connectorData.port.toString()}
                            onChangeText={(port) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                    port,
                                    username: this.state.connectorData.username,
                                    password: this.state.connectorData.password,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id,
                                    key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="portField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Username"
                            value={this.state.connectorData.username}
                            onChangeText={(username) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                    port: this.state.connectorData.port,
                                    username,
                                    password: this.state.connectorData.password,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id,
                                    key: this.state.connectorData.key}}) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="usernameField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Password"
                            value={this.state.connectorData.password}
                            onChangeText={(password) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                port: this.state.connectorData.port,
                                username: this.state.connectorData.username,
                                password,
                                name: this.state.connectorData.name,
                                provider_id: this.state.connectorData.provider_id,
                                user_id: this.state.connectorData.user_id,
                                key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="passwordField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Name"
                            value={this.state.connectorData.name}
                            onChangeText={(name) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                port: this.state.connectorData.port,
                                username: this.state.connectorData.username,
                                password: this.state.connectorData.password,
                                name,
                                provider_id: this.state.connectorData.provider_id,
                                user_id: this.state.connectorData.user_id,
                                key: this.state.connectorData.key} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="nameField"
                        />
                    </ScrollView>
                </View>
            )
        } else if (this.props.data.provider_id === 5) {
            const color = "#FFFFFF"
            return (
                <View>
                    <ScrollView>
                        <Text style={{color: '#FFFFFF'}}></Text>
                        <Text style={[styles.text, styles.title, styles.marginTop]}>Edit the Parameters of your <Text style={{ color: "#00FF24" }}>RTMP Connection</Text></Text>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="URL"
                            value={this.state.connectorData.host}
                            onChangeText={(host) => { this.setState({
                                connectorData: {host,
                                    username: this.state.connectorData.username,
                                    password: this.state.connectorData.password,
                                    key: this.state.connectorData.key,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="urlField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Username"
                            value={this.state.connectorData.username}
                            onChangeText={(username) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                    username,
                                    password: this.state.connectorData.password,
                                    key: this.state.connectorData.key,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="usernameField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Password"
                            autoCompleteType='password'
                            value={this.state.connectorData.password}
                            onChangeText={(password) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                    username: this.state.connectorData.username,
                                    password,
                                    key: this.state.connectorData.key,
                                    name: this.state.connectorData.name,
                                    provider_id: this.state.connectorData.provider_id,
                                    user_id: this.state.connectorData.user_id}}) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="passwordField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Key"
                            value={this.state.connectorData.key}
                            onChangeText={(key) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                username: this.state.connectorData.username,
                                password: this.state.connectorData.password,
                                key,
                                name: this.state.connectorData.name,
                                provider_id: this.state.connectorData.provider_id,
                                user_id: this.state.connectorData.user_id} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="keyField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Name"
                            value={this.state.connectorData.name}
                            onChangeText={(name) => { this.setState({
                                connectorData: {host: this.state.connectorData.host,
                                username: this.state.connectorData.username,
                                password: this.state.connectorData.password,
                                key: this.state.connectorData.key,
                                name,
                                provider_id: this.state.connectorData.provider_id,
                                user_id: this.state.connectorData.user_id} }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="nameField"
                        />
                    </ScrollView>
                </View>
            )
        } else if (this.props.data.provider_id === 7) {
            const color = "#FFFFFF"
            return (
                <View>
                    <ScrollView>
                        <Text style={{color: '#FFFFFF'}}></Text>
                        <Text style={[styles.text, styles.title, styles.marginTop]}>Edit the Parameters of your <Text style={{ color: "#00FF24" }}>Pod Server</Text></Text>
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="URL"
                            value={this.state.connectorData.host}
                            onChangeText={(host) => { this.setState({
                                connectorData: {
                                    host,
                                    token: this.state.connectorData.token,
                                } }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="urlField"
                        />
                        <TextField
                            labelFontSize={utils.moderateScale(10)}
                            fontSize={utils.moderateScale(12)}
                            label="Token"
                            value={this.state.connectorData.token}
                            onChangeText={(token) => { this.setState({
                                connectorData: {
                                    host: this.state.connectorData.host,
                                    token
                                } }) }}
                            textColor={color} tintColor={color} baseColor={color}
                            ref="keyField"
                        />
                    </ScrollView>
                </View>
            )
        }
    }

    create() {
        if (this.state.connectorData.name === '' && this.state.connectorData.provider_id === 0) {
            this.context.webSocketClient.publication.fetchModification({
                provider_id: 0,
                user_id: this.state.connectorData.user_id,
                host: this.state.connectorData.host,
                port: this.state.connectorData.port.toString(),
                username: this.state.connectorData.username,
                password: this.state.connectorData.password,
                name: this.state.connectorData.username,
                key: this.state.connectorData.key
            })
        } else if (this.state.connectorData.name === '' && this.state.connectorData.provider_id === 6) {
            this.context.webSocketClient.publication.fetchModification({
                provider_id: 6,
                user_id: this.state.connectorData.user_id,
                host: this.state.connectorData.host,
                port: this.state.connectorData.port.toString(),
                username: this.state.connectorData.username,
                password: this.state.connectorData.password,
                name: this.state.connectorData.username,
                key: this.state.connectorData.key
            })
        } else if (this.state.connectorData.name === '' && this.state.connectorData.provider_id === 5) {
            this.context.webSocketClient.publication.fetchModification({
                provider_id: 5,
                user_id: this.state.connectorData.user_id,
                host: this.state.connectorData.host,
                port: "-1",
                username: this.state.connectorData.username,
                password: this.state.connectorData.password,
                key: this.state.connectorData.key,
                name: this.state.connectorData.username
            })
        } else if (this.state.connectorData.provider_id === 0) {
            this.context.webSocketClient.publication.fetchModification({
                provider_id: 0,
                user_id: this.state.connectorData.user_id,
                host: this.state.connectorData.host,
                port: this.state.connectorData.port.toString(),
                username: this.state.connectorData.username,
                password: this.state.connectorData.password,
                name: this.state.connectorData.name,
                key: this.state.connectorData.key
            })
        } else if (this.state.connectorData.provider_id === 6) {
            this.context.webSocketClient.publication.fetchModification({
                provider_id: 6,
                user_id: this.state.connectorData.user_id,
                host: this.state.connectorData.host,
                port: this.state.connectorData.port.toString(),
                username: this.state.connectorData.username,
                password: this.state.connectorData.password,
                name: this.state.connectorData.name,
                key: this.state.connectorData.key
            })
        } else if (this.state.connectorData.provider_id === 5) {
            this.context.webSocketClient.publication.fetchModification({
                provider_id: 5,
                user_id: this.state.connectorData.user_id,
                host: this.state.connectorData.host,
                port: "-1",
                username: this.state.connectorData.username,
                password: this.state.connectorData.password,
                key: this.state.connectorData.key,
                name: this.state.connectorData.name
            })
        } else if (this.props.data.provider_id === 7) {
            this.context.webSocketClient.publication.updatePodServer({
                token: this.state.connectorData.token,
                server: this.state.connectorData.host
            })
        }
        this.cancel()
    }

    cancel() {
        this.props.onCancel()
    }

    render() {
        let createDisableStyle = { color: '#FFFFFF50' }
        let filledFields = 1
        if (this.state.connectorData.provider_id === 0 && this.state.connectorData.host.length > 0 && this.state.connectorData.username.length > 0 && this.state.connectorData.password.length > 0) {
            filledFields = 0
            createDisableStyle = {}
        } else if (this.state.connectorData.provider_id === 6 && this.state.connectorData.host.length > 0 && this.state.connectorData.username.length > 0 && this.state.connectorData.password.length > 0) {
            filledFields = 0
            createDisableStyle = {}
        } else if (this.state.connectorData.provider_id === 5 && this.state.connectorData.host.length > 0 && this.state.connectorData.key.length > 0) {
            filledFields = 0
            createDisableStyle = {}
        } else if (this.props.data.provider_id === 7) {
            filledFields = 0
            createDisableStyle = {}
        }

        return (
            <View style={styles.container} onLayout={this.onLayout.bind(this)} className="publishing-container-creator">
                <ScrollView ref={ref => this.scrollView = ref}>
                    <View style={[styles.container, styles.formWrapper, { flex: 1 }]}>

                        {this.renderCommonSectionTitle()}
                        {this.renderNameField()}
                    </View>
                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button} onPress={this.cancel.bind(this)}>
                        <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.button} disabled={filledFields} onPress={this.create.bind(this)}>
                        <Text style={[styles.buttonText, createDisableStyle]}>Edit</Text>
                    </TouchableOpacity>

                </View>
            </View>
        )
    }
}


function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

EditContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    buildIsDemo: PropTypes.bool
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        margin: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    marginTop: {
        marginTop: utils.moderateScale(20),
    },
    marginTopBis: {
        marginTop: utils.moderateScale(21),
    },
    service: {
        borderRadius: utils.moderateScale(5),
        justifyContent: 'center',
        alignItems: 'center',
        padding: utils.moderateScale(10),
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContainer)