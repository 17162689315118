import React from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as systemActions from '../../actions/system'

import * as utils from '../../utils'
import IconedButton from '../../components/IconedButton'

var mixerIcon = require('../../design/icons/scene_full.png')
var presentationIcon = require('../../design/icons/scene_screen.png')
var cameraIcon = require('../../design/icons/scene_speaker.png')

const PropTypes = require('prop-types')

class OptionsOutputMode extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            outputMode: -1
        }

        this.context.webSocketClient.mixer.onGetDisplayOutput(
            this.onGetDisplayOutput, this
        );

        this.context.webSocketClient.mixer.getDisplayOutput();
    }

    componentWillUnmount() {
        this.context.webSocketClient.mixer.offGetDisplayOutput(
            this.onGetDisplayOutput
        );
    }

    onGetDisplayOutput(data) {
        this.setState({
            outputMode: (data.id !== null && data.id !== undefined) ? data.id : -1
        })
    }

    getIcon(image, outputMode, label) {
        return (
            <IconedButton
                image={image}
                onPress={() => this.onPress(outputMode)}
                label={label}
                style={[styles.icon, (this.state.outputMode == outputMode) ? styles.activeIcon : {}]}
                maxIconSize={utils.moderateScale(100)}
                key={label}
            />
        )
    }

    getIcons() {
        let icons = []

        // Mixer
        icons.push(this.getIcon(mixerIcon, 0, 'Final result'))

        // Presentation
        icons.push(this.getIcon(presentationIcon, 1, 'Presentation'))

        // Camera
        icons.push(this.getIcon(cameraIcon, 2, 'Camera'))

        return icons
    }

    onPress(id) {
        this.context.webSocketClient.mixer.setDisplayOutput({ id })
    }

    render() {
        return (
            <View style={styles.container}>
                <ScrollView style={styles.containerScroll}>
                    <Text style={[styles.text, styles.centered]}>Select the desired output content to broadcast from the HDMI output port</Text>
                    <View style={[styles.row, styles.margin, styles.centered]}>
                        {this.getIcons().map((icon) => { return icon; })}
                    </View>
                </ScrollView>
            </View>
        )
    }
}

OptionsOutputMode.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    text: {
        color: '#FFFFFF',
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontSize: utils.moderateScale(13)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerScroll: {
        padding: utils.moderateScale(10),
        flex: 1
    },
    centered: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    margin: {
        margin: utils.moderateScale(10),
    },
    icon: {
        margin: utils.moderateScale(20),
        padding: utils.moderateScale(20),
        backgroundColor: '#000',
        alignItems: 'flex-start',
        backgroundColor: '#333333',
        borderRadius: utils.moderateScale(10)
    },
    activeIcon: {
        padding: utils.moderateScale(20) - 2,
        borderWidth: 2,
        borderColor: '#02FF24',
        backgroundColor: '#111'
    },
})

function mapStateToProps(state) {
    return {
        systemState: state.systemReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        systemActions: bindActionCreators(systemActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsOutputMode)