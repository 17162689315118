import React from 'react'
import { View, Image, Text, TouchableOpacity, StyleSheet } from 'react-native'

import * as utils from '../utils'

export default class IconedButton extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentImage: this.props.image,
            isDisabled: undefined
        }
    }

    onPress() {
        if (this.props.onPress && !this.props.isDisabled)
            this.props.onPress()
    }

    onPressIn() {
        if (this.props.onPressIn && !this.props.isDisabled)
            this.props.onPressIn()
    }

    onPressOut() {
        if (this.props.onPressOut && !this.props.isDisabled)
            this.props.onPressOut()
    }

    onLongPress() {
        if (this.props.onLongPress && !this.props.isDisabled)
            this.props.onLongPress()
    }

    onLayout(layout) {
        if (this.props.onLayout) {
            this.props.onLayout(layout)
        }
    }

    render() {
        let label = null
        if (this.props.label)
            label = <Text style={[styles.label, {
                paddingTop: this.props.labelPaddingTop !== undefined ? this.props.labelPaddingTop : utils.moderateScale(10),
                color: this.props.labelColor ? this.props.labelColor : '#FFFFFF'
            }]}>{this.props.label}</Text>

        let disabledStyle = (this.state.isDisabled) ? {
            opacity: 0.2
        } : {}

        let { isActive, activeImage, image } = this.props
        let renderImage = isActive ? activeImage : image

        const aspectRatio = (this.props.aspectRatio !== undefined) ? this.props.aspectRatio : 1
        return (
            <TouchableOpacity
                style={[{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    borderColor: '#00FF00',
                    borderWidth: 0,
                    height: (this.props.maxIconSize !== undefined) ? this.props.maxIconSize : '100%',
                }, this.props.style]}

                onLayout={this.onLayout.bind(this)}
                onPress={this.onPress.bind(this)}
                onPressIn={this.onPressIn.bind(this)}
                onPressOut={this.onPressOut.bind(this)}
                onLongPress={this.onLongPress.bind(this)}
                disabled={this.props.isDisabled}

                className="iconed-button-touchable"
            >
                <Image
                    style={StyleSheet.flatten([{
                        height: '100%',
                        width: this.props.maxIconSize * aspectRatio,
                        aspectRatio: aspectRatio,
                        resizeMode: 'contain',
                        alignSelf: 'center',
                        opacity: (this.props.opacity !== undefined) ? this.props.opacity : 1,
                        borderColor: '#FF0000',
                        borderWidth: 0,
                        flex: 1
                    }, disabledStyle])}

                    source={renderImage}

                    className="iconed-button-image"
                />
                {label}
            </TouchableOpacity>


            /*
            <TouchableOpacity
                onLayout={this.onLayout.bind(this)}
                onPress={this.onPress.bind(this)}
                style={StyleSheet.flatten([{ padding: utils.moderateScale(5) }, {
                    borderColor: '#FF0000',
                    borderWidth: 2
                }, this.props.style])}
                onPressIn={this.onPressIn.bind(this)}
                onPressOut={this.onPressOut.bind(this)}
                onLongPress={this.onLongPress.bind(this)}
                disabled={this.props.isDisabled}
            >
                <View style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                }}>
                    <Image
                        style={StyleSheet.flatten([{
                            flex: 1,
                            aspectRatio: (this.props.aspectRatio !== undefined) ? this.props.aspectRatio : 1,
                            maxHeight: (this.props.maxIconSize !== undefined) ? this.props.maxIconSize : '100%',
                            resizeMode: 'contain',
                            alignSelf: 'center',
                            opacity: (this.props.opacity !== undefined) ? this.props.opacity : 1,
                            overflow: 'hidden'
                        }, disabledStyle])}

                        source={renderImage}
                    />
                    {label}
                </View>
            </TouchableOpacity>
            */
        )
    }
}

const styles = StyleSheet.create({
    label: {
        fontSize: utils.moderateScale(11),
        alignSelf: 'center'
    },
})