import React from 'react'
import ReactModal from 'react-modal';

export default class ModalContainer extends React.Component {
    render()
    {
        return (
            <ReactModal
                isOpen={this.props.visible}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                overlayClassName={this.props.overlayClassName || "modal_overlay" }
                
                className={this.props.className || "modal_content"}

                {...this.props}
            >
                {this.props.children}
            </ReactModal>
        )
    }
}