import React from "react"
import { View, Text, ScrollView, KeyboardAvoidingView, TouchableOpacity, StyleSheet } from "react-native"

import { TextField } from 'react-native-material-textfield'
import { Switch } from 'react-native-switch';

import { Actions } from '../../../navigation/Navigator'

import * as utils from '../../../utils'

const PropTypes = require('prop-types')

const fieldTypes = {
    CHARFIELD: "CharField",
    BOOLEANFIELD: "BooleanField",
    NUMBERFIELD: "IntegerField"
}

export default class PublishingContainerEditor extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            connector: {
                fields: {
                    name: {
                        value: ''
                    }
                }
            },
            savedName: ''
        }

        this.context.webSocketClient.publication.onSave(
            this.onSave, this
        )
    }

    componentDidMount() {
        let connector = null;
        try {
            connector = this.props.connector || this.props.location.state.connector
        } catch (error) {}

        this.setState({
            connector: connector,
            savedName: connector.fields.name.value
        })
    }

    componentWillUnmount() {
        this.context.webSocketClient.publication.offSave(
            this.onSave
        )
    }

    onSave(connector) {
        this.setState({
            connector: connector,
            savedName: connector.fields.name.value
        }, () => {
            Actions.pop()
            this.context.webSocketClient.publication.getConnectors()
        })
    }

    getFields() {
        return Object.keys(this.state.connector.fields).map((key, i) => {
            const field = this.state.connector.fields[key]
            if (field.name !== '/name')
                return (
                    <View style={styles.marginTop} key={'wrapper_' + field.name}>
                        {this.getField(field)}
                    </View>
                )
        })
    }

    getField(field) {
        if (field.type) {
            if (field.type === fieldTypes.CHARFIELD) {
                return (
                    <TextField
                        labelFontSize={utils.moderateScale(10)}
                        fontSize={utils.moderateScale(12)}
                        label={field.verbose_name}
                        value={field.value}
                        onChangeText={(value) => {
                            const newState = Object.assign({}, this.state)
                            newState.connector.fields[field.name].value = value
                            this.setState(newState)
                        }}
                        textColor={"#FFFFFF"} tintColor={"#FFFFFF"} baseColor={"#FFFFFF"}
                        key={field.type}
                    />
                )
            } else if (field.type === fieldTypes.NUMBERFIELD) {
                return (
                    <TextField
                        labelFontSize={utils.moderateScale(10)}
                        fontSize={utils.moderateScale(12)}
                        label={field.verbose_name}
                        value={field.value + ""}
                        onChangeText={(value) => {
                            const newState = Object.assign({}, this.state)
                            newState.connector.fields[field.name].value = parseFloat(value)
                            this.setState(newState)
                        }}
                        textColor={"#FFFFFF"} tintColor={"#FFFFFF"} baseColor={"#FFFFFF"}
                        key={field.type}
                    />
                )
            } else if (field.type === fieldTypes.BOOLEANFIELD) {
                return (
                    <View style={styles.row}>
                        <Text style={styles.inlineText}>{field.verbose_name}</Text>
                        <Switch
                            onValueChange={(value) => {
                                if (value !== this.state.connector.fields[field.name].value) {
                                    const newState = Object.assign({}, this.state)
                                    newState.connector.fields[field.name].value = value
                                    this.setState(newState)
                                }
                            }}
                            value={this.state.connector.fields[field.name].value}
                            backgroundActive='#02FF24'
                            activeText=''
                            inActiveText=''
                            circleSize={utils.moderateScale(18)}
                            changeValueImmediately={true}
                        />
                    </View>
                )
            } else {
                console.warn("Unhandled type : " + field.type)
            }
        }
    }

    save() {
        let connector = this.state.connector
        this.context.webSocketClient.publication.save({
            'service': connector
        })
    }

    cancel() {
        this.context.webSocketClient.publication
        Actions.pop()
    }

    delete() {
        const connector = this.state.connector
        if (this.props.deletePressed)
        {
            this.props.deletePressed(this.props.connector, () => {
                Actions.pop()
            })
        } else {
            if (confirm("Are you sure your want to remove the following publishing profile : " + connector.fields.name.value + " ?"))
            {
                this.context.webSocketClient.publication.remove({
                    'service': connector
                })
                
                Actions.pop()
            }
        }
    }

    render() {
        return (
            <KeyboardAvoidingView enabled behavior="padding" style={styles.container}>
                <Text style={[styles.title, styles.text, styles.marginTop]}>Editing connector profile : <Text style={{ fontWeight: 'bold' }}>{this.state.savedName}</Text></Text>
                <ScrollView style={[styles.container, styles.formWrapper]}>
                    {this.getFields()}
                </ScrollView>

                <View style={[styles.toolbar]}>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.cancel.bind(this)}>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.delete.bind(this)}>Delete</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.save.bind(this)}>Save</Text>
                    </TouchableOpacity>
                </View>
            </KeyboardAvoidingView>
        )
    }
}

PublishingContainerEditor.contextTypes = {
    webSocketClient: PropTypes.object
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        margin: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
        alignSelf: 'center'
    },
    inlineText: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(14),
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    marginTop: {
        marginTop: utils.moderateScale(20),
    },
    service: {
        backgroundColor: '#333333',
        padding: utils.moderateScale(10),
        margin: utils.moderateScale(10),
        borderRadius: utils.moderateScale(30),
    }
})