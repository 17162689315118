import React from 'react'
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native'

import IconedButton from '../../IconedButton'

import * as utils from '../../../utils'

const checkIcon = require('../../../design/icons/check.png')
const checkActiveIcon = require('../../../design/icons/check_active.png')

export default class VideoListItem extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            isChecked: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.title !== this.props.title)
            return true

        if (nextProps.checkable !== this.props.checkable)
            return true

        if (nextState.isChecked !== this.state.isChecked)
            return true

        return false
    }

    onVideoClicked() {
        if (this.props.onVideoClicked) {
            this.props.onVideoClicked(this.props.id, !this.state.isChecked, this)
        }
    }

    render() {

        let duration = 0

        const { record_stopped_at, record_started_at } = this.props
        if (record_stopped_at && record_started_at) {
            let milli = record_stopped_at - record_started_at
            duration = utils.getVideoDuration(milli)
        }

        let recorded_at = undefined
        let date_offset = undefined
        if (record_started_at) {
            recorded_at = utils.getVideoRecordDate(record_started_at)
            date_offset = utils.getDateOffset(new Date(record_started_at))
        }

        const title = utils.getVideoName(this.props, true)

        const checkableWidth = (this.props.checkable) ? utils.moderateScale(15) : 0

        return (

            <TouchableOpacity
                style={{ flexDirection: 'row', alignContent: 'center' }}
                onPress={this.onVideoClicked.bind(this)}
            >
                <View style={styles.item}>
                    <IconedButton
                        image={checkIcon}
                        activeImage={checkActiveIcon}
                        isActive={this.state.isChecked}
                        style={{
                            marginRight: checkableWidth - 5,
                            aspectRatio: 1,
                            padding: 0
                        }}
                        maxIconSize={checkableWidth}
                        isDisabled={true}
                    />
                    <Text style={[styles.id, styles.left]}>{this.props.id}</Text>
                    <View style={[styles.col, styles.center]}>
                        <Text style={styles.bigWhite}>{title}</Text>
                        <Text style={styles.date}>{date_offset} - {recorded_at}</Text>
                    </View>
                    <Text style={[styles.duration, styles.right]}>{duration}</Text>
                </View>
            </TouchableOpacity>
        )
    }
}

const styles = StyleSheet.create({
    item: {
        padding: utils.moderateScale(10),
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        borderColor: '#222222',
        borderWidth: 0.5,
        flex: 1
    },
    col: {
        flexDirection: 'column',
    },
    id: {
        color: '#555555',
        fontSize: utils.moderateScale(10),
    },
    duration: {
        color: '#888888',
        fontSize: utils.moderateScale(10),
        textAlign: 'right'
    },
    bigWhite: {
        fontSize: utils.moderateScale(12),
        color: '#FFFFFF',
    },
    date: {
        color: '#555555',
        fontSize: utils.moderateScale(11),
    },
    checkbox: {
        paddingRight: 0,
        aspectRatio: 1
    },
    left: {
        flex: 1.5,
    },
    center: {
        flex: 10,
    },
    right: {
        flex: 5,
    },
})