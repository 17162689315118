import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

import JoystickContainer from '../../containers/optionsPanel/camera/JoystickContainer'
import ChromaSettingsContainer from '../../containers/optionsPanel/studio/ChromaSettingsContainer'

import * as utils from '../../utils'

import IconedButton from '../../components/IconedButton'

import * as globalActions from '../../actions/globals'
import * as globalConstans from '../../constants/globals'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const PropTypes = require('prop-types')

// Full Scene
var fullSceneIcon = require('../../design/icons/scene_full.png')
var fullSceneActiveIcon = require('../../design/icons/scene_full_active.png')

// Speaker Scene
var speakerSceneIcon = require('../../design/icons/scene_speaker.png')
var speakerSceneActiveIcon = require('../../design/icons/scene_speaker_active.png')

// Screen Scene
var screenSceneIcon = require('../../design/icons/scene_screen.png')
var screenSceneActiveIcon = require('../../design/icons/scene_screen_active.png')

// Speaker/Scene
var speakerScreenSceneIcon = require('../../design/icons/scene_speaker_screen.png')
var speakerScreenSceneActiveIcon = require('../../design/icons/scene_speaker_screen_active.png')

// Speaker above scene
var speakerAboveScreenIcon = require('../../design/icons/scene_speaker_above_screen.png')
var speakerAboveScreenActiveIcon = require('../../design/icons/scene_speaker_above_screen_active.png')

// Speaker left to screen
var speakerLeftToScreenIcon = require('../../design/icons/scene_speaker_left_to_screen.png')
var speakerLeftToScreenActiveIcon = require('../../design/icons/scene_speaker_left_to_screen_active.png')

// Speaker right to screen
var speakerRightToScreenIcon = require('../../design/icons/scene_speaker_right_to_screen.png')
var speakerRightToScreenActiveIcon = require('../../design/icons/scene_speaker_right_to_screen_active.png')

// Close View
var closeViewIcon = require('../../design/icons/view_close.png')
var closeViewActiveIcon = require('../../design/icons/view_close_active.png')

// Medium View
var mediumViewIcon = require('../../design/icons/view_medium.png')
var mediumViewActiveIcon = require('../../design/icons/view_medium_active.png')

// Full View
var fullViewIcon = require('../../design/icons/view_full.png')
var fullViewActiveIcon = require('../../design/icons/view_full_active.png')

// Slide Menu Opener/Closer
var slideMenuOpenerIcon = require('../../design/icons/open_menu_arrow.png')
var slideMenuCloserIcon = require('../../design/icons/open_menu_arrow_reversed.png')

class MainScreenBottomMenu extends React.Component {

    constructor(props, context) {
        super(props, context)

        // Scenes and Views constants
        this.views = this.context.webSocketClient.camera.views
        this.scenes = this.context.webSocketClient.mixer.scenes
    }

    getCloseExpandedButton(style = {}) {

        const { colorPickerActive } = this.props.globalState

        return (
            <View
                style={{
                    borderWidth: 0,
                    borderColor: 'green',
                    alignSelf: 'flex-end',
                    alignContent: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'row'
                }}
                className="closing-expanding-button"
            >
                <IconedButton
                    style={style}
                    image={slideMenuCloserIcon}
                    onPress={() => { this.props.setSlideMenuOpened(false) }}
                    isActive={colorPickerActive}
                    maxIconSize={this.props.maxIconSize}
                    isDisabled={colorPickerActive}
                />
            </View>
        )
    }

    getConfigurator() {
        const { mainScreenVideoMode } = this.props.globalState

        if (mainScreenVideoMode === 'studio') {
            return (
                <View style={styles.tools}>
                    <JoystickContainer
                        style={styles.joystick}
                        opacity={1}
                    />
                    <ChromaSettingsContainer
                        setOpacity={() => { }}
                        style={styles.chromaConfigurator}
                        opacity={1}
                        setVisibleMenu={this.props.setVisibleMenu}
                    />
                    {this.getCloseExpandedButton()}
                </View>
            )
        } else {
            return (
                <View style={styles.tools_no_studio}>
                    <JoystickContainer
                        style={styles.joystick_no_studio}
                        opacity={1}
                    />
                </View>
            )
        }
    }

    getSwitcherButton(constant, image, activeImage) {
        return { constant, image, activeImage }
    }

    getViewSwitchers() {
        const { mainScreenVideoMode } = this.props.globalState
        return (mainScreenVideoMode === 'default') ? [
            this.getSwitcherButton(this.views.SPEAKER, closeViewIcon, closeViewActiveIcon),
            this.getSwitcherButton(this.views.MEDIUM, mediumViewIcon, mediumViewActiveIcon),
            this.getSwitcherButton(this.views.FULL, fullViewIcon, fullViewActiveIcon)
        ] : []
    }

    getSceneSwitchers() {
        const { mainScreenVideoMode } = this.props.globalState
        if (mainScreenVideoMode === 'default') {
            return [
                this.getSwitcherButton(this.scenes.FULL, fullSceneIcon, fullSceneActiveIcon),
                this.getSwitcherButton(this.scenes.SPEAKER, speakerSceneIcon, speakerSceneActiveIcon),
                this.getSwitcherButton(this.scenes.SCREEN, screenSceneIcon, screenSceneActiveIcon),
                this.getSwitcherButton(this.scenes.SPEAKER_SCREEN, speakerScreenSceneIcon, speakerScreenSceneActiveIcon),
            ]
        } else if (mainScreenVideoMode === 'studio') {
            return [
                this.getSwitcherButton(this.scenes.SPEAKER_ABOVE_SCREEN, speakerAboveScreenIcon, speakerAboveScreenActiveIcon),
                this.getSwitcherButton(this.scenes.STUDIO_SCREEN, screenSceneIcon, screenSceneActiveIcon),
                this.getSwitcherButton(this.scenes.SPEAKER_LEFT_TO_SCREEN, speakerLeftToScreenIcon, speakerLeftToScreenActiveIcon),
                this.getSwitcherButton(this.scenes.SPEAKER_RIGHT_TO_SCEEN, speakerRightToScreenIcon, speakerRightToScreenActiveIcon),
            ]
        }
    }

    render() {
        let opacity = 1
        if (!this.props.isVisible) { opacity = 0 }

        let height = this.props.height
        const { mainScreenVideoMode } = this.props.globalState

        if (this.props.slideMenuOpened) {
            height = utils.getButtonGridUnit() * 4 + 20
        }

        return (
            <View
                style={{ opacity, height }}
                onLayout={this.props.onLayoutUpdate}
            >
                <View
                    style={{
                        paddingHorizontal: this.props.menuHorizontalPadding,
                        position: 'absolute',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        zIndex: 10,
                        backgroundColor: '#262626',
                        left: this.props.slideMenuPosLeft || 0, bottom: 0, top: 0, right: -this.props.slideMenuPosLeft || 0
                    }}
                    className="main-screen-bottom-menu-slide-menu"
                >
                    {this.getConfigurator()}

                    {this.getViewSwitchers().map((viewSwitch) => {
                        return (
                            <IconedButton
                                key={viewSwitch.constant}
                                image={viewSwitch.image}
                                activeImage={viewSwitch.activeImage}
                                onPress={() => { this.props.switchView(viewSwitch.constant) }}
                                isActive={this.props.activatedView === viewSwitch.constant}
                                maxIconSize={this.props.maxFloatingIconSize}
                            />
                        )
                    })}

                    {(mainScreenVideoMode === 'default') ?
                        this.getCloseExpandedButton()
                        : null}


                </View>
                <View
                    style={{
                        paddingHorizontal: this.props.menuHorizontalPadding,
                        position: 'absolute',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#262626',
                        left: 0, right: 0, bottom: 0, top: 0
                    }}>
                    <IconedButton
                        image={slideMenuOpenerIcon}
                        onPress={() => { this.props.setSlideMenuOpened(true) }}
                        isActive={false}
                        maxIconSize={this.props.maxIconSize}
                    />
                    {this.getSceneSwitchers().map((sceneSwitch) => {
                        return (
                            <IconedButton
                                key={sceneSwitch.constant}
                                image={sceneSwitch.image}
                                activeImage={sceneSwitch.activeImage}
                                onPress={() => { this.props.switchScene(sceneSwitch.constant) }}
                                isActive={this.props.activatedScene === sceneSwitch.constant}
                                maxIconSize={this.props.maxFloatingIconSize}
                            />
                        )
                    })}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    joystick: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        zIndex: 20
    },
    joystick_no_studio: {
        zIndex: 20
    },
    chromaConfigurator: {
        position: 'absolute',
        bottom: 10,
        right: utils.getButtonGridUnit() + 15,
        zIndex: 20,
    },
    tools: {
        flex: 1
    },
    tools_no_studio: {

    }
})

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreenBottomMenu)

MainScreenBottomMenu.contextTypes = {
    webSocketClient: PropTypes.object
}