import { Overlay as NativeOverlay, Scene as NativeScene, Stack as NativeStack } from 'react-native-router-flux';
import { Overlay as WebOverlay } from './WebComponents';

// Components
import MainScreenContainer from '../containers/MainScreenContainer'

// Video
import VideoContainer from '../containers/optionsPanel/video/videoContainer'
import VideoDetailContainer from '../containers/optionsPanel/video/videoDetailContainer'
import VideoExporterPanelUsb from '../components/VideoExporterPanelUsb'
import VideoExporterPanelOnline from '../components/VideoExporterPanelOnline'

// Options
import OptionsPanelContainer from '../containers/OptionsPanelContainer'
import OptionsCameraContainer from '../containers/optionsPanel/OptionsCameraContainer'
import OptionsAudioContainer from '../containers/optionsPanel/OptionsAudioContainer'
import OptionsAudioMixerContainer from '../containers/optionsPanel/OptionsAudioMixerContainer'
import OptionsNetworkContainer from '../containers/optionsPanel/OptionsNetworkContainer'
import OptionsOutputMode from '../containers/optionsPanel/OptionsOutputMode'
import OptionsLayoutMode from '../containers/optionsPanel/OptionsLayoutMode'
import OptionsStudioMode from '../containers/optionsPanel/OptionsStudioContainer'

import OptionsPublishingContainer from '../containers/optionsPanel/OptionsPublishingContainer'
import PublishingContainerEditor from '../containers/optionsPanel/publisher/PublishingContainerEditor'
import WebViewContainer from '../containers/optionsPanel/WebViewContainer'

import OptionsAboutContainer from '../containers/optionsPanel/OptionsAboutContainer'
import OptionsLogoContainer from '../containers/optionsPanel/OptionsLogoContainer'
import OptionsBackgroundContainer from '../containers/optionsPanel/OptionsBackgroundContainer'
import OptionsWatermarkContainer from '../containers/optionsPanel/OptionsWatermarkContainer'
import OptionsResolutionContainer from '../containers/optionsPanel/OptionsResolutionContainer'

// Overlays
import UpdaterContainer from "../containers/UpdaterContainer"

export default {
    key: "updateOverlay",
    nativeType: NativeOverlay,
    webType: WebOverlay,
    children: [
        {
            key: "root",
            nativeType: NativeScene,
            nativeProps: { hideNavBar: true },
            webType: null,
            children: [
                {
                    key: "Home",
                    component: MainScreenContainer,
                    nativeType: NativeScene,
                    nativeProps: { hideNavBar: true },
                    path: '/',
                    exact: true
                }, {
                    nativeKey: "videoOverlay",
                    webKey: "nativeVideoOverlay",
                    nativeType: NativeOverlay,
                    webType: WebOverlay,
                    children: [
                        {
                            key: "videoContainer",
                            nativeType: NativeScene,
                            nativeProps: {
                                back: true,
                                initial: true
                            },
                            webType: null,
                            children: [
                                {
                                    nativeKey: "videoInitial",
                                    webKey: "videoOverlay",
                                    title: "Videos",
                                    component: VideoContainer,
                                    nativeType: NativeScene,
                                    nativeProps: {
                                        backTitle: "Back",
                                        initial: true
                                    },
                                    path: '/videos',
                                    exact: true
                                },
                                {
                                    key: "VideoDetailContainer",
                                    title: "Video Preview",
                                    component: VideoDetailContainer,
                                    nativeType: NativeScene,
                                    nativeProps: { backTitle: "Video List" },
                                    path: '/videos/:videoId',
                                    exact: true
                                }
                            ]
                        }, {
                            key: "VideoExporterPanelOnline",
                            nativeType: NativeScene,
                            component: VideoExporterPanelOnline,
                            path: '/videos'
                        }, {
                            key: "VideoExporterPanelUsb",
                            nativeType: NativeScene,
                            component: VideoExporterPanelUsb,
                            path: '/videos'
                        }
                    ]
                }, {
                    nativeKey: "OptionsPanelContainer",
                    webKey: "NativeOptionsPanelContainer",
                    nativeType: NativeScene,
                    nativeProps: { back: true },
                    webType: null,
                    children: [
                        {
                            nativeKey: "OptionsInitial",
                            webKey: "OptionsPanelContainer",
                            title: "Options",
                            nativeType: NativeScene,
                            component: OptionsPanelContainer,
                            nativeProps: {
                                backTitle: "Back" ,
                                initial: true
                            },
                            path: '/options',
                            exact: true
                        },
                        {
                            key: "OptionsCameraContainer",
                            title: "Camera",
                            nativeType: NativeScene,
                            component: OptionsCameraContainer,
                            nativeProps: { backTitle: "Options"},
                            path: '/options/camera',
                            exact: true
                        },
                        {
                            key: "OptionsAudioContainer",
                            title: "Audio",
                            nativeType: NativeScene,
                            component: OptionsAudioContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/audio',
                            exact: true
                        },
                        {
                            key: "OptionsAudioMixerContainer",
                            title: "Audio",
                            nativeType: NativeScene,
                            component: OptionsAudioMixerContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/audiomixer',
                            exact: true
                        },
                        {
                            key: "OptionsNetworkContainer",
                            title: "Network",
                            nativeType: NativeScene,
                            component: OptionsNetworkContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/network',
                            exact: true
                        },
                        {
                            key: "OptionsPublishingContainer",
                            title: "Publishing profiles",
                            nativeType: NativeScene,
                            component: OptionsPublishingContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/publishing',
                            exact: true,
                        },
                        {
                            key: "WebViewContainer",
                            title: "WebView connection",
                            nativeType: NativeScene,
                            component: WebViewContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/publishing/webview',
                            exact: true
                        },
                        {
                            key: "PublishingContainerEditor",
                            title: "Publishing - Profile Edition",
                            nativeType: NativeScene,
                            component: PublishingContainerEditor,
                            nativeProps: { backTitle: "Publishing Profiles" },
                            path: '/options/publishing/:profileId',
                            exact: true
                        },
                        {
                            key: "OptionsAboutContainer",
                            title: "My Kast Box",
                            nativeType: NativeScene,
                            component: OptionsAboutContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/about',
                            exact: true
                        },
                        {
                            key: "OptionsLogoContainer",
                            title: "Logo",
                            nativeType: NativeScene,
                            component: OptionsLogoContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/logo',
                            exact: true
                        },
                        {
                            key: "OptionsBackgroundContainer",
                            title: "Background",
                            nativeType: NativeScene,
                            component: OptionsBackgroundContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/background',
                            exact: true
                        },
                        {
                            key: "OptionsWatermarkContainer",
                            title: "Watermark",
                            nativeType: NativeScene,
                            component: OptionsWatermarkContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/watermark',
                            exact: true
                        },
                        {
                            key: "OptionsOutputMode",
                            title: "Output Mode",
                            nativeType: NativeScene,
                            component: OptionsOutputMode,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/outputmode',
                            exact: true
                        },
                        {
                            key: "OptionsResolutionContainer",
                            title: "Resolution",
                            nativeType: NativeScene,
                            component: OptionsResolutionContainer,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/resolution',
                            exact: true
                        },
                        {
                            key: "OptionsLayoutMode",
                            title: "Layout Mode",
                            nativeType: NativeScene,
                            component: OptionsLayoutMode,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/layoutmode',
                            exact: true
                        },
                        {
                            key: "OptionsStudioMode",
                            title: "Studio Capture Mode",
                            nativeType: NativeScene,
                            component: OptionsStudioMode,
                            nativeProps: { backTitle: "Options" },
                            path: '/options/studiomode',
                            exact: true
                        }
                    ]
                }
            ]
        }, {
            key: "UpdaterContainer",
            nativeType: NativeScene,
            component: UpdaterContainer,
            path: '/'
        }
    ]
}
