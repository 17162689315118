import * as types from '../constants/audio'

const initialState = {
    audioLevel: -70
}

export default function cameraReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.AUDIO_LEVEL_UPDATED:
            return { ...state, audioLevel: action.audioLevel }

        default:
            return state || initialState;
    }
}