import React from 'react'
import { View, Text, TouchableWithoutFeedback, StyleSheet } from 'react-native'

import * as globalsActions from '../actions/globals'
import * as audioActions from '../actions/audio'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const PropTypes = require('prop-types')
const _ = require('lodash')

class WebPlayerContainer extends React.Component {
    jsmpegCanvas = undefined;
    player = undefined;
    sound = undefined;
    overlay = undefined;

    constructor(props, context) {
        super(props, context)
    }

    shouldComponentUpdate(nextProps) {
        return ((!this.player && !this.sound) || this.props.showOverlay !== nextProps.showOverlay || this.props.isPickingColor !== nextProps.isPickingColor)
    }

    componentDidUpdate() {
        const { isV3 } = this.props.capabilitiesState;

        if (isV3 === false && this.jsmpegCanvas && !this.player && !this.sound) {
            const { boxIP } = this.context;

            const videoWS = new WebSocket('ws://' + boxIP + ':8084/');
            const audioWs = new WebSocket('ws://' + boxIP + ':8094/');

            this.player = new jsmpeg(videoWS, { canvas: this.jsmpegCanvas, forceCanvas2D: true });
            this.sound = new jsaudio(audioWs, { bufferSize: 4096 });

            this.sound.onupdaterms((rms) => {
                if (isNaN(rms))
                    return;

                const currentLevel = (20 * Math.log10(rms)) + 15;
                this.props.audioActions.audioLevelUpdated(currentLevel)
            });

            this.sound.play();
        }
    }

    renderJsMpegPlayer() {
        return (
            <View className="jsmpeg-canvas-wrapper">
                <canvas className="jsmpeg-canvas"
                    ref={jsmpegCanvas => this.jsmpegCanvas = jsmpegCanvas}
                />
            </View>
        )
    }

    renderNewPlayer() {
        return (
            <View>
                <Text style={{ color: 'white', textAlign: 'center' }}>Unavailable player for V3 Kast.</Text>
            </View>
        )
    }

    renderUnknownPlayer() {
        return (
            <View>
                <Text style={{ color: 'white', textAlign: 'center' }}>No information fetched about camera version.</Text>
            </View>
        )
    }

    onColorPicked(event) {
        const { pageX, pageY } = event.nativeEvent;
        const body = document.body;

        if (this.props.onDragEnter)
            this.props.onDragEnter(pageX, pageY, body.clientWidth, body.clientHeight);
    }

    showOverlay() {
        if (this.props.showOverlay && this.props.isPickingColor) {
            return (

                <TouchableWithoutFeedback
                    onPress={(event) => this.onColorPicked(event)}
                    ref={(overlay) => this.overlay = overlay}
                >
                    <View style={StyleSheet.flatten([{
                        position: 'absolute',
                        top: 0, bottom: 0, left: 0, right: 0,
                        // backgroundColor: '#02FF2422',
                        borderWidth: (this.props.showBorders) ? 2 : 0,
                        borderColor: '#02FF24',
                        zIndex: 100000000
                    }, this.props.style])}
                    />
                </TouchableWithoutFeedback>
            )
        }
    }

    render() {
        const { isV3 } = this.props.capabilitiesState;
        let renderPlayer = undefined;

        if (isV3 !== null)
            renderPlayer = this.renderNewPlayer.bind(this);

        if (isV3 === false)
            renderPlayer = this.renderJsMpegPlayer.bind(this)

        return (
            <View
                style={{
                    borderWidth: 0,
                    borderColor: 'red',
                    alignContent: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#000000',
                    flex: 1
                }}
            >
                {(renderPlayer) ? renderPlayer() : this.renderUnknownPlayer()}
                {this.showOverlay()}
            </View>
        )
    }
}

WebPlayerContainer.contextTypes = {
    boxIP: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        globalsState: state.globalsReducer,
        audioState: state.audioReducer,
        systemState: state.systemReducer,
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalsActions: bindActionCreators(globalsActions, dispatch),
        audioActions: bindActionCreators(audioActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebPlayerContainer)