import React from "react";
import { View, Text } from "react-native";

export class Overlay extends React.Component {
    render()
    {
        return (
            <View className="web-overlay">
                {this.props.children}
            </View>
        )
    }
}
