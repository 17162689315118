import React from 'react'
import { View, Text, StyleSheet, LayoutAnimation } from 'react-native'
import * as Progress from 'react-native-progress';
import Messenger from '../components/Messenger'
import Button from 'react-native-button'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as systemActions from '../actions/system'

const PropTypes = require('prop-types')

import * as utils from '../utils'

class UpdaterContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            text: "",
            isUpdating: false,
            needsReboot: false,
            isRestarting: false
        }
    }

    componentDidMount() {

        this.context.webSocketClient.update.onDownloadStart(
            this.onFetchStart, this
        )

        this.context.webSocketClient.update.onDownloadProgress(
            this.onFetchProgress, this
        )

        this.context.webSocketClient.update.onDownloadEnd(
            this.onFetchEnd, this
        )

        this.context.webSocketClient.update.onDownloadError(
            this.onFetchError, this
        )

        this.context.webSocketClient.update.onUpdateCopyStart(
            this.onFetchStart, this
        )

        this.context.webSocketClient.update.onUpdateCopyProgress(
            this.onFetchProgress, this
        )

        this.context.webSocketClient.update.onUpdateCopyEnd(
            this.onFetchEnd, this
        )

        this.context.webSocketClient.update.onUpdateCopyError(
            this.onFetchError, this
        )

        this.context.webSocketClient.update.onUpdateStart(
            this.onUpdateStart, this
        )

        this.context.webSocketClient.update.onUpdateProgress(
            this.onUpdateProgress, this
        )

        this.context.webSocketClient.update.onUpdateEnd(
            this.onUpdateEnd, this
        )

        this.context.webSocketClient.update.onUpdateError(
            this.onUpdateError, this
        )

        this.context.webSocketClient.context.onUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.update.onAvailable(
            this.onUpdateAvailable, this
        )

        this.context.webSocketClient.connection.onClose(
            this.onClose, this
        )

        this.context.webSocketClient.connection.onOpen(
            this.onOpen, this
        )

        if (this.props.systemState.canStartUpdate)
            this.context.webSocketClient.update.check()
    }

    componentWillUnmount() {
        this.context.webSocketClient.update.offDownloadStart(
            this.onFetchStart
        )

        this.context.webSocketClient.update.offDownloadProgress(
            this.onFetchProgress
        )

        this.context.webSocketClient.update.offDownloadEnd(
            this.onFetchEnd
        )

        this.context.webSocketClient.update.offDownloadError(
            this.onFetchError
        )

        this.context.webSocketClient.update.offUpdateCopyStart(
            this.onFetchStart
        )

        this.context.webSocketClient.update.offUpdateCopyProgress(
            this.onFetchProgress
        )

        this.context.webSocketClient.update.offUpdateCopyEnd(
            this.onFetchEnd
        )

        this.context.webSocketClient.update.offUpdateCopyError(
            this.onFetchError
        )

        this.context.webSocketClient.update.offUpdateStart(
            this.onUpdateStart
        )

        this.context.webSocketClient.update.offUpdateProgress(
            this.onUpdateProgress
        )

        this.context.webSocketClient.update.offUpdateEnd(
            this.onUpdateEnd
        )

        this.context.webSocketClient.update.offUpdateError(
            this.onUpdateError
        )

        this.context.webSocketClient.context.offUpdated(
            this.onContextUpdated
        )

        this.context.webSocketClient.update.offAvailable(
            this.onUpdateAvailable
        )

        this.context.webSocketClient.connection.onClose(
            this.onClose
        )

        this.context.webSocketClient.connection.onOpen(
            this.onOpen
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.needsReboot) {
            this.updateComponent("Update successful.\nRebooting your KastBox...")
            if (!this.state.isUpdating)
                this.setUpdating(true)
        } else if (prevState.needsReboot && !this.state.needsReboot) {
            this.setUpdating(false)
        }
    }

    onFetchStart() {
        this.updateComponent("Fetching update...\n")
    }

    onFetchProgress(message) {
        const { size_transferred, total_size } = message
        let { progression } = message
        
        if (progression == null || progression == undefined)
            progression = size_transferred / total_size * 100

        progression = progression.toFixed(0)
        this.updateComponent("Fetching update...\n" + progression + "%")
    }

    onFetchEnd() {
        this.updateComponent("Fetching update... Done\n")

        if (!this.props.capabilitiesState.isAbUpdate)
            this.setState({ needsReboot: true, isRestarting: true, isUpdating: true })
    }

    onFetchError(error) {
        Messenger.error("An error occurred while fetching update. Please, try again later.\nDetails : " + error.message)
        this.updateComponent("")
    }

    onUpdateStart() {
        this.updateComponent("Updating...\n")
    }

    onUpdateProgress(message) {
        const { size_transferred, total_size } = message
        const progression = (size_transferred / total_size * 100).toFixed(0)

        this.updateComponent("Updating...\n" + progression + "%")
    }

    onUpdateEnd() {
        this.setState({ needsReboot: true })
    }

    onUpdateError(error) {
        Messenger.error("An error occurred while flashing. Please, try again.\nDetails : " + error.error)
        console.log("UPDATE ERRORATION", error)
        this.updateComponent("")
    }

    onContextUpdated(context) {
        let needsReboot = undefined
        try {
            needsReboot = context.system.needs_reboot
        } catch (error) { }

        if (needsReboot !== undefined) {
            if (this.state.needsReboot !== needsReboot) {
                this.setState({ needsReboot })
            }
        }
    }

    onUpdateAvailable() {
        if (this.props.systemState.canStartUpdate) {
            utils.onUpdateAvailable(this.context)
        }
    }

    onClose() {
        if (this.props.capabilitiesState.isAbUpdate) {
            if (this.state && this.state.isRestarting) {
                this.setUpdating(false)
                this.setState({
                    needsReboot: false,
                    isRestarting: false
                })
            }
        }
    }

    onOpen() {
        if (!this.props.capabilitiesState.isAbUpdate) {
            this.setUpdating(false)
            this.setState({
                needsReboot: false,
                isRestarting: false
            })
        }
    }

    updateComponent(newText) {
        this.setUpdating(newText !== "")
        if (this.state.text !== newText) {
            this.setState({ text: newText })
        }
    }

    setUpdating(isUpdating) {
        if (this.state.isUpdating !== isUpdating) {

            this.props.systemActions.setCanStartUpdate(!isUpdating)

            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
            this.setState({ isUpdating })
        }
    }

    showRestartButton() {
        if (this.state.needsReboot) {
            return (
                <View style={styles.restartButtonContainer}>
                    <Button
                        containerStyle={styles.buttonContainer}
                        onPress={this.reboot.bind(this)}
                        style={styles.buttonText}
                    >Restart Now</Button>
                </View>
            )
        }
    }

    reboot() {
        this.context.webSocketClient.system.reboot()
        this.setState({ isRestarting: true })
    }

    render() {
        let invisibleStyle = {}
        let barStyle = {}
        let text = this.state.text

        if (!this.state.isUpdating) {
            invisibleStyle = {
                height: 0,
                padding: 0,
                top: 1.5
            }

            text = "\n"
        }

        const isWebVisible = this.state.isUpdating ? "updating" : ''

        return (
            <View style={[styles.container, invisibleStyle]} className={"updater-container " + isWebVisible }>
                <View style={[styles.colorBar]}></View>
                <View style={[styles.loaderContainer]}>
                    <View>
                        <Text style={styles.text}>{text}</Text>
                    </View>
                    {/*this.showRestartButton()*/}
                </View>
            </View>
        )
    }
}

UpdaterContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#262626',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    colorBar: {
        height: 1.5,
        width: "100%",
        backgroundColor: '#00FF24'
    },
    loaderContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    text: {
        color: '#FFFFFF',
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontSize: utils.moderateScale(13),
        padding: utils.moderateScale(7.5),
    },
    buttonContainer: {
        padding: 10,
        backgroundColor: '#555555',
        marginHorizontal: utils.moderateScale(7.5)
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(13),
    },
    restartButtonContainer: {
        justifyContent: 'center',
    }
})

function mapStateToProps(state) {
    return {
        systemState: state.systemReducer,
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        systemActions: bindActionCreators(systemActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdaterContainer)