import React from 'react'
import { View, TouchableOpacity, Text, Image, StyleSheet, Dimensions } from 'react-native'

import Grid from 'react-native-photo-grid';

import UsbImporter from '../../components/UsbImporter'
import Messenger from '../../components/Messenger'
import * as utils from '../../utils'

const PropTypes = require('prop-types')

const defaultLogo = ''

export default class OptionsLogoContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            logos: [],
            currentLogo: undefined,
            logosPerRow: 2,
            importingLogoVisible: false
        }
    }

    componentDidMount() {
        this.context.webSocketClient.logo.onList(
            this.onList, this
        )

        this.context.webSocketClient.logo.onGetCurrent(
            this.onGetCurrent, this
        )

        this.refreshList()
    }

    componentWillUnmount() {
        this.context.webSocketClient.logo.offList(
            this.onList
        )

        this.context.webSocketClient.logo.offGetCurrent(
            this.onGetCurrent
        )
    }

    onList(list) {
        this.setState({
            logos: list.logos.filter((logo) => {
                return logo !== defaultLogo
            })
        }, () => {
            this.context.webSocketClient.logo.getCurrent()
        })
    }

    onGetCurrent(currentLogo) {
        this.setState({ currentLogo: currentLogo.logo })
    }

    refreshList() {
        this.context.webSocketClient.logo.get()
    }

    import() {
        this.setState({ importingLogoVisible: true })
    }

    closeImportLogo() {
        this.setState({ importingLogoVisible: false })
    }

    importFile(filePath, device) {
        this.context.webSocketClient.logo.usbCopy({
            device_id: device,
            path: filePath
        })
    }

    delete(logo) {
        Messenger.prompt(
            'Remove logo',
            'Are you sure you want to remove the current logo ' + logo + ' ?',
            [
                { text: 'Cancel', onPress: () => { }, style: 'cancel' },
                { text: 'Remove', onPress: () => { this.removeLogo(logo) } },
            ],
            { cancelable: false }
        )
    }

    removeLogo(logo) {
        this.context.webSocketClient.logo.remove({
            logo: logo
        })
        this.refreshList()
    }

    imageTouched(logo) {
        this.setState({
            currentLogo: logo
        })
        this.context.webSocketClient.logo.set({
            logo: logo
        })
    }

    renderItem(logo, logoSize) {
        return (
            <View key={logo}>
                <TouchableOpacity
                    style={{ width: logoSize, height: logoSize }}
                    onPress={() => this.imageTouched(logo)}
                >
                    <Image
                        resizeMode="cover"
                        style={[
                            styles.logo,
                            (this.state.currentLogo === logo) ? styles.activeLogo : {}
                        ]}
                        source={{ uri: 'http://' + this.context.boxIP + '/logos/' + logo }}
                    />
                </TouchableOpacity>
                <View style={[
                    styles.toolbar,
                    {}
                ]}>
                    <TouchableOpacity onPress={() => this.delete(logo)} disabled={(this.state.currentLogo === logo)} style={styles.button}>
                        <Text style={[
                            styles.buttonText,
                            (this.state.currentLogo === logo) ? styles.disabledButtonText : {}
                        ]}>Delete</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    onLayout() {
        const dim = Dimensions.get('screen')
        this.setState({ logosPerRow: (dim.height >= dim.width) ? 2 : 3 })
    }

    filterEntry(entry) {
        return (entry.is_dir) ? true : entry.name.endsWith('.png')
    }

    render() {
        const { logos } = this.state

        return (
            <View style={styles.container} onLayout={this.onLayout.bind(this)} className="options-logo-container">
                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.import.bind(this)}>Import</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flex: 1 }} className="options-logo-container-grid">
                    <Grid
                        data={logos}
                        itemsPerRow={this.state.logosPerRow}
                        itemMargin={utils.moderateScale(30)}
                        renderItem={this.renderItem.bind(this)}
                    />
                </View>
                <UsbImporter
                    isVisible={this.state.importingLogoVisible}
                    onCancel={this.closeImportLogo.bind(this)}
                    onFileSelected={this.importFile.bind(this)}
                    filterEntry={this.filterEntry.bind(this)}
                />
            </View>
        )
    }
}

OptionsLogoContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    boxIP: PropTypes.string
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    containerScroll: {
        flex: 1,
        padding: utils.moderateScale(10)
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        alignContent: 'center',
        padding: utils.moderateScale(10)
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    disabledButtonText: {
        color: '#333333',
    },
    logo: {
        flex: 1,
    },
    activeLogo: {
        borderWidth: 2,
        borderColor: '#02FF24'
    }
})