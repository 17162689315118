import React from 'react'
import { Platform } from 'react-native'

import ModalFilterPicker from 'react-native-modal-filter-picker'
import ModalFilterPickerWeb from '../containers/ModalFilterPickerWeb'

export default class UsbExporter extends React.Component {

    constructor(props, context) {
        super(props, context)
    }

    render() {

        const ModalFilterPickerComponent = Platform.OS == "web" ? ModalFilterPickerWeb : ModalFilterPicker

        return (
            <ModalFilterPickerComponent
                visible={this.props.isVisible}
                onSelect={this.props.onSelect}
                onCancel={this.props.onCancel}
                options={this.props.options}
                modal={{
                    onRequestClose: () => { },
                    transparent: true,
                    hardwareAccelerated: true,
                    animationType: 'fade'
                }}
                cancelButtonText='Cancel'
                showFilter={false}
                title={this.props.title}
                noResultsText={this.props.noResultsText}
                style={{
                    zIndex:99999
                }}
            />
        )
    }
}