import React from "react"
import { View, Text, TouchableOpacity, ScrollView, Image, LayoutAnimation, StyleSheet } from "react-native"

import { TextField } from 'react-native-material-textfield'
import Grid from 'react-native-photo-grid'

import Messenger from '../../../components/Messenger'

import * as utils from '../../../utils'

const PropTypes = require('prop-types')

export default class PublishingContainerCreator extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            pickedConnectorType: null,
            connectorName: ''
        }
    }

    onConnectorTypeSelect(pickedConnectorType) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        this.setState({ pickedConnectorType }, () => {
            this.refs.nameField.focus()
        })
    }

    onLayout() {
        this.setState({ itemsPerRow: 4 })
    }

    renderCommonSectionTitle() {
        return (
            <Text style={[styles.text, styles.title]}>Select the desired publishing service</Text>
        )
    }

    renderConnectorType(connectorType, size) {
        const activeStyle = (connectorType === this.state.pickedConnectorType) ? { backgroundColor: '#555' } : {}

        return (
            <View key={connectorType.name} style={{
                width: size,
                justifyContent: 'center',
            }}
                className="services-creator"
            >
                <TouchableOpacity style={[styles.service, activeStyle, {
                    height: size,
                    alignSelf: 'center',
                }]} onPress={() => { this.onConnectorTypeSelect(connectorType) }}>
                    <Image
                        resizeMode="cover"
                        style={[{
                            aspectRatio: 1,
                            height: size / 2,
                            marginBottom: utils.moderateScale(10)
                        }]}
                        source={utils.getPublishingIcon(connectorType.iconPath)}
                    />
                    <Text style={[styles.text, { textAlign: "center" }]}>{connectorType.verbose_name}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    renderConnectorTypes() {
        return (
            <Grid
                data={this.props.data.filter((item) => {
                    return this.context.buildIsDemo || item.is_visible
                })}
                itemsPerRow={this.state.itemsPerRow}
                itemMargin={utils.moderateScale(20)}
                renderItem={this.renderConnectorType.bind(this)}
            />
        )
    }

    renderFieldsSectionTitle() {
        if (this.state.pickedConnectorType) {
            return (
                <Text style={[styles.text, styles.title, styles.marginTop]}>Enter a name for your <Text style={{ color: "#00FF24" }}>{this.state.pickedConnectorType.verbose_name}</Text> publishing profile (e.g., Work, Personnal) </Text>
            )
        }
    }

    renderNameField() {
        if (this.state.pickedConnectorType) {
            const color = "#FFFFFF"
            return (
                <TextField
                    labelFontSize={utils.moderateScale(10)}
                    fontSize={utils.moderateScale(12)}
                    label={'Service name'}
                    value={this.state.connectorName}
                    onChangeText={(connectorName) => { this.setState({ connectorName }) }}
                    textColor={color} tintColor={color} baseColor={color}
                    ref="nameField"
                    onSubmitEditing={this.create.bind(this)}
                />
            )
        }
    }

    create() {
        const connectorName = this.state.connectorName
        if (connectorName.length > 0) {
            const service = {
                typename: this.state.pickedConnectorType.name,
                id: null,
                fields: {
                    name: {
                        value: connectorName
                    }
                }
            }
            this.context.webSocketClient.publication.save({ service })
        } else {
            Messenger.error("Your publishing profile name can't be empty")
        }
    }

    cancel() {
        this.props.onCancel()
    }

    render() {
        const createDisableStyle = !this.state.connectorName ? { color: '#FFFFFF50' } : {}

        return (
            <View style={styles.container} onLayout={this.onLayout.bind(this)} className="publishing-container-creator">
                <ScrollView>
                    <View style={[styles.container, styles.formWrapper, { flex: 1 }]}>

                        { /* Render common informations */}
                        {this.renderCommonSectionTitle()}
                        {this.renderConnectorTypes()}

                        { /* Service fields */}
                        {this.renderFieldsSectionTitle()}
                        {this.renderNameField()}
                    </View>
                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button} onPress={this.cancel.bind(this)}>
                        <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.button} disabled={!this.state.connectorName} onPress={this.create.bind(this)}>
                        <Text style={[styles.buttonText, createDisableStyle]}>Create</Text>
                    </TouchableOpacity>

                </View>
            </View>
        )
    }
}

PublishingContainerCreator.contextTypes = {
    webSocketClient: PropTypes.object,
    buildIsDemo: PropTypes.bool
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        margin: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    marginTop: {
        marginTop: utils.moderateScale(20),
    },
    service: {
        borderRadius: utils.moderateScale(5),
        justifyContent: 'center',
        alignItems: 'center',
        padding: utils.moderateScale(10),
    }
})