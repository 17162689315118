import React from 'react'
import { Actions } from "react-native-router-flux"

import Messenger from '../components/Messenger'

import { View } from 'react-native'

import MainScreen from '../components/MainScreen'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as globalActions from '../actions/globals'
import * as systemActions from '../actions/system'

const PropTypes = require('prop-types')
const _ = require('lodash')

class MainScreenContainer extends React.Component {

    mount = false

    constructor(props, context) {
        super(props, context)

        this.state = {
            visibleMenu: true,
            session: {},
            isConnected: false
        }

        this.context.webSocketClient.connection.onClose(
            this.onConnectionClosed, this
        )

        this.context.webSocketClient.connection.onOpen(
            this.onConnectionOpened, this
        )

        this.context.webSocketClient.connection.onError(
            this.onConnectionError, this
        )

        this.context.webSocketClient.system.onError(
            this.onError, this
        )

        this.context.webSocketClient.system.onNotificationError(
            this.onNotificationError, this
        )

        this.context.webSocketClient.system.onSuccess(
            this.onSuccess, this
        )

        this.context.webSocketClient.system.onNotificationSuccess(
            this.onNotificationSuccess, this
        )

        this.context.webSocketClient.usb.onCopyTaskError(
            this.onCopyTaskError, this
        )

        this.context.webSocketClient.system.onFreeSpace(
            this.onFreeSpace, this
        )

        this.context.webSocketClient.video.onCurrentSession(
            this.onCurrentSession, this
        )

        this.context.webSocketClient.video.onLiveError(
            this.onLiveError, this
        )
    }


    componentWillUnmount() {
        this.mount = false  

        this.context.webSocketClient.system.offError(
            this.onError
        )

        this.context.webSocketClient.system.offNotificationError(
            this.onNotificationError
        )

        this.context.webSocketClient.system.offSuccess(
            this.onSuccess
        )

        this.context.webSocketClient.system.offNotificationSuccess(
            this.onNotificationSuccess
        )

        this.context.webSocketClient.usb.offCopyTaskError(
            this.onCopyTaskError
        )

        this.context.webSocketClient.system.offFreeSpace(
            this.onFreeSpace
        )

        this.context.webSocketClient.video.offCurrentSession(
            this.onCurrentSession
        )

        this.context.webSocketClient.video.offLiveError(
            this.onLiveError
        )
    }

    onConnectionClosed(connectionData) {
        this.setState({ isConnected: false })
    }

    onConnectionOpened() {
        Messenger.notifySuccess('Successfully connected')
        this.props.systemActions.setWebsocketConnected(true)
        this.setState({ isConnected: true })

        this.context.webSocketClient.context.get()
        this.context.webSocketClient.video.currentSession()

        function getTime() {
            const currentDate = new Date()
            const currentTime = currentDate.getTime()
            const localOffset = currentDate.getTimezoneOffset() * 1000 * 60

            return currentTime - localOffset
        }

        // Update system time
        this.context.webSocketClient.system.setTime({
            time: getTime()
        })
    }

    onConnectionMessage(message) {
        Messenger.error(message)
        this.context.webSocketClient.connection.socket.close()
        this.props.systemActions.setWebsocketConnected(false)
        console.log("CONNECITONMESSAGE ERRORATION", message)
    }

    onConnectionError(message) {
        console.log("onConnectionErroration", message)
    }

    onError(error) {
        this.setState({ errorMessage: error })
        Messenger.error(error.message)
        console.log("SYSTEM ERRORATION", error)
    }

    onNotificationError(notificationError) {
        this.setState({ notificationErrorMessage: notificationError })
        Messenger.notify(notificationError.message)
        console.log("NOTIFICATION ERRORATION", error)
    }

    onSuccess(success) {
        this.setState({ successMessage: success })
        console.log("ONSUCCESS ERRORATION", success)
        Messenger.notify(success.message)
    }

    onNotificationSuccess(notificationSuccess) {
        this.setState({ notificationSuccessMessage: notificationSuccess })
        console.log("ONNOTIFSUCCESS ERRORATION", notificationSuccess)
        Messenger.notify(notificationSuccess.message)
    }

    onCopyTaskError(taskError) {
        //Messenger.notify(taskError)
    }

    onFreeSpace(spaceData) {
        const usedSpace = spaceData.total_space - spaceData.free_space
        const usedSpacePercentage = (usedSpace / spaceData.total_space) || 0
        const message = `The storage is ${(usedSpacePercentage * 100).toFixed(2)}% full. Please, save and remove videos.`

        if (usedSpacePercentage >= 0.95) {
            Messenger.warning(message)
        } else if (usedSpacePercentage >= 0.80) {
            Messenger.notify(message)
        }
    }

    onCurrentSession(session) {
        this.setState({ session })
    }

    onLiveError() {
        Messenger.warning('The live broadcasting has failed.\nPlease try again.')
    }

    componentDidMount() {
        this.mount = true
    }

    componentDidUpdate(oldProps, oldState) {

        // End of recording
        let isRecording = undefined
        let wasRecording = undefined

        try {
            isRecording = this.state.session.state === "LI"
            wasRecording = oldState.session.state === "LI"
        } catch (error) { }

        if (wasRecording && !isRecording) {
            this.context.webSocketClient.system.getFreeSpace()
        }
    }

    setVisibleMenu(visibleMenu) {
        if (this.state.visibleMenu !== visibleMenu)
            this.setState({ visibleMenu })
    }

    render() {
        return (
            <MainScreen
                uri={this.context.rtspFlux}
                setVisibleMenu={this.setVisibleMenu.bind(this)}
                visibleMenu={this.state.visibleMenu}
                isConnected={this.state.isConnected}
            />
        )
    }
}

MainScreenContainer.contextTypes = {
    rtspFlux: PropTypes.string,
    webSocketClient: PropTypes.object
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer,
        systemState: state.systemState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch),
        systemActions: bindActionCreators(systemActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreenContainer)