export const MAIN_SCREEN_BOTTOM_MENU_UPDATED = 'MAIN_SCREEN_BOTTOM_MENU_UPDATED'
export const MAIN_SCREEN_VIDEO_PLAYER_ACTIVITY = 'MAIN_SCREEN_VIDEO_PLAYER_ACTIVITY'
export const PLAYER_INSTANCE_VOLUME = 'PLAYER_INSTANCE_VOLUME'
export const MAIN_SCREEN_VIDEO_MODE = 'MAIN_SCREEN_VIDEO_MODE'
export const REFRESH_PLAYER_LAYOUT = 'REFRESH_PLAYER_LAYOUT'
export const APP_STATE = 'APP_STATE'
export const VIDEO_PLAYER_INSTANCE = 'VIDEO_PLAYER_INSTANCE'
export const COLOR_PICKER_ACTIVE = 'COLOR_PICKER_ACTIVE'
export const COLOR_PICKER_COORDINATES = 'COLOR_PICKER_COORDINATES'
export const POP_ALL_ON_CLOSE = 'POP_ALL_ON_CLOSE'
export const PAUSE_RENDER_PLAYER = 'PAUSE_RENDER_PLAYER'
export const EXPORT_IS_VISIBLE = 'EXPORT_IS_VISIBLE'