import { combineReducers } from 'redux'

import mixerReducer from './mixer'
import cameraReducer from './camera'
import globalsReducer from './globals'
import videoReducer from './video'
import audioReducer from './audio'
import systemReducer from './system'
import routerReducer from './router'
import capabilitiesReducer from './capabilities'
export default combineReducers({
    routerReducer,
    mixerReducer,
    cameraReducer,
    globalsReducer,
    videoReducer,
    audioReducer,
    systemReducer,
    capabilitiesReducer
})