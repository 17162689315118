import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import reducers from './reducers/index'

const logger = createLogger({ collapsed: true })

export default function configureStore(initialState) {
    const store = createStore(
        reducers,
        initialState || {},
        applyMiddleware(thunk),
    )

    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('./reducers/index').default;
            store.replaceReducer(nextRootReducer)
        })
    }

    return store
}