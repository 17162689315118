import React from 'react'
import { View, Text, StyleSheet, ScrollView, Platform } from 'react-native'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as systemActions from '../../actions/system'
import { Actions } from '../../navigation/Navigator'

import * as utils from '../../utils'
import IconedButton from '../../components/IconedButton'

var studioIcon = require('../../design/icons/option_studio.png')
var studioGreenIcon = require('../../design/icons/option_studio_green.png')

const PropTypes = require('prop-types')

class OptionsStudioMode extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            studioMode: -1
        }

    }

    getIcon(image, studioMode, label) {
        return (
            <IconedButton
                image={image}
                onPress={() => this.onPress(studioMode)}
                label={label}
                style={[styles.icon, (this.state.studioMode == studioMode) ? styles.activeIcon : {}]}
                maxIconSize={utils.moderateScale(100)}
                key={label}
            />
        )
    }

    getIcons() {
        let icons = []

        // Mixer
        icons.push(this.getIcon(studioIcon, 0, 'CAPTURE'))

        // Presentation
        icons.push(this.getIcon(studioGreenIcon, 1, 'STUDIO'))

        return icons
    }

    switchScene(scene) {
        this.context.webSocketClient.mixer.switchScene({
            scene_id: scene
        })
    }

    onPress(id) {
        this.setState({
            studioMode: id
        })
        const { scenes } = this.context.webSocketClient.mixer
        if (id == 1)
            this.switchScene(scenes.SPEAKER_ABOVE_SCREEN)
        else this.switchScene(scenes.FULL)

        if (Platform.OS !== "web")
            Actions.pop()
        else {
            Actions.push("Home")
        }
    }
    render() {
        return (
            <View style={styles.container}>
                <ScrollView style={styles.containerScroll}>
                    <Text style={[styles.text, styles.centered]}>STUDIO</Text>
                    <View style={[styles.row, styles.margin, styles.centered]}>
                        {this.getIcons().map((icon) => { return icon; })}
                    </View>
                </ScrollView>
            </View>
        )
    }
}

OptionsStudioMode.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    text: {
        color: '#FFFFFF',
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontSize: utils.moderateScale(13)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerScroll: {
        padding: utils.moderateScale(10),
        flex: 1
    },
    centered: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    margin: {
        margin: utils.moderateScale(10),
    },
    icon: {
        margin: utils.moderateScale(20),
        padding: utils.moderateScale(20),
        backgroundColor: '#000',
        alignItems: 'flex-start',
        backgroundColor: '#333333',
        borderRadius: utils.moderateScale(10)
    },
    activeIcon: {
        padding: utils.moderateScale(20) - 2,
        borderWidth: 2,
        borderColor: '#02FF24',
        backgroundColor: '#111'
    },
})

function mapStateToProps(state) {
    return {
        systemState: state.systemReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        systemActions: bindActionCreators(systemActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsStudioMode)