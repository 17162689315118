import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import * as globalsActions from '../../../actions/globals'

import VideoList from '../../../components/optionsPanel/video/VideoList'
import DiskSpaceRemaining from '../../../components/optionsPanel/video/DiskSpaceRemaining'

import { Actions } from "../../../navigation/Navigator"

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Messenger from '../../../components/Messenger'

import ServicesExporter from "../../../components/ServicesExporter"

const PropTypes = require('prop-types')

let instance = undefined
class VideoContainer extends React.Component {

    sectionHeaderClicked = false
    videoId = undefined

    static back = function () {
        Actions.pop()
    }

    constructor(props, context) {
        super(props, context)

        instance = this

        this.state = {
            sections: [],
            isRefreshing: false,
            multipleSelectionMode: false,
            freeSpace: undefined,
            totalSpace: undefined,
            exporterVisible: false,
            version: undefined
        }

        this.checkedItems = {}
        this.checkedHeaders = []

        try {
            this.videoId = this.props.history.location.state.videoId
        } catch (error) {
            this.videoId = undefined
        }
    }

    componentDidMount() {
        this.mount = true

        this.context.webSocketClient.video.onList(
            this.onList, this
        )

        this.context.webSocketClient.system.onFreeSpace(
            this.onFreeSpace, this
        )

        this.context.webSocketClient.system.onAbout(
            this.onAbout, this
        )

        this.refreshList()
        this.context.webSocketClient.system.about()
        this.context.webSocketClient.system.getFreeSpace()
    }

    onAbout(data) {
        this.setState({
            version: data.version.replace(/\D/g, '')
        })
    }

    componentWillUnmount() {
        this.mount = false

        this.context.webSocketClient.video.offList(
            this.onList
        )

        this.context.webSocketClient.system.offFreeSpace(
            this.onFreeSpace
        )

        this.context.webSocketClient.system.offAbout(
            this.onAbout
        )
    }

    onList(results) {
        let sections = []
        results.videos.forEach(function (video) {

            if (video.record_stopped_at) {
                video.key = video.id
                let section = this.getSectionByTitle(sections, new Date(video.record_started_at).toLocaleDateString())
                video.section = section
                section.data.push(video)

                if (video.id === parseInt(this.videoId) && this.videoId) {
                    Actions.replace("VideoDetailContainer", {
                        videoId: this.videoId,
                        session: video
                    })
    
                    this.videoId = null
                    return
                }
            }
        }, this)

        // Iterating to get takes
        sections.forEach((section) => {
            let videos = section.data
            videos.forEach((video) => {
                video.takeNb = videos.length - videos.indexOf(video)
            })
        })

        if (this.mount)
            this.setState({ sections })
    }

    onFreeSpace(params) {
        this.setState({
            freeSpace: params.free_space,
            totalSpace: params.total_space
        })
    }

    getSectionByTitle(sections, title) {
        let section = null

        sections.forEach(function (_section) {
            if (_section.title === title)
                section = _section
        }, this);

        if (section === null) {
            section = { title: title, data: [] }
            sections.push(section)
        }
        return section
    }

    getSessionById(sectionsCategories, id) {
        for (let i = 0; i < sectionsCategories.length; i++) {
            let sectionCategory = sectionsCategories[i]
            for (let j = 0; j < sectionCategory.data.length; j++) {
                let session = sectionCategory.data[j]
                if (session.id === id)
                    return session
            }
        }
    }

    removeSessionById(sectionsCategories, id) {
        for (let i = sectionsCategories.length - 1; i >= 0; i--) {
            let sectionCategory = sectionsCategories[i]
            for (let j = sectionCategory.data.length - 1; j >= 0; j--) {
                let session = sectionCategory.data[j]

                if (session.id === id)
                    sectionCategory.data.splice(j, 1)
            }

            if (sectionCategory.data.length === 0) {
                sectionsCategories.splice(i, 1)
            }
        }
    }

    refreshList() {
        this.context.webSocketClient.video.list()
    }

    updateTitleInList(session, newTitle) {
        let sections = [...this.state.sections]
        let _session = this.getSessionById(sections, session.id)
        _session.title = newTitle

        this.setState({ sections })
    }

    removeSessionFromList(id) {
        let sections = [...this.state.sections]
        this.removeSessionById(sections, id)
        this.setState({ sections })
    }

    onVideoClicked(id, isChecked, item) {
        if (this.state.multipleSelectionMode) {
            this.onValueChanged(id, isChecked, item)
        } else {
            let session = this.getSessionById(this.state.sections, id)
            Actions.VideoDetailContainer({
                videoId: id,
                session: session,
                // updateTitleInList: (newTitle) => { this.updateTitleInList(session, newTitle) },
                //removeSessionFromList: () => { this.removeSessionFromList(session.id) }
            })
        }
    }

    onHeaderSectionClicked(section, isChecked) {
        this.sectionHeaderClicked = true;
        for (const sessionId in section) {
            const session = section[sessionId];
            const videoListItemRef = "videoListItem" + session.id;
            const item = this.refs["videoList"].videoListItemRef[videoListItemRef]
            this.onVideoClicked(session.id, isChecked, item)
        }

        this.sectionHeaderClicked = false;
    }

    toggleMultipleSelection() {
        if (this.mount) {
            this.setState({
                multipleSelectionMode: !this.state.multipleSelectionMode
            })
        }
    }

    setSessionSectionItemChecked(sectionItem, isChecked) {
        if (sectionItem.state.isChecked != isChecked) {
            sectionItem.setState({ isChecked })

        }
    }

    onValueChanged(id, isChecked, item) {
        if (item) {
            item.setState({ isChecked })

            if (isChecked)
                this.checkedItems[id] = item
            else if (this.checkedItems[id])
                delete this.checkedItems[id]

            this.forceUpdate()

            const section = item.props.section

            const videoSectionHeaderRef = "videoHeaderItem" + section.title
            const sectionItem = this.refs["videoList"].videoHeaderItemRef[videoSectionHeaderRef]

            if (!this.sectionHeaderClicked) {
                for (let sessionId in section.data) {
                    const session = section.data[sessionId]

                    const videoListItemRef = "videoListItem" + session.id
                    const sessionItem = this.refs["videoList"].videoListItemRef[videoListItemRef]

                    if (sessionItem !== item && sessionItem.state.isChecked !== isChecked) {
                        this.setSessionSectionItemChecked(sectionItem, false)
                        for (let i = this.checkedHeaders.length - 1; i >= 0; i--) {
                            if (this.checkedHeaders[i] == sessionItem) {
                                this.checkedHeaders.splice(i, 1);
                            }
                        }
                        return
                    }
                }

                this.setSessionSectionItemChecked(sectionItem, isChecked)
            }

            if (isChecked) {
                let found = undefined;
                for (let i = 0; i < this.checkedHeaders.length; i++) {
                    if (this.checkedHeaders[i] == sectionItem) {
                        found = sectionItem;
                    }
                }

                if (!found)
                    this.checkedHeaders.unshift(sectionItem)
            }
        }
    }

    cancelMultipleSelection() {
        for (let id in this.checkedItems) {
            const checkedItem = this.checkedItems[id]
            checkedItem.setState({ isChecked: false })
            delete this.checkedItems[id]
        }

        for (let i = this.checkedHeaders.length - 1; i >= 0; i--) {
            const checkedHeader = this.checkedHeaders[i]
            checkedHeader.setState({ isChecked: false })

            this.checkedHeaders.splice(i, 1);
        }

        if (this.state.multipleSelectionMode)
            this.setState({ multipleSelectionMode: false })
    }

    deleteMultipleSelection() {
        Messenger.prompt(
            'Multiple deletion',
            'Are you sure you want to delete selected video(s) ?\nThis operation can\'t be undone',
            [
                { text: 'Cancel', onPress: () => { }, style: 'cancel' },
                { text: 'Delete', onPress: this.confirmMultipleDeletion.bind(this) },
            ],
            { cancelable: true }
        )
    }

    confirmMultipleDeletion() {
        const selectedItems = Object.keys(this.checkedItems).map((key) => {
            return parseInt(key);
        })
        this.context.webSocketClient.video.remove({
            videos_id: selectedItems
        })

        for (let id in selectedItems) {
            id = parseInt(selectedItems[id])
            this.removeSessionFromList(id)
        }

        this.cancelMultipleSelection()
    }

    exportMultipleSelection() {
        this.setState({ exporterVisible: true })
    }

    renderToolBar() {
        if (!this.state.multipleSelectionMode) {
            return (
                <TouchableOpacity style={styles.button}>
                    <Text style={styles.buttonText} onPress={this.toggleMultipleSelection.bind(this)}>Select many...</Text>
                </TouchableOpacity>
            )
        } else {
            const itemNumbers = Object.keys(this.checkedItems).length

            let disabledStyle = itemNumbers === 0 ? { color: '#FFFFFF40' } : {}

            return (
                <View style={styles.toolbarRow}>
                    <TouchableOpacity style={styles.button} onPress={this.cancelMultipleSelection.bind(this)}>
                        <Text style={[styles.buttonText]}>Cancel</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.button} disabled={itemNumbers === 0} onPress={this.deleteMultipleSelection.bind(this)}>
                        <Text style={[styles.buttonText, disabledStyle]}>Remove Selection...</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.button} disabled={itemNumbers === 0} onPress={this.exportMultipleSelection.bind(this)}>
                        <Text style={[styles.buttonText, disabledStyle]}>Export Selection...</Text>
                    </TouchableOpacity>
                </View>
            )
        }
    }

    onCancelExport() {
        this.props.globalsActions.displayExportIsVisible(false)
        this.setState({ exporterVisible: false })
    }

    onExportStarted() {
        this.props.globalsActions.displayExportIsVisible(false)
        this.cancelMultipleSelection()
    }

    renderServicesExporter() {
        if (this.state.version >= 201807042049 && !this.props.capabilitiesState.useLegacyPublisher) {
            return <ServicesExporter
                isVisible={this.state.exporterVisible}
                videos={Object.keys(this.checkedItems)}
                onCancel={this.onCancelExport.bind(this)}
                onExportStarted={this.onExportStarted.bind(this)}
                usbOnly={false}
            />
        } else {
            return <ServicesExporter
                isVisible={this.state.exporterVisible}
                videos={Object.keys(this.checkedItems)}
                onCancel={this.onCancelExport.bind(this)}
                onExportStarted={this.onExportStarted.bind(this)}
                usbOnly={true}
            />
        }
    }

    render() {
        return (
            <View style={{ flex: 1, flexDirection: 'column', backgroundColor: '#262626' }} className="video-container">
                <DiskSpaceRemaining
                    freeSpace={this.state.freeSpace}
                    totalSpace={this.state.totalSpace}
                />
                <VideoList
                    sections={this.state.sections}
                    multipleSelectionMode={this.state.multipleSelectionMode}
                    onVideoClicked={this.onVideoClicked.bind(this)}
                    onHeaderSectionClicked={this.onHeaderSectionClicked.bind(this)}
                    refreshList={this.refreshList.bind(this)}
                    isRefreshing={this.state.isRefreshing}
                    ref="videoList"
                />
                <View style={styles.toolbar}>
                    {this.renderToolBar()}
                </View>
                
                {this.renderServicesExporter()}
            </View>
        )
    }
}

VideoContainer.contextTypes = {
    webSocketClient: PropTypes.object
};

const styles = StyleSheet.create({
    sectionHeader: {
        fontSize: 14,
        padding: 15,
        fontWeight: 'bold',
        color: '#AAAAAA',
        backgroundColor: '#222222'
    },
    selectorContainer: {
        flexDirection: 'row'
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        alignContent: 'center',
        padding: 10
    },
    toolbarRow: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: 10,
        fontSize: 17
    },
    disabledButtonText: {
        color: '#333333',
    },
    background: {
        flex: 1,
    },
    activeBackground: {
        borderWidth: 2,
        borderColor: '#02FF24'
    }
})

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer,
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalsActions: bindActionCreators(globalsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoContainer)

VideoContainer.contextTypes = {
    webSocketClient: PropTypes.object
};