import React from 'react'
import { View, Text, StyleSheet, KeyboardAvoidingView, LayoutAnimation, TouchableOpacity, FlatList, ScrollView, Platform } from 'react-native'
import { Switch } from 'react-native-switch'

import PublishingContainerCreator from "./PublishingContainerCreator"
import PublishingContainerValidation from "./PublishingContainerValidation"
import ModalContainer from "../../ModalContainer";

import * as utils from '../../../utils'

import IconedButton from '../../../components/IconedButton'
import Messenger from '../../../components/Messenger'
import { Actions } from '../../../navigation/Navigator'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../../actions/globals'

import Dropdown from '../../../components/DropdownLive'
import Button from 'react-native-button'

const PropTypes = require('prop-types')

var paramsIcon = require('../../../design/icons/params.png')
var trashIcon = require('../../../design/icons/trash.png')
var validateIcon = require('../../../design/icons/validate.png')

var invalidIcon = require('../../../design/icons/publisher_invalid.png')
var validIcon = require('../../../design/icons/publisher_valid.png')
var unknownIcon = require('../../../design/icons/publisher_unknown.png')

let instance = undefined
class OptionsPublishingContainer extends React.Component {
    mount = false

    static getPopAllOnClose = function () {
        if (instance) {
            const { popAllOnClose } = instance.props.globalState
            instance.props.globalActions.setPopAllOnClose(false)
            return popAllOnClose
        }

        return false
    }

    constructor(props, context) {
        super(props, context)
        instance = this

        this.state = {
            autoLive: false,
            connectors: [],
            activeConnector: null,
            connectorTypes: [],
            creatorVisible: false,
            validationVisible: false,
            showLiveDropdownIcon: true,
            validationData: null,
            processing: false,
            hasNetAccess: false
        }

        this.context.webSocketClient.context.onUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.publication.onSave(
            this.onSave, this
        )

        this.context.webSocketClient.publication.onGetConnectors(
            this.onGetConnectors, this
        )

        this.context.webSocketClient.publication.onGetConnectorTypes(
            this.onGetConnectorTypes, this
        )

        this.context.webSocketClient.publication.onRemove(
            this.onRemove, this
        )

        this.context.webSocketClient.publication.onGetLiveData(
            this.onGetLiveData, this
        )

        this.context.webSocketClient.connection.onOpen(
            this.onConnectionOpened, this
        )

        this.context.webSocketClient.publication.onGetIsValid(
            this.onGetIsValid, this
        )

        this.context.webSocketClient.publication.onValidationData(
            this.onValidationData, this
        )

        this.context.webSocketClient.context.get()
    }

    componentWillUnmount() {
        this.mount = false

        this.context.webSocketClient.context.offUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.publication.offSave(
            this.onSave
        )

        this.context.webSocketClient.publication.offGetConnectors(
            this.onGetConnectors
        )

        this.context.webSocketClient.publication.offGetConnectorTypes(
            this.onGetConnectorTypes
        )

        this.context.webSocketClient.publication.offRemove(
            this.onRemove
        )

        this.context.webSocketClient.publication.offGetLiveData(
            this.onGetLiveData
        )

        this.context.webSocketClient.publication.offGetIsValid(
            this.onGetIsValid
        )

        this.context.webSocketClient.publication.offValidationData(
            this.onValidationData
        )
    }

    componentDidMount() {
        this.mount = true

        this.context.webSocketClient.publication.getConnectorTypes()
    }


    onContextUpdated(context) {
        const { is_connected } = context.network
        this.setState({ hasNetAccess: is_connected })
    }

    onSave(connector) {
        if (this.state.creatorVisible) {
            if (Object.keys(connector.fields).length > 1) {
                const toPush = { 'connector': connector, }
                if (Platform.OS !== "web")
                    toPush['deletePressed'] = this.deletePressed.bind(this)
                else 
                    toPush['profileId'] = connector.id

                Actions.PublishingContainerEditor(toPush)
            }
            this.setState({ creatorVisible: false })
        }
        this.context.webSocketClient.publication.getConnectors()
    }

    onGetConnectorTypes(_connectorTypes) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        let connectorTypes = []


        for (const connectorTypeName in _connectorTypes) {
            const connectorType = _connectorTypes[connectorTypeName]
            connectorType.iconPath = connectorType.icon
            connectorTypes.push(connectorType)
        }

        connectorTypes.sort((a, b) => {
            const x = a.verbose_name.toLowerCase();
            const y = b.verbose_name.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        })
        this.setState({ connectorTypes }, () => {
            this.context.webSocketClient.publication.getConnectors()
        })
    }

    getConnectorIcon(connector) {
        for (const connectorTypeI in this.state.connectorTypes) {
            let connectorType = this.state.connectorTypes[connectorTypeI]
            if (connectorType.name === connector.name) {
                return utils.getPublishingIcon(connectorType.iconPath)
            }
        }
    }

    onGetConnectors(connectors) {
        let formatedConnectors = []

        for (const connectordId in connectors) {
            const connector = connectors[connectordId]
            connector.icon = this.getConnectorIcon(connector)
            formatedConnectors.push(connector)
        }

        this.setState({
            connectors: formatedConnectors,
        }, () => {
            for (let i = 0; i < this.state.connectors.length; i++) {
                const connector = this.state.connectors[i]
                this.context.webSocketClient.publication.getIsValid({
                    'service': connector
                })

                if (i === this.state.connectors.length - 1) {
                    this.context.webSocketClient.publication.getLiveData()
                }
            }
        })
    }

    onLiveConnectorSelect(_, connector) {
        if (connector.isValid) {
            this.context.webSocketClient.publication.setLiveData({
                'service': connector,
                'auto': this.state.autoLive
            })
        }
    }

    onGetLiveData(liveData) {
        this.setState({
            autoLive: liveData.auto
        })

        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        let liveConnectors = this.getLiveConnectors().filter((connector) => {
            return connector && connector.isValid
        })

        const activeConnector = liveData.data
        for (const i in liveConnectors) {
            const _connector = liveConnectors[i]

            if (_connector.id == activeConnector.id) {
                this.setState({
                    activeConnector: _connector
                }, () => {
                    this.refs.liveDropdown.select(i, false)
                })
            }
        }
    }

    onConnectionOpened() {
        this.context.webSocketClient.publication.getConnectorTypes()
        this.context.webSocketClient.publication.getConnectors()
    }

    onGetIsValid(isValidData) {
        if ('is_valid' in isValidData && 'service' in isValidData) {
            const isValid = isValidData.is_valid
            const _connector = isValidData.service

            let connectors = [... this.state.connectors]

            for (let i = 0; i < connectors.length; i++) {
                const connector = connectors[i]
                if (connector.id == _connector.id) {
                    connector.isValid = isValid
                    break
                }
            }

            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)

            const validationData = this.state.validationData
            let mustCloseValidationModal = false

            if (isValid && validationData) {
                if ('service' in validationData && validationData.service.id == isValidData.service.id) {
                    mustCloseValidationModal = true
                }
            }

            this.setState({ connectors: [...connectors], validationData }, () => {
                if (mustCloseValidationModal) {
                    setTimeout(() => {
                        this.setState({
                            validationVisible: false,
                            validationData: null
                        })
                    }, 2000)
                }
            })
        }
    }

    onValidationData(validationData) {
        this.setState({
            validationVisible: true,
            validationData
        })
    }

    onRemove(_connector) {
        let connectors = [... this.state.connectors]

        for (let i = connectors.length - 1; i >= 0; i--) {
            const connector = connectors[i]
            if (connector.id == _connector.id) {
                connectors.splice(i, 1)
                break
            }
        }

        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        this.setState({
            connectors
        }, () => {
            if (this.state.activeConnector && this.state.activeConnector.id == _connector.id) {
                this.refs.liveDropdown.select(-1)
                this.setState({
                    activeConnector: null
                })
            }
        })
    }

    getLiveConnectors() {
        return this.state.connectors.filter((connector) => {
            return connector.types.indexOf('live') > -1
        })
    }

    autoLiveSwitched(activated) {
        if (this.state.autoLive !== activated) {
            this.context.webSocketClient.publication.setLiveData({
                'service': this.state.activeConnector,
                'auto': activated
            })
        }
    }

    createNewConnector() {
        this.setState({
            creatorVisible: true
        })
    }

    renderConnectorSelectText(connector) {
        return connector.fields.name.value
    }

    editPressed(connector) {
        const toPush = { connector }
        if (Platform.OS !== "web")
            toPush['deletePressed'] = this.deletePressed.bind(this)
        else 
            toPush['profileId'] = connector.id

        Actions.PublishingContainerEditor(toPush)
    }

    deletePressed(connector, onRemovePressed) {
        Messenger.prompt(
            'Confirmation required',
            "Are you sure your want to remove the following publishing profile : " + connector.fields.name.value + " ?",
            [{ text: 'Cancel', onPress: () => { }, style: 'cancel' }, {
                text: 'Remove', onPress: () => {
                    this.context.webSocketClient.publication.remove({
                        'service': connector
                    })
                    if (onRemovePressed)
                        onRemovePressed()
                }
            }],
            { cancelable: true }
        )
    }

    validatePressed(connector) {
        if (this.state.hasNetAccess) {
            this.context.webSocketClient.publication.validate({
                'service': connector
            })
        } else {
            Messenger.error("You need to have an internet access to validate this profile")
        }
    }

    renderEditButton(connector) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        if (connector && this.state.autoLive) {
            return (
                <IconedButton
                    key={'edit_' + this.state.connectors.indexOf(connector)}
                    image={paramsIcon}
                    style={[styles.rowImage]}
                    maxIconSize={utils.moderateScale(30)}
                    onPress={() => this.editPressed(connector)}
                    aspectRatio={1}
                />
            )
        }
    }

    renderDeleteButton(connector) {
        if (connector) {
            return (
                <IconedButton
                    key={'delete_' + this.state.connectors.indexOf(connector)}
                    image={trashIcon}
                    style={[styles.rowImage, { zIndex: 666 }]}
                    maxIconSize={utils.moderateScale(30)}
                    onPress={() => this.deletePressed(connector)}
                    aspectRatio={1}
                />
            )
        }
    }

    renderValidateButton(connector) {
        if (connector && !connector.isValid) {
            return (
                <Button
                    key={'validate_' + this.state.connectors.indexOf(connector)}
                    containerStyle={styles.buttonContainer}
                    onPress={() => this.validatePressed(connector)}
                    style={[styles.buttonText2, {
                        paddingHorizontal: utils.moderateScale(utils.getButtonGridUnit() / 4),
                        margin: 0
                    }]}
                    _
                >
                    <IconedButton
                        image={validateIcon}
                        aspectRatio={1}
                        disabled={true}
                        style={[styles.rowImage, {
                            right: - utils.moderateScale(15)
                        }]}
                    />Validation
                </Button>
            )
        }
    }

    renderConnectorListItem(connector) {
        const _connector = connector.item
        const index = this.state.connectors.indexOf(_connector)

        const backgroundColor = { backgroundColor: (index % 2 === 0) ? '#33333355' : '#333333' }
        const isValid = _connector.isValid

        let validationStateIcon = validIcon
        if (isValid === undefined) {
            validationStateIcon = unknownIcon
        } else if (!isValid) {
            validationStateIcon = invalidIcon
        }

        const padding = utils.moderateScale(1)

        return (
            <TouchableOpacity style={[styles.row, {
                paddingVertical: utils.moderateScale(5),
                paddingHorizontal: utils.moderateScale(5),
                height: utils.moderateScale(50),
            }, backgroundColor]}
                onPress={() => this.editPressed(_connector)}
            >
                <View style={styles.row}>
                    <IconedButton
                        image={_connector.icon}
                        style={[styles.rowImage, { marginRight: utils.moderateScale(10) }]}
                        maxIconSize={utils.moderateScale(30)}
                        onPress={this.editPressed.bind(this)}
                        aspectRatio={1}
                        isDisabled={true}
                    />
                    <IconedButton
                        image={validationStateIcon}
                        style={[styles.rowImage, {
                            position: 'absolute',
                            left: utils.moderateScale(20),
                            top: utils.moderateScale(20),
                            padding: utils.moderateScale(padding),
                            paddingRight: utils.moderateScale(padding),
                            backgroundColor: '#222222AA',
                            borderRadius: utils.moderateScale(10),
                        }]}
                        maxIconSize={utils.moderateScale(10)}
                        aspectRatio={1}
                        isDisabled={true}
                    />
                    <Text style={[styles.text, {
                        paddingTop: 0
                    }]}>{_connector.fields.name.value}</Text>
                </View>
                <View style={styles.row}>
                    {this.renderValidateButton(_connector)}
                    {this.renderDeleteButton(_connector)}
                </View>
            </TouchableOpacity>
        )
    }

    renderProfiles() {
        if (this.state.connectors.length > 0) {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <FlatList
                        data={this.state.connectors}
                        renderItem={this.renderConnectorListItem.bind(this)}
                        keyExtractor={(item) => '' + item.id}
                    />
                </View>
            )
        } else {
            return (
                <TouchableOpacity
                    style={styles.button}
                    onPress={this.createNewConnector.bind(this)}
                    activeOpacity={1}
                >
                    <Text style={[styles.textGrey, { textAlign: 'center' }]}>
                        No profile(s) found.{'\r'}Please, create your first publication profile using the
                        <Text
                            style={{
                                fontWeight: 'bold',
                                color: '#00FF2490'
                            }}
                        > Add new profile... </Text>button.
                    </Text>
                </TouchableOpacity>
            )
        }
    }

    render() {
        const liveConnectors = this.state.connectors.filter((connector) => {
            return connector && connector.isValid && connector.types.indexOf("live") > -1
        })

        return (
            <View style={styles.container}>

                {/* Creation overlay modal */}
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.state.creatorVisible}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-creator-modal-container"
                >
                    <KeyboardAvoidingView enabled behavior="padding" style={[styles.modalWrapper]}>
                        <View style={styles.modal}>
                            <PublishingContainerCreator
                                data={this.state.connectorTypes}
                                onCancel={() => { this.setState({ creatorVisible: false }) }}
                            />
                        </View>
                    </KeyboardAvoidingView>
                </ModalContainer>

                {/* Validation overlay modal */}
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.state.validationVisible}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-creator-modal-container"
                >
                    <View style={[styles.modalWrapper]}>
                        <View style={styles.modal}>
                            <PublishingContainerValidation
                                content={this.state.validationData}
                                onCancel={() => { this.setState({ validationVisible: false, validationData: null }) }}
                            />
                        </View>
                    </View>
                </ModalContainer>

                <View style={[styles.formWrapper]}>
                    <Text style={[styles.text, styles.title]}>Live diffusion</Text>

                    <View style={[styles.row, styles.input]} className="option-publishing-container-active-profile-row">
                        <Text style={styles.text}>Active live profile</Text>
                        <View style={{ flexDirection: 'row' }} className="option-publishing-container-active-profile">
                            {this.renderEditButton(this.state.activeConnector)}
                            <Dropdown
                                defaultValue="No profile selected   ▼"
                                onSelect={this.onLiveConnectorSelect.bind(this)}
                                options={liveConnectors}
                                renderRowText={this.renderConnectorSelectText.bind(this)}
                                renderRowImage={this.state.showLiveDropdownIcon}
                                ref={"liveDropdown"}
                                disabled={liveConnectors.length === 0}
                            />
                        </View>
                    </View>

                    <View style={[styles.row, {
                        paddingVertical: utils.moderateScale(10),
                        display: this.state.activeConnector ? 'flex' : 'none'
                    }]}>
                        <Text style={styles.text}>Enable live streaming</Text>
                        <Switch
                            onValueChange={this.autoLiveSwitched.bind(this)}
                            value={this.state.autoLive}
                            backgroundActive='#02FF24'
                            activeText=''
                            inActiveText=''
                            circleSize={utils.moderateScale(20)}
                        />
                    </View>
                </View>

                <Text style={[styles.text, styles.title, {
                    marginBottom: utils.moderateScale(20)
                }]}>Video Uploading / Live Profiles</Text>
                <ScrollView style={[styles.formWrapper]}>
                    {this.renderProfiles()}
                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity
                        style={styles.button}
                        onPress={this.createNewConnector.bind(this)}
                    >
                        <Text
                            style={styles.buttonText}
                        >Add new profile...</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
}

OptionsPublishingContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    buildIsDemo: PropTypes.bool,
    boxIP: PropTypes.string
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPublishingContainer)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
    },
    formWrapper: {
        margin: utils.moderateScale(10),
        flexShrink: 1
    },
    textInput: {
        height: 30,
        borderWidth: 1,
        paddingVertical: utils.moderateScale(20),
        paddingHorizontal: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
        paddingTop: utils.moderateScale(10)
    },
    textGrey: {
        fontSize: utils.moderateScale(13),
        color: '#555',
        paddingTop: utils.moderateScale(10)
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    buttonContainer: {
        backgroundColor: '#22222255',
        alignSelf: 'center',
        marginRight: utils.moderateScale(10),
        borderRadius: utils.moderateScale(10),
        height: utils.moderateScale(30),
        alignContent: 'center',
        justifyContent: 'center'
    },
    buttonText2: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(12),
    },
    global: {
        color: '#FFFFFF'
    },
    input: {
        marginTop: utils.moderateScale(10)
    },
    modalStyle: {
        backgroundColor: 'gray',
        height: utils.moderateScale(20),
        justifyContent: "center",
        paddingHorizontal: utils.moderateScale(20),
        borderRadius: 30
    },
    modalTextStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        fontSize: utils.moderateScale(10)
    },
    modalDropdownTextStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        fontSize: utils.moderateScale(12),
        padding: utils.moderateScale(5)
    },
    modalDropdownStyle: {
        backgroundColor: '#333333',
        borderColor: '#444444'
    },

    rowImage: {
        paddingRight: utils.moderateScale(5),
        maxHeight: utils.moderateScale(30),
        alignSelf: "center",
        padding: 0,
    },

    modalWrapper: {
        justifyContent: 'center',
        alignContent: 'center',
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#000000AA'
    },

    modal: {
        flex: 1,
        maxWidth: '90%',
        maxHeight: '90%',
        alignSelf: 'center'
    },
})