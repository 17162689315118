import React from "react";
import { Platform } from "react-native";

import NativeReduxRouter from './NativeReduxRouter';
import { Actions as RouterActions } from 'react-native-router-flux';
import { Router as WebRouter, Route as WebRoute } from "react-router-dom";

export class Actions {
    static history = undefined;
    static webRoutes = {};

    static parsePath(path, navigationProps) {
        const parsedPath = path.replace(/:(\w+)/g, (_, value) => navigationProps[value])
        return parsedPath
    }

    static pop() {
        if (Platform.OS === "web")
            Actions.history.goBack()
        else
            RouterActions.pop();
    }

    static push(key, navigationProps) {
        if (Platform.OS === "web") {
            Actions.history.push({
                pathname: Actions.parsePath(Actions.webRoutes[key], navigationProps),
                state: { ...navigationProps }
            })
        }
        else
            RouterActions[key]();
    }

    static replace(key, navigationProps) {
        if (Platform.OS === "web")
            Actions.history.replace({
                pathname: Actions.parsePath(Actions.webRoutes[key], navigationProps),
                state: { ...navigationProps }
            })
        else
            RouterActions.replace(key, navigationProps)
    }

    static execute(actionName, key, navigationProps = {}) {
        if (actionName === "replace") {
            if (Platform.OS !== 'web') {
                Actions.replace(key, navigationProps)
            } else {
                Actions.history.push({
                    pathname: Actions.parsePath(Actions.webRoutes[key], navigationProps),
                    state: { ...navigationProps }
                })
            }
        } else if (actionName === "push") {
            if (Platform.OS !== 'web') {
                RouterActions.push(key, navigationProps)
            } else {
                Actions.history.push({
                    pathname: Actions.parsePath(Actions.webRoutes[key], navigationProps),
                    state: { ...navigationProps }
                })
            }
        }
    }
}

export default class Navigator extends React.Component {

    constructor(props, state, context) {
        super(props, state, context);
        this.registerNavigationItem(this.props.navigation)

        Actions.history = this.props.history;
    }

    registerNavigationItem(navigationItem) {
        const { path, children } = navigationItem;

        let { key, webKey, nativeKey } = navigationItem;

        if (!key && webKey && Platform.OS === "web")
            key = webKey;

        else if (!key && nativeKey && Platform.OS !== "web")
            key = nativeKey;

        Actions.webRoutes[key] = path;

        Actions[key] = (navigationProps) => {
            if (Platform.OS === "web") {
                if (path) {
                    const parsedPath = Actions.parsePath(Actions.webRoutes[key], navigationProps)
                    this.props.history.push({
                        pathname: parsedPath,
                        state: { ...navigationProps }
                    })
                }
            } else {
                RouterActions[key](navigationProps)
            }
        }

        if (children) {
            children.forEach(navigationItem => this.registerNavigationItem(navigationItem));
        }
    }

    renderNativeNavigationItem(navigationItem) {
        const { key, nativeKey, nativeType, component, children, nativeProps, title } = navigationItem
        const NativeComponent = nativeType;
        const Component = component;

        if (children) {
            return (
                <NativeComponent key={key || nativeKey} title={title} {...nativeProps}>
                    {children.map(childNavigationItem => this.renderNativeNavigationItem(childNavigationItem))}
                </NativeComponent>
            )
        }

        return <NativeComponent key={key || nativeKey} title={title} component={Component} {...nativeProps} />
    }

    renderWebNavigationItem(navigationItem) {
        const { key, webKey, webType, component, children, webProps, path, exact } = navigationItem
        const WebComponent = webType;
        const Component = component;

        if (path)
            return <WebRoute key={key || webKey} exact={exact} component={Component} path={path} {...webProps} />
        else {
            if (children) {
                if (WebComponent) {
                    return (
                        <WebComponent key={key || webKey} {...webProps}>
                            {children.map(childNavigationItem => this.renderWebNavigationItem(childNavigationItem))}
                        </WebComponent>
                    )
                }

                return children.map(childNavigationItem => this.renderWebNavigationItem(childNavigationItem))
            }
        }
    }

    render() {
        if (Platform.OS === "web") {
            return (
                <WebRouter history={this.props.history}>
                    {this.renderWebNavigationItem(this.props.navigation)}
                </WebRouter>
            );
        } else {
            return (
                <NativeReduxRouter {...this.props}>
                    {this.renderNativeNavigationItem(this.props.navigation)}
                </NativeReduxRouter>
            );
        }
    }
}
