import * as types from '../constants/globals'

const initialState = {
    mainScreenBottomMenuGeo: {
        width: undefined,
        height: undefined,
        x: undefined,
        y: undefined
    },
    mainScreenVideoPlayerActive: true,
    playerInstanceVolume: 1,
    mainScreenVideoMode: 'default',
    refreshPlayerLayout: 0,
    appState: 'active',
    videoPlayerInstance: undefined,
    colorPickerActive: false,
    colorPickerCoordinates: { x: undefined, y: undefined, width: undefined, height: undefined },
    buildIsDemo: false,
    popAllOnClose: false,
    pauseRenderPlayer: false,
    exportIsVisible: false
}

export default function globalsReducer(state = initialState, action = {}) {

    switch (action.type) {
        case types.MAIN_SCREEN_BOTTOM_MENU_UPDATED:

            let mainScreenBottomMenuGeo = { ...state.mainScreenBottomMenuGeo }
            mainScreenBottomMenuGeo.width = action.newWidth
            mainScreenBottomMenuGeo.height = action.newHeight
            mainScreenBottomMenuGeo.x = action.x
            mainScreenBottomMenuGeo.y = action.y

            return {
                ...state,
                mainScreenBottomMenuGeo: mainScreenBottomMenuGeo
            }

        case types.MAIN_SCREEN_VIDEO_PLAYER_ACTIVITY:

            return {
                ...state,
                mainScreenVideoPlayerActive: action.isActive
            }

        case types.PLAYER_INSTANCE_VOLUME:

            return {
                ...state,
                playerInstanceVolume: action.volume
            }

        case types.MAIN_SCREEN_VIDEO_MODE:

            return {
                ...state,
                mainScreenVideoMode: action.mainScreenVideoMode
            }

        case types.REFRESH_PLAYER_LAYOUT:
            return {
                ...state,
                refreshPlayerLayout: state.refreshPlayerLayout + 1
            }

        case types.APP_STATE:
            return {
                ...state,
                appState: action.appState
            }

        case types.VIDEO_PLAYER_INSTANCE:
            return {
                ...state,
                videoPlayerInstance: action.videoPlayerInstance
            }

        case types.COLOR_PICKER_ACTIVE:
            return {
                ...state,
                colorPickerActive: action.isActive
            }

        case types.COLOR_PICKER_COORDINATES:
            return {
                ...state,
                colorPickerCoordinates: action.colorPickerCoordinates
            }

        case types.POP_ALL_ON_CLOSE:
            return {
                ...state,
                popAllOnClose: action.popAllOnClose
            }

        case types.PAUSE_RENDER_PLAYER:
            return {
                ...state,
                pauseRenderPlayer: action.isActive
            }

        case types.EXPORT_IS_VISIBLE:
            return {
                ...state,
                exportIsVisible: action.isActive
            }

        default:
            return state || initialState;
    }
}
