import React from 'react'
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native'

import IconedButton from '../../IconedButton'

import * as utils from '../../../utils'
const checkIcon = require('../../../design/icons/check.png')
const checkActiveIcon = require('../../../design/icons/check_active.png')

export default class VideoHeaderItem extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            isChecked: false
        }
    }

    onHeaderClicked(section) {
        if (this.props.checkable) {
            this.setState({
                isChecked: !this.state.isChecked
            }, () => {
                this.props.onHeaderSectionClicked(section, this.state.isChecked)
            })
        }
    }

    render() {
        const { section } = this.props
        const checkableWidth = (this.props.checkable) ? utils.moderateScale(15) : 0
        const HeaderWrapper = (this.props.checkable) ? TouchableOpacity : View

        return (
            <HeaderWrapper
                style={styles.sectionHeader}
                onPress={() => {
                    this.onHeaderClicked(section.data)
                }}>
                <IconedButton
                    image={checkIcon}
                    activeImage={checkActiveIcon}
                    isActive={this.state.isChecked}
                    style={{
                        marginRight: checkableWidth - 5,
                        aspectRatio: 1,
                        padding: 0
                    }}
                    maxIconSize={checkableWidth}
                    isDisabled={true}
                />
                <Text style={[styles.sectionText, styles.left]}>ID</Text>
                <Text style={[styles.sectionText, styles.center]}>{section.title}</Text>
                <Text style={[styles.sectionText, styles.right]}>Duration</Text>
            </HeaderWrapper>
        )
    }
}

const styles = StyleSheet.create({
    sectionHeader: {
        paddingHorizontal: utils.moderateScale(10),
        minHeight: utils.moderateScale(40),
        backgroundColor: '#222222',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    left: {
        flex: 1.5,
    },
    center: {
        textAlign: 'left',
        flex: 10
    },
    right: {
        textAlign: 'right',
        flex: 5
    },
    sectionText: {
        fontWeight: 'bold',
        color: '#AAAAAA',
        fontSize: utils.moderateScale(10)
    }
})