import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native'

import { ColorPicker, fromHsv } from 'react-native-color-picker'
import { SketchPicker } from 'react-color'

import Slider from "react-native-slider"
import PixelColor from 'react-native-pixel-color'
import { captureScreen } from "react-native-view-shot"
import IconedButton from "../../../components/IconedButton"

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as globalActions from '../../../actions/globals'

import * as utils from '../../../utils'
import ModalContainer from '../../ModalContainer';

const PropTypes = require('prop-types')

const colorPickerIcon = require("../../../design/icons/picker.png")
const colorPickerActiveIcon = require("../../../design/icons/picker_active.png")

class ChromaSettingsContainer extends React.Component {

    mount = false
    pipped = false

    toHex = (int) => { return ('0' + int.toString(16)).slice(-2) }
    toInt = (hex) => { return parseInt(hex, 16) }
    toRgbArray = (color) => {
        return color.split('#')[1].match(/.{2}/g).map((tint) => {
            return this.toInt(tint)
        }, this)
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            alphaAngle: 0,
            alphaBw: 0,
            rgb: '#FFFFFF',
            oldSavedRgb: '#FFFFFF',
            colorPickerVisible: false,
            opacity: 1
        }

        this.context.webSocketClient.mixer.onGetAlphaRgb(
            this.onGetAlphaRgb, this
        )

        this.context.webSocketClient.mixer.onGetAlphaAngle(
            this.onGetAlphaAngle, this
        )

        this.context.webSocketClient.mixer.onGetAlphaBw(
            this.onGetAlphaBw, this
        )
    }

    componentWillUnmount() {
        this.mount = false
        this.context.webSocketClient.mixer.offGetAlphaRgb(
            this.onGetAlphaRgb
        )

        this.context.webSocketClient.mixer.offGetAlphaAngle(
            this.onGetAlphaAngle
        )

        this.context.webSocketClient.mixer.offGetAlphaBw(
            this.onGetAlphaBw
        )
    }

    onColorPickerPressed() {
        this.props.globalActions.setColorPickerActive(
            !this.props.globalState.colorPickerActive
        )
    }

    onGetAlphaRgb(rgb) {
        if (this.mount) {
            this.setState({
                rgb: '#' + this.toHex(rgb[0]) + this.toHex(rgb[1]) + this.toHex(rgb[2]),
                oldSavedRgb: '#' + this.toHex(rgb[0]) + this.toHex(rgb[1]) + this.toHex(rgb[2]),
            })
        }
    }

    onGetAlphaAngle(alphaAngle) {
        if (this.mount) {
            this.setState({ alphaAngle })
        }
    }

    onGetAlphaBw(alphaBw) {
        if (this.mount) {
            this.setState({ alphaBw: alphaBw[0] })
        }
    }

    componentDidMount() {
        this.mount = true
    }

    componentDidUpdate(prevProps, prevState) {
        const { colorPickerCoordinates } = this.props.globalState
        const oldColorPickerCoordinates = prevProps.globalState.colorPickerCoordinates

        if (colorPickerCoordinates.x !== oldColorPickerCoordinates.x ||
            colorPickerCoordinates.y !== oldColorPickerCoordinates.y) {
            this.getPixelColor(colorPickerCoordinates)
        }
    }

    getPixelColor(colorPickerCoordinates) {

        if (Platform.OS === "web") {
            html2canvas(document.body).then((canvas) => {
                const { x, y, } = colorPickerCoordinates;

                var ctx = canvas.getContext('2d');
                var rgb = ctx.getImageData(x, y, 1, 1).data;


                this.onRgbChanged(rgb)
                this.saveColor()
                this.props.globalActions.setColorPickerActive(false)
            });
        } else {
            captureScreen({ format: "jpg", quality: 0.5, result: "base64" }).then(
                uri => {
                    const { x, y, } = colorPickerCoordinates;
                    const { width, height } = utils.getSize();

                    PixelColor.getHex("data:image/png;base64, " + uri, { x, y, width, height })
                        .then(pixelColor => {
                            this.onRgbChanged(pixelColor)
                            this.saveColor()
                            this.props.globalActions.setColorPickerActive(false)
                        })
                        .catch(error => { console.log('Error : ' + error) });
                },
                error => { }
            );
        }
    }

    rgbToHex(r, g, b) {
        if (r > 255 || g > 255 || b > 255)
            throw "Invalid color component";
        return ((r << 16) | (g << 8) | b).toString(16);
    }

    onRgbChanged(rgb) {
        if (Platform.OS === "web") {
            var hex = "#" + ("000000" + this.rgbToHex(rgb[0], rgb[1], rgb[2])).slice(-6);
            console.log("Picked web hex color : " + hex)
            this.setState({ rgb: hex })
            this.context.webSocketClient.mixer.setAlphaRgb({
                r: rgb[0],
                g: rgb[1],
                b: rgb[2]
            })
        } else {
            let rgbArray = this.toRgbArray(fromHsv(rgb))
            this.setState({ rgb: fromHsv(rgb) })
            this.context.webSocketClient.mixer.setAlphaRgb({
                r: rgbArray[0],
                g: rgbArray[1],
                b: rgbArray[2]
            })
        }
    }

    saveColor(mustRestore = false) {
        let rgbArray = this.toRgbArray(mustRestore ? this.state.oldSavedRgb : this.state.rgb)
        this.context.webSocketClient.mixer.saveAlphaRgb({
            r: rgbArray[0],
            g: rgbArray[1],
            b: rgbArray[2]
        })

        if (!mustRestore) {
            this.setState({ oldSavedRgb: this.state.rgb })
        } else {
            this.setState({ rgb: this.state.oldSavedRgb })
        }
        this.setColorPickerVisible(false)
    }

    saveRgb() {
        this.saveColor();
    }

    saveOldRgb() {
        this.saveColor(true);
    }

    onCancel() {
        this.saveColor(true)
        this.setColorPickerVisible(false)
    }

    save() {
        this.saveColor()
        this.setColorPickerVisible(false)
    }

    onAlphaAngleChanged(alphaAngle) {
        this.setState({ alphaAngle })
        this.context.webSocketClient.mixer.setAlphaAngle({
            angle: alphaAngle
        })
    }

    saveAlphaAngle() {
        this.context.webSocketClient.mixer.saveAlphaAngle({
            angle: this.state.alphaAngle
        })
    }

    onBwAngleChanged(alphaBw) {
        this.setState({ alphaBw })
        this.context.webSocketClient.mixer.setAlphaBw({
            black: alphaBw,
            white: alphaBw
        })
    }

    saveBwAngle() {
        this.context.webSocketClient.mixer.saveAlphaBw({
            black: this.state.alphaBw,
            white: this.state.alphaBw
        })
    }

    setColorPickerVisible(isVisible) {
        if (isVisible !== this.state.colorPickerVisible) {
            this.setState({ colorPickerVisible: isVisible })
            this.props.setVisibleMenu(!isVisible)
        }
    }

    onSketchPickerColorChange(color) {
        this.setState({ rgb: color.hex })
        this.context.webSocketClient.mixer.setAlphaRgb({
            r: color.rgb.r,
            g: color.rgb.g,
            b: color.rgb.b
        })

        /*
        this.context.webSocketClient.mixer.setAlphaRgb({
            r: color.rgb.r,
            g: color.rgb.g,
            b: color.rgb.b
        })
        */
    }

    onSketchPickerColorChanged(color) {
        this.context.webSocketClient.mixer.saveAlphaRgb({
            r: color.rgb.r,
            g: color.rgb.g,
            b: color.rgb.b
        })
    }

    renderColorPicker() {
        if (Platform.OS === 'web') {
            return (
                <SketchPicker
                    color={this.state.rgb}
                    onChange={this.onSketchPickerColorChange.bind(this)}
                    onChangeComplete={this.onSketchPickerColorChanged.bind(this)}
                />
            )
        } else {
            return (
                <ColorPicker
                    onColorChange={this.onRgbChanged.bind(this)}
                    onColorSelected={this.saveRgb.bind(this)}
                    onOldColorSelected={this.saveOldRgb.bind(this)}
                    style={styles.colorPicker}
                    oldColor={this.state.oldSavedRgb}
                />
            )
        }
    }

    render() {
        return (
            <View style={StyleSheet.flatten([styles.container, this.props.style])} className="chroma-settings-panel">
                <View style={{ flex: 1, opacity: 1 }}>
                    <View style={styles.column}>
                        <Text style={styles.columnText}>Color</Text>
                        <View style={styles.row}>
                            <IconedButton
                                image={colorPickerIcon}
                                activeImage={colorPickerActiveIcon}
                                isActive={this.props.globalState.colorPickerActive}
                                style={{
                                    padding: 0,
                                    alignSelf: 'center'
                                }}
                                maxIconSize={utils.getButtonGridUnit() / 2}
                                onPress={this.onColorPickerPressed.bind(this)}
                            />
                            <TouchableOpacity style={{
                                flex: 1,
                                maxWidth: utils.getButtonGridUnit(),
                                aspectRatio: 1,
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }} onPress={() => this.setColorPickerVisible(true)}>
                                <View style={[{
                                    backgroundColor: this.state.rgb
                                }, styles.colorButton]}></View>
                            </TouchableOpacity>

                        </View>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.columnText}>Alpha angle</Text>
                        <Slider
                            minimumValue={0}
                            maximumValue={90}
                            onValueChange={this.onAlphaAngleChanged.bind(this)}
                            onSlidingComplete={this.saveAlphaAngle.bind(this)}
                            step={0.1}
                            value={this.state.alphaAngle}
                            thumbTintColor='#00FF24'
                            minimumTrackTintColor='#AAA'
                            maximumTrackTintColor='#555'
                            thumbStyle={styles.thumb}
                        />
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.columnText}>Alpha B/W</Text>
                        <Slider
                            minimumValue={0}
                            maximumValue={128}
                            onValueChange={this.onBwAngleChanged.bind(this)}
                            onSlidingComplete={this.saveBwAngle.bind(this)}
                            step={0.1}
                            value={this.state.alphaBw}
                            thumbTintColor='#00FF24'
                            minimumTrackTintColor='#AAA'
                            maximumTrackTintColor='#555'
                            thumbStyle={styles.thumb}
                        />
                    </View>
                </View>
                <ModalContainer
                    animationType="slide"
                    transparent={true}
                    visible={this.state.colorPickerVisible}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="chroma-settings-container"
                >
                    <View style={styles.modal}>
                        <View style={styles.column}>
                            {this.renderColorPicker()}
                            <View style={styles.toolbar}>
                                <TouchableOpacity style={styles.button}>
                                    <Text style={styles.buttonText} onPress={this.onCancel.bind(this)}>Cancel</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.button}>
                                    <Text style={styles.buttonText} onPress={this.save.bind(this)}>Save</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </ModalContainer>
            </View>
        )
    }
}

ChromaSettingsContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        width: utils.getButtonGridUnit() * 3,
        height: utils.getButtonGridUnit() * 4 + 20,
    },
    modal: {
        flexDirection: 'row',
        flex: 1,
        alignSelf: 'center'
    },
    colorPicker: {
        flex: 1,
        padding: utils.moderateScale(20),
        opacity: 1
    },
    text: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(13),
        paddingTop: utils.moderateScale(10),
        backgroundColor: 'transparent'
    },
    columnText: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(13),
        alignSelf: 'flex-end',
        paddingTop: utils.moderateScale(10),
        backgroundColor: 'transparent'
    },
    column: {
        flexDirection: 'column',
        flex: 1,
        backgroundColor: 'transparent'
    },
    row: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent'
    },
    slider: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5FCFF',
        zIndex: 30
    },
    colorButton: {
        width: utils.getButtonGridUnit() / 1.5,
        height: utils.getButtonGridUnit() / 1.5,
        borderRadius: utils.getButtonGridUnit(),
        borderWidth: 1,
        borderColor: '#222',
        alignSelf: 'flex-end'
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10)
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    thumb: {
        width: utils.moderateScale(18),
        height: utils.moderateScale(18),
        backgroundColor: '#00FF24',
        borderRadius: utils.moderateScale(18) / 2,
    }
})

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChromaSettingsContainer)