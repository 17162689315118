import * as types from '../constants/video'

const initialState = {
    sessionState: undefined
}

export default function videoReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SESSION_STATE_UPDATED:
            return { ...state, sessionState: action.sessionState }

        default:
            return state || initialState;
    }
}