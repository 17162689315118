import React from 'react'
import { View, Platform } from 'react-native'
import GstPlayerContainer from '../containers/GstPlayerContainer'
import MainScreenFloatingMenuContainer from '../containers/mainScreen/MainScreenFloatingMenuContainer'
import MainScreenBottomMenuContainer from '../containers/mainScreen/MainScreenBottomMenuContainer'
import ConnectionAssistant from '../containers/connectionAssist/wrapper'

import * as globalActions from '../actions/globals'

import StatusBar from '../containers/StatusBar'

import Messenger from "./Messenger";

import * as utils from '../utils'
const PropTypes = require('prop-types')

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const CONNECTION_ASSISTANT_FIRST_CONNECT_DELAY = 15000
const CONNECTION_ASSISTANT_OTHER_CONNECT_DELAY = 30000
class MainScreen extends React.Component {

    appState = undefined
    showConnectionAssistantTimer = null

    constructor(props, context) {
        super(props, context)

        this.state = {
            bottomMenuHeight: 0,
            connectionAssistantVisible: false
        }
    }

    startConnectionAssistantTimer(delay) {
        if (this.showConnectionAssistantTimer) {
            clearTimeout(this.showConnectionAssistantTimer);
            this.showConnectionAssistantTimer = null;
        }

        this.showConnectionAssistantTimer = setTimeout(() => {
            if (!this.props.isConnected && !this.state.connectionAssistantVisible) {
                Messenger.prompt("Connection is taking time...", "The Wi-Fi connection between your mobile device and your Kast device may not be configured properly.\nDo you want to run a connection assistant to help you trough this process ?",
                [{ text: 'No', onPress: () => { }, style: 'cancel' }, {
                    text: 'Open assistant', onPress: () => this.setState({ connectionAssistantVisible: true })
                }],
                    { cancelable: false }
                )
            }

        }, delay);
    }

    componentDidMount() {
        if (Platform.OS !== "web")
        {
            this.startConnectionAssistantTimer(CONNECTION_ASSISTANT_FIRST_CONNECT_DELAY)
        }
    }

    componentDidUpdate(prevProps) {	
        if (Platform.OS !== "web")	
        {	
            if (!this.props.isConnected && prevProps.isConnected)	
                this.startConnectionAssistantTimer(CONNECTION_ASSISTANT_OTHER_CONNECT_DELAY);	
    	
            else if (this.props.isConnected && this.state.connectionAssistantVisible) {	
                if (this.showConnectionAssistantTimer) {	
                    clearTimeout(this.showConnectionAssistantTimer)	
                    this.showConnectionAssistantTimer = null	
    	
                    this.setState({ connectionAssistantVisible: false })	
                }	
            }	
        }	
    }

    onLayout() {
        let bottomMenuHeight = utils.getButtonGridUnit() * 2

        if (this.state.bottomMenuHeight !== bottomMenuHeight)
            this.setState({ bottomMenuHeight: bottomMenuHeight })
    }

    scanPixelColor(x, y, width, height) {
        this.props.globalActions.setColorPickerCoordinates({
            x, y, width, height
        })
    }

    onDragEnter(x, y, width, height) {
        if (this.props.globalState.colorPickerActive) {
            this.scanPixelColor(x, y, width, height)
        }
    }

    onPlayingStop() {
        if (this.props.onPlayingStop)
            this.props.onPlayingStop()
    }

    onPlayingProgress(progress, duration) {
        if (this.props.onPlayingProgress)
            this.props.onPlayingProgress(progress, duration)
    }

    getStudioModeInformator() {
        const { mainScreenVideoMode } = this.props.globalState
        return (
            (mainScreenVideoMode === 'studio') ?
                <View style={{
                    opacity: this.props.visibleMenu ? 1 : 0,
                    alignItems: 'center',
                    backgroundColor: '#02FF24',
                    padding: 1.5
                }}>
                </View> : null
        )
    }

    getMainScreenFloatingMenuContainer() {
        if (!this.props.globalState.colorPickerActive) {
            return (
                <MainScreenFloatingMenuContainer
                    isVisible={this.props.visibleMenu}
                    height={this.state.bottomMenuHeight * 0.75}
                />
            )
        }
    }

    renderPlayer() {
        const { routeName } = this.props.routerState
        const volume = (routeName === "Home") ? 1 : 0

        if ((routeName === "Home" || (Platform.OS == "ios")) && this.props.globalState.pauseRenderPlayer == false) {
            return (
                <GstPlayerContainer
                    onDragEnter={this.onDragEnter.bind(this)}
                    uri={this.context.rtspFlux}
                    showOverlay={true}
                    isPickingColor={this.props.globalState.colorPickerActive}
                    volume={volume}
                />
            )
        }

        return null
    }

    setConnectionAssistantVisibility(state) {
        if (this.state.connectionAssistantVisible !== state) {
            this.setState({
                connectionAssistantVisible: state
            })
        }
    }

    renderConnectionAssistant() {
        return (
            <ConnectionAssistant
                isVisible={this.state.connectionAssistantVisible}
                onCancel={() => this.setConnectionAssistantVisibility(false)}
            />
        )
    }

    render() {
        return (
            <View style={{ flex: 1, flexDirection: 'column', backgroundColor: '#00FF0010', height: '100%' }} onLayout={this.onLayout.bind(this)} className="main-screen">
                <StatusBar />

                <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }} className="main-screen">
                    {this.renderPlayer()}
                    {this.getMainScreenFloatingMenuContainer()}
                </View>

                {this.getStudioModeInformator()}

                <MainScreenBottomMenuContainer
                    isVisible={this.props.visibleMenu}
                    setVisibleMenu={this.props.setVisibleMenu}
                    height={this.state.bottomMenuHeight}
                />

                {this.renderConnectionAssistant()}
            </View>
        )
    }
}

MainScreen.contextTypes = {
    rtspFlux: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer,
        routerState: state.routerReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen)