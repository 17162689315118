// Standard
export const SCENE_FULL = 0
export const SCENE_SPEAKER = 1
export const SCENE_SCREEN = 2
export const SCENE_SPEAKER_SCREEN = 3

// Studio mode
export const SCENE_SPEAKER_ABOVE_SCREEN = 4
export const SCENE_SPEAKER_LEFT_TO_SCREEN = 5
export const SCENE_SPEAKER_RIGHT_TO_SCEEN = 6

// Watermark
export const WATERMARK_ENABLED_STATE = 7