import React from 'react'
import { View, Text, ScrollView, KeyboardAvoidingView, StyleSheet, TouchableOpacity } from 'react-native'
import { TextField } from 'react-native-material-textfield'
import { Switch } from 'react-native-switch';

import { Actions } from 'react-native-router-flux'

import Messenger from '../../../components/Messenger'
import * as utils from '../../../utils'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../../actions/globals'

import IconedButton from '../../../components/IconedButton'

const PropTypes = require('prop-types')

const broadcastServices = {
    YOUTUBE: 'YouTubeHost',
    FACEBOOK: 'FacebookHost',
    WEBEX: 'WebEx',
    SKYPE: 'Skype',
    ADOBECONNECT: 'Adobe Connect',
    CUSTOM: 'RtmpHost'
}

const youtubeIcon = require('../../../design/icons/youtube.png')
const youtubeActiveIcon = require('../../../design/icons/youtube_active.png')

const facebookIcon = require('../../../design/icons/facebook.png')
const facebookActiveIcon = require('../../../design/icons/facebook_active.png')

const webexIcon = require('../../../design/icons/webex.png')
const webexActiveIcon = require('../../../design/icons/webex_active.png')

const skypeIcon = require('../../../design/icons/skype.png')
const skypeActiveIcon = require('../../../design/icons/skype_active.png')

const adobeconnectIcon = require('../../../design/icons/adobeconnect.png')
const adobeconnectActiveIcon = require('../../../design/icons/adobeconnect_active.png')

const customIcon = require('../../../design/icons/publisher_icons/rtmp.png')
const customActiveIcon = require('../../../design/icons/publisher_icons/rtmp_active.png')

let instance = undefined
class OptionsPublishingContainer extends React.Component {
    mount = false
    tintColor = null

    static getPopAllOnClose = function () {
        const { popAllOnClose } = instance.props.globalState
        instance.props.globalActions.setPopAllOnClose(false)
        return popAllOnClose
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            autoLive: false,
            url: undefined,
            broadcastService: undefined,
            key: undefined,
            inputColor: "#FFFFFF",
            rtmpUsername: "",
            rtmpPassword: "",
            ready: false
        }

        this.context.webSocketClient.publication.onGetLiveData(
            this.onGetLiveData, this
        )

        this.context.webSocketClient.system.onNotificationSuccess(
            this.onNotificationSuccess, this
        )

        /*this.context.webSocketClient.system.onNotificationError(
            this.onNotificationError, this
        )*/

        this.context.webSocketClient.publication.getLiveData()

        instance = this
    }

    componentWillUnmount() {
        this.mount = false

        this.context.webSocketClient.publication.offGetLiveData(
            this.onGetLiveData
        )

        this.context.webSocketClient.system.offNotificationSuccess(
            this.onNotificationSuccess
        )

        /*this.context.webSocketClient.system.offNotificationError(
            this.onNotificationError
        )*/
    }

    onGetLiveData(params) {
        if (this.mount) {
            this.setState({
                autoLive: params.auto,
                url: params.data.url,
                key: params.data.key,
                broadcastService: params.data.publisher,
                rtmpUsername: params.data.username,
                rtmpPassword: params.data.password
            }, () => {
                this.setState({
                    ready: true
                })
            })
        }
    }

    onNotificationSuccess(message) {
        // Actions.pop()
    }

    onNotificationError(message) {
        Messenger.error(message.message)
    }

    componentDidMount() {
        this.mount = true
    }

    switchService(service) {
        this.setState({ broadcastService: service })
    }

    autoLiveSwitched(activated) {
        if (this.state.autoLive !== activated) {
            this.setState({ autoLive: activated })
        }
    }

    cancel() {
        Actions.pop()
    }

    save() {
        this.context.webSocketClient.publication.setLiveData({
            auto: this.state.autoLive,
            data: {
                url: this.state.url,
                key: this.state.key,
                publisher: this.state.broadcastService,
                username: this.state.rtmpUsername,
                password: this.state.rtmpPassword
            }
        })
    }

    componentDidUpdate(oldProps, oldState) {
        if (oldState.autoLive !== this.state.autoLive && this.state.ready) {
            this.save()
        }
    }

    renderKeyField(service) {

        let label = " key"

        switch (service) {
            case broadcastServices.YOUTUBE:
                label = "YouTube" + label
                break;
            case broadcastServices.FACEBOOK:
                label = "Facebook" + label
                break;
            case broadcastServices.WEBEX:
                label = "WebEx" + label
                break;
            case broadcastServices.SKYPE:
                label = "Skype" + label
                break;
            case broadcastServices.ADOBECONNECT:
                label = "Adobe Connect" + label
                break;
            case broadcastServices.CUSTOM:
                label = "Other" + label
                break;
            default:
                break;
        }

        if (service === this.state.broadcastService) {
            return (
                <TextField
                    keyboardType="default"
                    textContentType="none"
                    labelFontSize={utils.moderateScale(10)}
                    fontSize={utils.moderateScale(12)}
                    label={label}
                    value={this.state.key}
                    onChangeText={(key) => this.setState({ key })}
                    textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                />
            )
        }
    }

    renderUrlField(service) {
        if (service === this.state.broadcastService) {
            let label = "RTMP Url"
            return (
                <TextField
                    keyboardType="url"
                    textContentType="none"
                    labelFontSize={utils.moderateScale(10)}
                    fontSize={utils.moderateScale(12)}
                    label={label}
                    value={this.state.url}
                    onChangeText={(url) => this.setState({ url })}
                    textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                />
            )
        }
    }

    renderAuth(service) {
        if (service === this.state.broadcastService && this.props.capabilitiesState.hasRtmpAuth) {
            return (
                <View>
                    <TextField
                        textContentType="none"
                        labelFontSize={utils.moderateScale(10)}
                        fontSize={utils.moderateScale(12)}
                        label={"Username (Optional)"}
                        value={this.state.rtmpUsername || ""}
                        onChangeText={(rtmpUsername) => this.setState({ rtmpUsername })}
                        textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                    />
                    <TextField
                        textContentType="none"
                        labelFontSize={utils.moderateScale(10)}
                        fontSize={utils.moderateScale(12)}
                        label={"Password (Optional)"}
                        value={this.state.rtmpPassword || ""}
                        onChangeText={(rtmpPassword) => this.setState({ rtmpPassword })}
                        textColor={this.state.inputColor} tintColor={this.state.inputColor} baseColor={this.state.inputColor}
                    />
                </View>
            )
        }
    }

    getIconData(image, activeImage, service, label, isDemo = false) {
        return { image, activeImage, service, label, isDemo }
    }

    getIcons() {

        const icons = [
            this.getIconData(youtubeIcon, youtubeActiveIcon, broadcastServices.YOUTUBE, "YouTube"),
            this.getIconData(facebookIcon, facebookActiveIcon, broadcastServices.FACEBOOK, "Facebook")
        ]

        const demoIcons = [
            this.getIconData(webexIcon, webexActiveIcon, broadcastServices.WEBEX, "WebEx", true),
            this.getIconData(skypeIcon, skypeActiveIcon, broadcastServices.SKYPE, "Skype", true),
            this.getIconData(adobeconnectIcon, adobeconnectActiveIcon, broadcastServices.ADOBECONNECT, "Adobe Connect", true)
        ]

        const otherIcons = [
            this.getIconData(customIcon, customActiveIcon, broadcastServices.CUSTOM, "Other")
        ]

        return (

            <View style={[styles.row, { marginTop: 30, marginBottom: 15 }]} className="option-publishing-container-legacy">
                {
                    icons.concat(demoIcons).concat(otherIcons).map((icon, key) => {

                        if (!icon.isDemo || this.context.buildIsDemo) {
                            return (
                                <IconedButton
                                    key={key}
                                    image={icon.image}
                                    activeImage={icon.activeImage}
                                    onPress={() => this.switchService(icon.service)}
                                    style={styles.icon}
                                    isActive={this.state.broadcastService === icon.service}
                                    label={icon.label}
                                />
                            )
                        }
                    })
                }
            </View>
        )
    }

    render() {
        return (
            <KeyboardAvoidingView style={styles.container} enabled behavior="padding">
                <ScrollView style={[styles.container, styles.formWrapper]}>
                    <Text style={[styles.text, styles.title]}>Live diffusion</Text>

                    <View style={styles.row}>
                        <Text style={styles.text}>Enable live publication</Text>
                        <Switch
                            onValueChange={this.autoLiveSwitched.bind(this)}
                            value={this.state.autoLive}
                            backgroundActive='#02FF24'
                            activeText=''
                            inActiveText=''
                            circleSize={utils.moderateScale(20)}
                        />
                    </View>

                    {this.getIcons()}

                    <View style={styles.input}>
                        {this.renderKeyField(broadcastServices.YOUTUBE)}
                        {this.renderKeyField(broadcastServices.FACEBOOK)}
                        {this.renderKeyField(broadcastServices.WEBEX)}
                        {this.renderKeyField(broadcastServices.SKYPE)}
                        {this.renderKeyField(broadcastServices.ADOBECONNECT)}
                        {this.renderUrlField(broadcastServices.CUSTOM)}
                        {this.renderAuth(broadcastServices.CUSTOM)}
                    </View>

                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.save.bind(this)}>Save</Text>
                    </TouchableOpacity>
                </View>
            </KeyboardAvoidingView>
        )
    }
}

OptionsPublishingContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    buildIsDemo: PropTypes.bool
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer,
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPublishingContainer)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        padding: utils.moderateScale(10)
    },
    textInput: {
        height: 30,
        borderWidth: 1,
        paddingVertical: utils.moderateScale(20),
        paddingHorizontal: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
        paddingTop: utils.moderateScale(10)
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    global: {
        color: '#FFFFFF'
    },
    input: {
        marginTop: utils.moderateScale(10)
    }
})