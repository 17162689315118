import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import * as audioActions from '../actions/audio'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import IconedButton from './IconedButton'

import * as utils from '../utils'

const muteIcon = require('../design/icons/mic.png')
const muteActiveIcon = require('../design/icons/mic_disabled.png')

const PropTypes = require('prop-types')

class AudioFeedback extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            muted: false
        }

        this.context.webSocketClient.audio.onContext(
            this.onAudioContext, this
        )

        context.webSocketClient.audio.context()
    }

    componentWillUnmount() {
        this.context.webSocketClient.audio.offContext(
            this.onAudioContext
        )
    }

    onAudioContext(context) {
        if (this.state.muted !== context.muted) {
            this.setState({ muted: context.muted })
        }
    }

    getMeterColor(dbfs) {
        let red, green, blue
        if (dbfs >= 0) {
            red = 255
            green = blue = 0
        } else if (dbfs >= -6) {
            red = green = 255
            blue = 36
        } else if (dbfs >= -42) {
            red = 2
            green = 255
            blue = 36
        } else
            red = green = blue = 36
        return { r: red, g: green, b: blue }
    }

    getCircle(index, circleDef, audioLevel) {
        const minOpacity = 0.1
        const color = circleDef.color
        const { min, max } = circleDef.range

        let normalizedPosition = minOpacity

        if (audioLevel > min && audioLevel <= max) {
            normalizedPosition = ((audioLevel - min)) / (max - min)
        } if (audioLevel > max) {
            normalizedPosition = 1
        }

        return (
            <View key={index} style={[
                styles.circle, {
                    opacity: normalizedPosition,
                    backgroundColor: color
                }
            ]}></View>
        )
    }

    getCircles(audioLevel) {
        const circlesDefinitions = [{
            range: { min: -64, max: -44 },
            color: 'rgb(0, 153, 34)',
        }, {
            range: { min: -44, max: -34 },
            color: 'rgb(2, 175, 36)',
        }, {
            range: { min: -34, max: -24 },
            color: 'rgb(2, 175, 36)',
        }, {
            range: { min: -24, max: -14 },
            color: 'rgb(2, 204, 36)',
        }, {
            range: { min: -14, max: -4 },
            color: 'rgb(2, 255, 36)',
        }, {
            range: { min: -4, max: 1 },
            color: 'rgb(255, 136, 36)',
        }, {
            range: { min: 1, max: 6 },
            color: 'rgb(255, 0, 0)',
        }]

        return (
            circlesDefinitions.map((circleDef, index) => {
                return this.getCircle(index, circleDef, audioLevel)
            })
        )
    }

    onLayout(layout) {
        if (this.props && this.props.onLayout) {
            this.props.onLayout(layout)
        }
    }

    render() {
        const audioLevel = this.props.audioState.audioLevel

        return (
            <View
                style={[styles.container, this.props.style]}
                onLayout={this.onLayout.bind(this)}
            >
                <TouchableOpacity
                    style={styles.container}
                    onPress={this.props.onPress}
                >
                    <IconedButton
                        image={muteIcon}
                        activeImage={muteActiveIcon}
                        isActive={this.state.muted}
                        isDisabled={true}
                        style={{
                            padding: 0,
                            marginRight: 5,
                            alignSelf: 'center'
                        }}
                        maxIconSize={utils.getButtonGridUnit() / 2.5}
                    />
                    {this.getCircles(audioLevel)}
                </TouchableOpacity>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    circle: {
        width: utils.moderateScale(6),
        height: utils.moderateScale(6),
        borderRadius: 1000,
        marginHorizontal: utils.moderateScale(1),
    }
})

AudioFeedback.contextTypes = {
    webSocketClient: PropTypes.object
};

function mapStateToProps(state) {
    return {
        audioState: state.audioReducer,
        globalState: state.globalsReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(AudioFeedback)
