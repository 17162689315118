import React from 'react'
import { StyleSheet, LayoutAnimation, View, Text, FlatList } from 'react-native'
import { Bubbles, DoubleBounce, Bars, Pulse } from 'react-native-loader'
import Button from 'react-native-button';

import * as utils from '../utils'
import { moderateScale } from '../utils'
import Messenger from './Messenger'

const PropTypes = require('prop-types')


export default class VideoExporterPanelUsb extends React.Component {

    constructor(props, context) {
        let mount = false

        super(props, context)

        this.state = {
            tasks: [],
            listItemHeight: 0,
            messageHeight: 0,
            height: 0,
            isExporting: false,
            exportDone: false
        }

        this.context.webSocketClient.usb.onCopyTaskCreated(
            this.onCopyTaskCreated, this
        )

        this.context.webSocketClient.usb.onCopyTaskEnded(
            this.onCopyTaskEnded, this
        )

        this.context.webSocketClient.usb.onCopyTaskProgress(
            this.onCopyTaskProgress, this
        )

        this.context.webSocketClient.usb.onCopyTaskError(
            this.onCopyTaskError, this
        )
    }

    componentWillUnmount() {
        this.context.webSocketClient.usb.offCopyTaskCreated(
            this.onCopyTaskCreated
        )

        this.context.webSocketClient.usb.offCopyTaskEnded(
            this.onCopyTaskEnded
        )

        this.context.webSocketClient.usb.offCopyTaskProgress(
            this.onCopyTaskProgress
        )

        this.context.webSocketClient.usb.offCopyTaskError(
            this.onCopyTaskError
        )

        this.mount = false
    }


    createOrUpdateTask(task) {
        let { tasks } = this.state

        // Check if task is created
        let existingTask = undefined
        for (let i in tasks) {
            const _task = tasks[i]
            if (_task.id === task.id) {
                existingTask = _task
                break
            }
        }

        if (!existingTask) {
            task.error = null
            task.done = false
            tasks.push(task)
        } else {
            existingTask.progression = task.progression
        }
        this.setState({ tasks: [...tasks] })
    }

    getItemLayout(data, index)
    {
        const height = this.state.listItemHeight;
        return {
            length: height,
            offset: height * index,
            index
        }
    }

    onCopyTaskCreated(task) {

        if (this.mount) {
            this.createOrUpdateTask(task)
            this.resizeExporter()

            if (!this.state.isExporting)
                this.setState({ isExporting: true })
        }
    }

    onCopyTaskEnded(task) {
        for (let i in this.state.tasks) {
            let _task = this.state.tasks[i]
            if (_task.id === task.id) {
                _task.done = true
            }
        }

        // Hackish way to refresh listing
        let newTasks = this.state.tasks.filter(function () {
            return true
        })
        this.setState({ tasks: newTasks })
    }

    onCopyTaskProgress(task) {
        if (this.mount) {

            console.log(this.state.tasks);

            for (let i in this.state.tasks) {
                let _task = this.state.tasks[i]
                if (_task.id === task.id) {
                    this.scrollToTask(_task)
                }
            }

            this.createOrUpdateTask(task)
        }
    }

    onCopyTaskError(message) {
        const { task, error } = message

        for (let i in this.state.tasks) {
            let _task = this.state.tasks[i]
            if (_task.id === task.id) {
                _task.done = true
                _task.error = error || 'Unknown error'

                this.scrollToTask(_task)
            }
        }

        // Hackish way to refresh listing
        let newTasks = this.state.tasks.filter(function () {
            return true
        })
        this.setState({ tasks: newTasks })
    }

    scrollToTask(task) {
        let viewPosition = 0
        if (this.state.tasks.indexOf(task) === this.state.tasks.length - 1) {
            viewPosition = 1
        } else if (this.state.tasks.indexOf(task) > 0) {
            viewPosition = 0.5
        }

        /*this.refs.list.scrollToIndex({
            index: this.state.tasks.indexOf(task),
            viewOffset: 0,
            viewPosition,
        })*/
    }

    removeTask(task) {
        if (this.mount) {
            let { tasks } = this.state
            let newTasks = tasks.filter((_task) => {
                return _task.id !== task.id
            })

            this.setState({ tasks: [...newTasks] })
            if (newTasks.length === 0 && this.failedTasks.length > 0) {
                /*
                let list = ""
                for (let i in this.failedTasks) {
                    const failedTask = this.failedTasks[i]
                    list += failedTask.dest + "\n"
                }
                */
            }
        }
    }

    componentDidMount() {
        this.mount = true
    }

    componentDidUpdate(oldProps, oldState) {
        if (this.state.height !== this.getHeight()) {
            this.resizeExporter()
        }

        if (this.state.isExporting) {

            let tasksDone = this.state.tasks.filter(function (_task) {
                return _task.done
            })

            if (tasksDone.length === this.state.tasks.length) {
                if (!this.state.exportDone && this.state.isExporting) {
                    this.setState({ exportDone: true })
                    this.setState({ isExporting: false })
                }
            } else {
                if (this.state.exportDone)
                    this.setState({ exportDone: false })
            }
        }
    }

    getHeight() {
        const maxHeight = this.state.listItemHeight * 3 + this.state.messageHeight
        return (this.state.tasks.length > 0) ? Math.min(
            this.state.listItemHeight * this.state.tasks.length + this.state.messageHeight,
            maxHeight
        ) : 0
    }

    resizeExporter() {
        //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        if (this.getHeight() !== this.state.height)
            this.setState({ height: this.getHeight() })
    }

    onLayout(viewDetails) {
        const listItemHeight = utils.moderateScale(10) * 2 + viewDetails.nativeEvent.layout.height
        if (this.state.listItemHeight !== listItemHeight) {
            this.setState({ listItemHeight })
        }
        this.resizeExporter()
    }

    onExportingMessageLayout(viewDetails) {
        const messageHeight = utils.moderateScale(2) + viewDetails.nativeEvent.layout.height
        if (this.state.messageHeight !== messageHeight) {
            this.setState({
                messageHeight
            })
        }
        this.resizeExporter()
    }

    clearPressed() {
        this.setState({ tasks: [], exportDone: false })
    }

    renderTask(task, i) {
        task = task.item
        let { dest, progression } = task
        progression = Math.min(progression, 100).toFixed(0)

        let text = dest + " : " + progression + "%"
        if (progression === 100) {
            text = dest + " : Finalizing..."
        }

        let errorStyle = task.error ? styles.errorText : {}
        let successStyle = task.done ? styles.successText : {}

        if (task.error) {
            text += ' (Error : ' + task.error + ')'
        } else if (task.done) {
            text = dest + " : Successfully exported"
        }

        return (
            <View onLayout={this.onLayout.bind(this)}
                style={styles.itemContainer}>
                <Text style={[styles.text, successStyle, errorStyle]} key={i}>{text}</Text>
            </View>
        )
    }

    renderDoneButton() {
        if (this.state.exportDone) {
            return (
                <Button
                    containerStyle={[styles.buttonContainer]}
                    onPress={this.clearPressed.bind(this)}
                    style={[styles.buttonText, {
                        paddingHorizontal: utils.moderateScale(utils.getButtonGridUnit() / 4),
                        margin: 0
                    }]}
                >Close</Button>
            )
        }
    }

    renderInfoSection() {
        let text = "Please wait, exporting videos file..."

        let errorStyle = {}
        let successStyle = {}

        if (this.state.exportDone) {
            let tasksWithErrors = this.state.tasks.filter(function (_task) {
                return _task.error
            })

            text = "Exporting videos file successful ! You may now safely remove your usb device"

            errorStyle = tasksWithErrors.length > 0 ? styles.errorText : {}
            if (tasksWithErrors.length > 0) {
                text = "Exporting videos file done with some errors. You may now safely remove your usb device"
            }
        }

        return (
            <View style={styles.feedback}>
                <Text onLayout={this.onExportingMessageLayout.bind(this)} style={[styles.warningText, errorStyle]}>{text}</Text>
                {this.renderDoneButton()}
            </View>
        )
    }

    render() {
        return (
            <View style={[styles.container, { maxHeight: this.state.height }]} className="video-exporter-panel">
                {this.renderInfoSection()}
                {<FlatList
                    ref="list"
                    data={this.state.tasks}
                    keyExtractor={(item, index) => item.id}
                    renderItem={this.renderTask.bind(this)}
                    getItemLayout={this.getItemLayout.bind(this)}
                />}
            </View>
        )
    }
}

VideoExporterPanelUsb.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#161616',
    },

    itemContainer: {
        flexDirection: 'row',
        margin: utils.moderateScale(10),
        alignContent: 'center',
        justifyContent: 'center'
    },

    text: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(8)
    },

    errorText: {
        color:  '#FF0000'
    },

    successText: {
        color:  '#00FF24'
    },

    buttonContainer: {
        backgroundColor: '#111111',
        flexDirection: 'row',
        position: 'absolute',
        alignSelf: 'center',
        right: utils.moderateScale(5),
        padding: utils.moderateScale(2),
        borderRadius: utils.moderateScale(10),
    },

    buttonText: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(10),
        alignSelf: 'center',
    },

    warningText: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(9),
        padding: utils.moderateScale(5),
        textAlign: 'center',
    },

    feedback: {
        marginTop: utils.moderateScale(2),
        backgroundColor: '#222',
        flexDirection: 'row',
        justifyContent: 'center',
        overflow: 'hidden'
    }
})