import React from 'react'
import { View, Text } from 'react-native'

export default class WebVideoPlayer extends React.Component {

    render() {
        return (
            <View style={{
                flex: 1,
                overflow: 'hidden',
                justifyContent: 'center',
                backgroundColor: '#262626',
            }}>
                <video width="100%" height="100%" className="webvideo-player" controls>
                    <source src={this.props.url} type="video/mp4" />
                </video>
            </View>
        )
    }
}