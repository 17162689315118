import React from 'react'
import { View, TouchableOpacity, Text, StyleSheet, FlatList } from 'react-native'

import * as Progress from 'react-native-progress';
import * as utils from '../utils'

import ModalContainer from '../containers/ModalContainer'

import CommonListItem from '../components/CommonListItem'

const PropTypes = require('prop-types')

function pathJoin(parts, sep) {
    var separator = sep || '/';
    var replace = new RegExp(separator + '{1,}', 'g');
    return parts.join(separator).replace(replace, separator);
}


export default class UsbImporter extends React.Component {

    mount = false

    getInitialState() {
        return {
            devices: [],
            currentDevice: undefined,
            currentFolder: undefined,
            listContent: [],
            loaderVisible: false
        }
    }

    constructor(props, context) {
        super(props, context)

        this.state = this.getInitialState()

        // Registering
        this.context.webSocketClient.usb.onDeviceList(
            this.onDeviceList, this
        )

        this.context.webSocketClient.usb.onDeviceDirList(
            this.onDeviceDirList, this
        )
    }

    componentWillUnmount() {
        this.mount = false

        this.context.webSocketClient.usb.offDeviceList(
            this.onDeviceList
        )

        this.context.webSocketClient.usb.offDeviceDirList(
            this.onDeviceDirList
        )
    }

    onDeviceList(devices) {
        if (this.mount) {

            this.setState({ devices: devices })

            if (!this.currentDevice) {
                this.setState({
                    listContent: Object.keys(devices).map(function (key, index) {
                        devices[key].isDevice = true
                        devices[key].key = devices[key].friendly_name
                        return devices[key]
                    })
                })
            }

            this.showLoader(false)
        }
    }

    onDeviceDirList(deviceDirList) {
        if (this.mount) {

            let _deviceDirList = deviceDirList.entries.filter((value) => {
                let canReturn = !value.name.startsWith('.')

                if (canReturn && this.props.filterEntry) {
                    canReturn = this.props.filterEntry(value)
                }
                
                return canReturn
            }).map((value, key) => {
                value.key = value.name
                return value
            })

            _deviceDirList.unshift({
                isDir: false,
                isBack: true,
                name: "...", key: "..."
            })

            this.setState({
                listContent: _deviceDirList,
                currentFolder: deviceDirList.path
            })

            this.showLoader(false)
        }
    }

    componentDidMount() {
        this.mount = true
    }

    componentDidUpdate(oldProps, oldState) {
        this.mount = this.props.isVisible

        if (oldProps.isVisible !== this.props.isVisible) {
            this.setState(this.getInitialState())

            if (this.props.isVisible)
                this.context.webSocketClient.usb.deviceList()
        }
    }

    showLoader(loaderVisible) {
        this.setState({ loaderVisible })
    }

    onSelectedItem(item) {
        this.showLoader(true)

        if (item.isDevice)
            this.onSelectedDevice(item)
        else if (item.is_dir || item.isDir)
            this.onSelectedFolder(item)
        else if (item.isBack)
            this.onSelectedBack()
        else
            this.onSelectedFile(item)
    }

    onSelectedDevice(device) {
        this.setState({ currentDevice: device })
        this.context.webSocketClient.usb.deviceDirList({
            device_id: device.id,
            path: '/'
        })
    }

    onSelectedFolder(folder) {
        this.context.webSocketClient.usb.deviceDirList({
            device_id: this.state.currentDevice.id,
            path: pathJoin([this.state.currentFolder, folder.name])
        })
    }

    onSelectedFile(file) {
        this.props.onFileSelected(pathJoin([this.state.currentFolder, file.name]), this.state.currentDevice.id)
        this.props.onCancel()
    }

    onSelectedBack() {
        const str = this.state.currentFolder

        if (str.length > 1) {
            this.context.webSocketClient.usb.deviceDirList({
                device_id: this.state.currentDevice.id,
                path: str.substring(0, str.lastIndexOf("/"))
            })
        } else {
            this.context.webSocketClient.usb.deviceList()
        }
    }

    getList() {
        if (this.state.loaderVisible) {
            return (
                <View style={{
                    flex: 1,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <Progress.CircleSnail
                        size={utils.getButtonGridUnit() * 2.5}
                        color={'#02FF24'}
                        thickness={2}
                    />
                </View>
            )
        } else {
            return (
                <FlatList
                    data={this.state.listContent}
                    renderItem={
                        (entry) => <CommonListItem
                            onClick={this.onSelectedItem.bind(this)}
                            item={entry.item}
                        />
                    }
                />
            )
        }
    }

    render() {
        return (
            <ModalContainer
                animationType="slide"
                transparent={true}
                visible={this.props.isVisible}
                supportedOrientations={['portrait', 'landscape']}
                onRequestClose={() => { }}
                className="usb-importer"
            >
                <View style={styles.modal}>
                    <View style={styles.container}>

                        {this.getList()}

                    </View>
                    <View style={styles.toolbar}>
                        <TouchableOpacity style={styles.button}>
                            <Text style={styles.buttonText} onPress={this.props.onCancel}>Cancel</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ModalContainer>
        )
    }
}

UsbImporter.contextTypes = {
    webSocketClient: PropTypes.object
}

const styles = StyleSheet.create({
    modal: {
        flexDirection: 'column',
        flex: 1,
        backgroundColor: '#262626',
    },
    text: {
        color: "#FFFFFF",
    },
    container: {
        padding: 10,
        flex: 1
    },
    row: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        alignContent: 'center',
        padding: 10
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: 10,
        fontSize: utils.moderateScale(13)
    },
})