import React from 'react'
import { StyleSheet, View } from 'react-native'

import { connect } from 'react-redux'

import OptionsPublishingContainerOld from "./publisher/OptionsPublishingContainer";
import OptionsPublishingContainer201807042049 from "./publisherV2/201807042049OptionsPublishingContainer";
import OptionsPublishingContainer201920110000 from "./publisher/201920110000OptionsPublishingContainer";

const PropTypes = require('prop-types')

class OptionsPublishingContainer extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            version: undefined
        }

        this.context.webSocketClient.system.onAbout(
            this.onAbout, this
        )

        this.context.webSocketClient.system.about()
    }

    onAbout(data) {
        this.setState({
            version: data.version.replace(/\D/g, '')
        })
    }

    componentWillUnmount() {
        this.context.webSocketClient.system.offAbout(
            this.onAbout
        )
    }

    render() {
        const { version } = this.state
        if (version) {
            if (version <= 201907042049 && !this.props.capabilitiesState.useLegacyPublisher) {
                return <OptionsPublishingContainer201807042049 />
            } else if (version > 201907042049 && this.props.capabilitiesState.canUploadNoauth) {
                return <OptionsPublishingContainer201920110000 />
            } else {
                return <OptionsPublishingContainerOld />//201906111236
            }
        } else {
            return <View style={styles.container} />
        }
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
})

OptionsPublishingContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

function mapStateToProps(state) {
    return {
        capabilitiesState: state.capabilitiesReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPublishingContainer)