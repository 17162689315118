import React from 'react'
import { View, TouchableOpacity, Text, Image, StyleSheet, Dimensions } from 'react-native'
import { Switch } from 'react-native-switch';

import { connect } from 'react-redux'

import Grid from 'react-native-photo-grid';

import UsbImporter from '../../components/UsbImporter'
import Messenger from '../../components/Messenger'
import * as utils from '../../utils'

const PropTypes = require('prop-types')

const defaultWatermark = ''

class OptionsWatermarkContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            watermarks: [],
            currentWatermark: undefined,
            watermarksPerRow: 2,
            importingWatermarkVisible: false
        }
    }

    componentDidMount() {

        this.context.webSocketClient.watermark.onList(
            this.onList, this
        )

        this.context.webSocketClient.watermark.onGetCurrent(
            this.onGetCurrent, this
        )

        this.refreshList()
    }

    componentWillUnmount() {
        this.context.webSocketClient.watermark.offList(
            this.onList
        )

        this.context.webSocketClient.watermark.offGetCurrent(
            this.onGetCurrent
        )
    }

    onList(list) {
        this.setState({
            watermarks: list.watermarks.filter((watermark) => {
                return watermark !== defaultWatermark
            })
        }, () => {
            this.context.webSocketClient.watermark.getCurrent()
        })
    }

    onGetCurrent(currentWatermark) {
        this.setState({ currentWatermark: currentWatermark.watermark })
    }

    refreshList() {
        this.context.webSocketClient.watermark.get()
    }

    import() {
        this.setState({ importingWatermarkVisible: true })
    }

    closeImportWatermark() {
        this.setState({ importingWatermarkVisible: false })
    }

    importFile(filePath, device) {
        this.context.webSocketClient.watermark.usbCopy({
            device_id: device,
            path: filePath
        })
    }

    delete(watermark) {
        Messenger.prompt(
            'Remove watermark',
            'Are you sure you want to remove the current watermark ' + watermark + ' ?',
            [
                { text: 'Cancel', onPress: () => { }, style: 'cancel' },
                { text: 'Remove', onPress: () => { this.removeWatermark(watermark) } },
            ],
            { cancelable: false }
        )
    }

    removeWatermark(watermark) {
        this.context.webSocketClient.watermark.remove({
            watermark: watermark
        })
        this.refreshList()
    }

    imageTouched(watermark) {
        this.setState({
            currentWatermark: watermark
        }, () => {
            this.context.webSocketClient.watermark.set({
                watermark: watermark
            })
        })
    }

    renderItem(watermark, watermarkSize) {
        const uri = this.context.mediaServer + '/watermarks/' + watermark

        return (
            <View key={watermark}>
                <TouchableOpacity
                    style={{ width: watermarkSize, height: watermarkSize }}
                    onPress={() => this.imageTouched(watermark)}
                >
                    <Image
                        resizeMode="cover"
                        style={[
                            styles.watermark,
                            (this.state.currentWatermark === watermark) ? styles.activeWatermark : {}
                        ]}
                        source={{ uri }}
                    />
                </TouchableOpacity>
                <View style={[
                    styles.toolbar,
                    {}
                ]}>
                    <TouchableOpacity onPress={() => this.delete(watermark)} disabled={(this.state.currentWatermark === watermark)} style={styles.button}>
                        <Text style={[
                            styles.buttonText,
                            (this.state.currentWatermark === watermark) ? styles.disabledButtonText : {}
                        ]}>Delete</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    onLayout() {
        const dim = Dimensions.get('screen')
        this.setState({ watermarksPerRow: (dim.height >= dim.width) ? 2 : 3 })
    }

    filterEntry(entry) {
        return (entry.is_dir) ? true : entry.name.endsWith('.png')
    }

    onEnableStateChanged(enabled) {
        this.context.webSocketClient.mixer.setWatermarkEnabled({
            enabled
        })
    }    

    render() {
        const { watermarks } = this.state

        return (
            <View style={styles.container} onLayout={this.onLayout.bind(this)} className="options-watermark-container">
                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button}>
                        <Text style={styles.buttonText} onPress={this.import.bind(this)}>Import</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.row}>
                    <Text style={styles.inlineText}>Enable Watermark</Text>
                    <Switch
                        onValueChange={this.onEnableStateChanged.bind(this)}
                        value={this.props.mixerState.watermarkEnabled}
                        backgroundActive='#02FF24'
                        activeText=''
                        inActiveText=''
                        circleSize={utils.moderateScale(18)}
                        changeValueImmediately={true}
                    />
                </View>

                <View style={{ flex: 1 }} className="options-watermark-container-grid">
                    <Grid
                        data={watermarks}
                        itemsPerRow={this.state.watermarksPerRow}
                        itemMargin={utils.moderateScale(30)}
                        renderItem={this.renderItem.bind(this)}
                    />
                </View>
                <UsbImporter
                    isVisible={this.state.importingWatermarkVisible}
                    onCancel={this.closeImportWatermark.bind(this)}
                    onFileSelected={this.importFile.bind(this)}
                    filterEntry={this.filterEntry.bind(this)}
                />
            </View>
        )
    }
}

OptionsWatermarkContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    boxIP: PropTypes.string,
    mediaServer: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        mixerState: state.mixerReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {};
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    containerScroll: {
        flex: 1,
        padding: utils.moderateScale(10)
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        alignContent: 'center',
        padding: utils.moderateScale(10)
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    inlineText: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(14),
    },
    disabledButtonText: {
        color: '#333333',
    },
    watermark: {
        flex: 1,
    },
    activeWatermark: {
        borderWidth: 2,
        borderColor: '#02FF24'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(OptionsWatermarkContainer)