import React from 'react'
import { View, Text, Button, StyleSheet, FlatList, TouchableHighlight } from 'react-native'

import ModalContainer from '../containers/ModalContainer'

import * as utils from '../utils'

export default class ModalFilterPickerWeb extends React.Component {

    constructor(props, context) {
        super(props, context)
    }

    renderItem(item) {
        const { key, label } = item

        return (
            <TouchableHighlight
                key={key}
                onPress={() => this.props.onSelect(key)}
            >
                <Text style={{ fontSize: utils.moderateScale(11), }} className="usb-entry">{label}</Text>
            </TouchableHighlight>
        );
    }

    render() {
        return (
            <ModalContainer
                visible={this.props.visible || this.props.isVisible}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                overlayClassName={this.props.overlayClassName || "modal_overlay"}
                className="modal-content-usb-exporter"
            >
                <View>
                    <Text style={{ color: 'white', fontSize: utils.moderateScale(11), }}>{this.props.title}</Text>
                    <FlatList
                        data={this.props.options}
                        renderItem={(item) => this.renderItem(item.item)}
                        className="usb-enties"
                    />
                    <Button title="Cancel" onPress={() => this.props.onCancel()} />
                </View>
            </ModalContainer>
        )
    }
}

const styles = StyleSheet.create({
    container: {

    }
});