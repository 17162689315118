import React from 'react'
import { Text, SectionList, View, StyleSheet } from 'react-native'

import VideoListItem from './VideoListItem'
import VideoHeaderItem from './VideoHeaderItem'

import * as utils from './../../../utils'

const PropTypes = require('prop-types')

export default class VideoList extends React.Component {

    videoListItemRef=[];
    videoHeaderItemRef=[];

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.sections !== nextProps.sections)
            return true

        if (this.props.multipleSelectionMode !== nextProps.multipleSelectionMode)
            return true

        return false
    }

    renderSectionHeader(section) {
        section = section.section
        const ref = "videoHeaderItem" + section.title
        return (
            <VideoHeaderItem
                checkable={this.props.multipleSelectionMode}
                onHeaderSectionClicked={this.props.onHeaderSectionClicked}
                section={section}
                ref={(videoHeaderItem) => {
                    this.videoHeaderItemRef[ref] = videoHeaderItem
                }}
            />
        )
    }

    renderItem(item) {
        const ref = "videoListItem" + item.item.id

        return (
            <VideoListItem
                checkable={this.props.multipleSelectionMode}
                onVideoClicked={this.props.onVideoClicked}
                style={styles.item}
                ref={(videoListItem) => {
                    this.videoListItemRef[ref] = videoListItem
                }}
                {...item.item}
            />
        )
    }

    render() {
        return (
            <SectionList
                sections={this.props.sections}
                renderItem={this.renderItem.bind(this)}
                renderSectionHeader={this.renderSectionHeader.bind(this)}
                onRefresh={this.props.refreshList}
                refreshing={this.props.isRefreshing}
                initialNumToRender={2500}
                style={{
                    flex: 1
                }}
                className="video-listing"
            />
        )
    }
}

const styles = StyleSheet.create({
    sectionHeader: {
        paddingHorizontal: utils.moderateScale(10),
        paddingVertical: utils.moderateScale(15),
        backgroundColor: '#222222',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    left: {
        flex: 1.5,
    },
    center: {
        textAlign: 'left',
        flex: 10
    },
    right: {
        textAlign: 'right',
        flex: 5
    },
    sectionText: {
        fontWeight: 'bold',
        color: '#AAAAAA',
        fontSize: utils.moderateScale(10)
    }
})