import React from 'react'
import { View, Text, StyleSheet, Platform } from 'react-native'

import IconedButton from '../../../components/IconedButton'
import Button from 'react-native-button';

import * as cameraActions from '../../../actions/camera'
import * as cameraConstants from '../../../constants/camera'

import * as mixerActions from '../../../actions/mixer'

import ModalFilterPicker from 'react-native-modal-filter-picker'
import ModalFilterPickerWeb from '../../../containers/ModalFilterPickerWeb'

import Messenger from '../../../components/Messenger'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Tracking
var trackingIcon = require('./../../../design/icons/tracking.png')
var trackingActiveIcon = require('./../../../design/icons/tracking_active.png')
var trackingSetIcon = require('./../../../design/icons/tracking_set.png')
var trackingSetEndIcon = require('./../../../design/icons/tracking_set_end.png')

// Close View
var closeViewIcon = require('../../../design/icons/view_close.png')
var closeViewActiveIcon = require('../../../design/icons/view_close_active.png')
var closeViewSaveIcon = require('../../../design/icons/view_close_save.png')

// Medium View
var mediumViewIcon = require('../../../design/icons/view_medium.png')
var mediumViewActiveIcon = require('../../../design/icons/view_medium_active.png')
var mediumViewSaveIcon = require('../../../design/icons/view_medium_save.png')

// Full View
var fullViewIcon = require('../../../design/icons/view_full.png')
var fullViewActiveIcon = require('../../../design/icons/view_full_active.png')
var fullViewSaveIcon = require('../../../design/icons/view_full_save.png')

import * as utils from '../../../utils'

const PropTypes = require('prop-types')

class RoomConfiguratorContainer extends React.Component {

    mount = false
    savingView = undefined

    userTrackingPreset = {
        key: 'user',
        label: 'User defined'
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            activatedView: undefined,
            isSettingTracking: false,
            trackingZoneListVisible: false,
            trackingZonesList: [],
            userCanDrawTrackingZone: false,
            currentTrackingZonePreset: undefined,
            videoCameraHasTracking: true
        }

        this.context.webSocketClient.context.onUpdated(
            this.onUpdated, this
        )

        this.context.webSocketClient.context.get()
    }

    componentWillUnmount(context) {
        this.mount = false

        this.context.webSocketClient.context.offUpdated(
            this.onUpdated
        )

        this.props.cameraActions.setTracking(false)
    }

    onUpdated(context) {
        if (this.mount) {
            let userCanDrawTrackingZone;
            let tracking_zone_presets;
            try {
                userCanDrawTrackingZone = context.camera.tracking_zone_custom
            } catch (exception) {
                userCanDrawTrackingZone = true
            }

            try {
                tracking_zone_presets = context.camera.tracking_zone_presets
            } catch (exception) {
                tracking_zone_presets = []
            }

            const videoCameraHasTracking = (userCanDrawTrackingZone || Object.keys(tracking_zone_presets).length > 0)
            let trackingZonesList = []

            if (userCanDrawTrackingZone)
                trackingZonesList.push(this.userTrackingPreset)

            for (const label in tracking_zone_presets) {
                if (tracking_zone_presets.hasOwnProperty(label)) {
                    const key = tracking_zone_presets[label]

                    trackingZonesList.push({
                        key,
                        label
                    })
                }
            }

            this.setState({
                activatedView: context.mixer.mode,
                videoCameraHasTracking,
                userCanDrawTrackingZone,
                trackingZonesList
            })
        }
    }

    componentDidMount() {
        this.mount = true
    }

    switchView(view) {
        this.context.webSocketClient.editing.switchViewWithTracking(view)
        this.context.webSocketClient.camera.stopTrack()
        this.context.webSocketClient.camera.switchView(view)
    }

    saveView(view) {
        this.savingView = view

        let viewName = 'Undefined'
        switch (view) {
            case cameraConstants.VIEW_SPEAKER:
                viewName = 'close-range (i.e, the speaker)'
                break;

            case cameraConstants.VIEW_MEDIUM:
                viewName = 'mid-range (i.e, the desk)'
                break;

            case cameraConstants.VIEW_FULL:
                viewName = 'long-range (i.e, the stage)'
                break;
        }

        Messenger.prompt(
            'Saving',
            'Are you sure you want to replace the old configuration for the ' + viewName + ' view ?',
            [
                { text: 'Cancel', onPress: () => {}, style: 'cancel' },
                { text: 'Save', onPress: this.confirmViewSave.bind(this) },
            ],
            { cancelable: false }
        )
    }

    confirmViewSave() {
        this.context.webSocketClient.camera.saveView(this.savingView)
        this.switchView(this.savingView)
    }

    getTrackingButton(setTrackingActive) {
        if (this.state.videoCameraHasTracking) {
            return (
                <View style={{
                    alignItems: 'center',
                    top: 0,
                    left: -utils.getButtonGridUnit() * 1.5,
                }}>
                    <IconedButton
                        image={trackingIcon}
                        activeImage={trackingActiveIcon}
                        isActive={this.props.isTracking}
                        onPress={() => this.props.setTracking(!this.props.isTracking)}
                        style={styles.icon}
                        isDisabled={setTrackingActive}
                    />
                </View>
            )
        }
    }

    getTrackingButtonConfiguration(setTrackingActive) {
        if (this.state.videoCameraHasTracking && (this.state.userCanDrawTrackingZone || this.state.trackingZonesList.length > 0)) {
            return (
                <View style={{
                    alignItems: 'center',
                    left: -utils.getButtonGridUnit() * 1.5,
                    top: utils.getButtonGridUnit(),
                }}>
                    <IconedButton
                        image={trackingSetIcon}
                        activeImage={trackingSetEndIcon}
                        isActive={this.state.isSettingTracking}
                        onPress={this.trackingConfigurationButtonPressed.bind(this)}
                        style={styles.icon}
                    />
                </View>
            )
        }
    }

    getTrackingPresetsButton(setTrackingActive) {
        if (this.state.isSettingTracking && this.state.trackingZonesList.length > 1) {
            return (
                <View
                    pointerEvents='box-none'
                    style={[, {
                        alignItems: 'flex-end',
                        right: utils.getButtonGridUnit() * 4.5,
                        top: utils.getButtonGridUnit(),
                    }]}
                >
                    <Button
                        containerStyle={[styles.buttonContainer, {
                            height: utils.getButtonGridUnit(),
                            right: 0
                        }]}
                        onPress={this.showPresets.bind(this)}
                        style={styles.buttonText}
                    >Tracking Zone</Button>
                </View>
            )
        }
    }

    trackingConfigurationButtonPressed() {
        const isSettingTracking = !this.state.isSettingTracking

        if (isSettingTracking) {
            this.context.webSocketClient.camera.setTrackingZone()
        } else {
            this.props.cameraActions.setTracking(false)
            this.context.webSocketClient.camera.endSettingTrackingZone()
        }

        if (this.state.trackingZonesList.length === 1 && this.state.trackingZonesList[0] === this.userTrackingPreset) {
            this.props.cameraActions.setTracking(isSettingTracking)
        }

        this.setState({ isSettingTracking })
    }

    showPresets() {
        this.setState({ trackingZoneListVisible: true })
    }

    onTrackingZoneSelect(trackingZoneIndex) {
        this.setState({
            currentTrackingZonePreset: trackingZoneIndex,
            trackingZoneListVisible: false
        })

        this.props.cameraActions.setTracking(trackingZoneIndex === 'user')

        if (trackingZoneIndex !== 'user') {
            this.context.webSocketClient.camera.setZonePreset({
                preset_id: trackingZoneIndex
            })
        } else {
            this.setState({ isSettingTracking: true })

        }
    }

    onTrackingZoneCancel() {
        this.setState({
            trackingZoneListVisible: false
        })
    }

    onRequestClose() { }

    render() {
        const { setTrackingActive } = this.props.cameraState

        const ModalFilterPickerComponent = Platform.OS === "web" ? ModalFilterPickerWeb : ModalFilterPicker
        
        return (

            <View style={{ flex: 1 }} pointerEvents='box-none' className="room-configurator-container">
                <ModalFilterPickerComponent
                    visible={this.state.trackingZoneListVisible}
                    onSelect={this.onTrackingZoneSelect.bind(this)}
                    onCancel={this.onTrackingZoneCancel.bind(this)}
                    options={this.state.trackingZonesList}
                    modal={{
                        onRequestClose: this.onRequestClose.bind(this),
                        transparent: true,
                        hardwareAccelerated: true,
                        animationType: 'fade'
                    }}
                    cancelButtonText='Cancel'
                    showFilter={false}
                    title='Select a tracking zone'
                    noResultsText='No tracking zone avaliable'
                />
                <View
                    style={StyleSheet.flatten([styles.container, this.props.style])}
                    pointerEvents='box-none'
                >
                    <View style={{
                        alignItems: 'flex-end',
                    }}>
                        <Text style={styles.text}>Preview</Text>
                    </View>

                    {this.getTrackingButton(setTrackingActive)}

                    <View
                        style={{
                            alignItems: 'center',
                            left: 0,
                            top: 0,
                        }}
                    >
                        <IconedButton
                            image={closeViewIcon}
                            activeImage={closeViewActiveIcon}
                            onPress={() => this.switchView(cameraConstants.VIEW_SPEAKER)}
                            canVibrate={true}
                            style={styles.icon}
                            isDisabled={setTrackingActive}
                            isActive={this.state.activatedView === cameraConstants.VIEW_SPEAKER}
                        />
                    </View>
                    <View style={{
                        alignItems: 'center',
                        right: -utils.getButtonGridUnit(),
                        top: 0
                    }}>
                        <IconedButton
                            image={mediumViewIcon}
                            activeImage={mediumViewActiveIcon}
                            onPress={() => this.switchView(cameraConstants.VIEW_MEDIUM)}
                            canVibrate={true}
                            style={styles.icon}
                            isDisabled={setTrackingActive}
                            isActive={this.state.activatedView === cameraConstants.VIEW_MEDIUM}
                        />
                    </View>
                    <View style={{
                        alignItems: 'center',
                        right: -utils.getButtonGridUnit() * 2,
                        top: 0
                    }}>
                        <IconedButton
                            image={fullViewIcon}
                            activeImage={fullViewActiveIcon}
                            onPress={() => this.switchView(cameraConstants.VIEW_FULL)}
                            canVibrate={true}
                            style={styles.icon}
                            isDisabled={setTrackingActive}
                            isActive={this.state.activatedView === cameraConstants.VIEW_FULL}
                        />
                    </View>
                    <View style={{
                        alignItems: 'flex-end',
                        height: utils.getButtonGridUnit(),
                        top: utils.getButtonGridUnit()
                    }}>
                        <Text style={styles.text}>Save</Text>
                    </View>

                    {this.getTrackingButtonConfiguration(setTrackingActive)}

                    <View style={{
                        alignItems: 'center',
                        top: utils.getButtonGridUnit()
                    }}>
                        <IconedButton
                            image={closeViewSaveIcon}
                            onPress={() => this.saveView(cameraConstants.VIEW_SPEAKER)}
                            canVibrate={true}
                            style={styles.icon}
                            isDisabled={setTrackingActive}
                        />
                    </View>
                    <View style={{
                        alignItems: 'center',
                        top: utils.getButtonGridUnit(),
                        right: -utils.getButtonGridUnit()
                    }}>
                        <IconedButton
                            image={mediumViewSaveIcon}
                            onPress={() => this.saveView(cameraConstants.VIEW_MEDIUM)}
                            canVibrate={true}
                            style={styles.icon}
                            isDisabled={setTrackingActive}
                        />
                    </View>
                    <View style={{
                        alignItems: 'center',
                        right: -utils.getButtonGridUnit() * 2,
                        top: utils.getButtonGridUnit()
                    }}>
                        <IconedButton
                            image={fullViewSaveIcon}
                            onPress={() => this.saveView(cameraConstants.VIEW_FULL)}
                            canVibrate={true}
                            style={styles.icon}
                            isDisabled={setTrackingActive}
                        />
                    </View>
                    {this.getTrackingPresetsButton(setTrackingActive)}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        height: utils.getButtonGridUnit() * 4,
        width: utils.getButtonGridUnit() * 5
    },
    icon: {
        position: 'absolute',
        padding: utils.moderateScale(utils.getButtonGridUnit() / 8),
        backgroundColor: '#222222',
        borderRadius: utils.moderateScale(utils.getButtonGridUnit()),
        width: utils.getButtonGridUnit(),
        height: utils.getButtonGridUnit()
    },
    text: {
        color: "#FFFFFF",
        fontSize: utils.moderateScale(14),
        alignSelf: 'flex-end',
        paddingTop: utils.moderateScale(10),
        backgroundColor: 'transparent',
    },
    buttonContainer: {
        backgroundColor: '#222222',
        borderRadius: 100,
        flexDirection: 'row',
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(12),
        alignSelf: 'center',
        margin: utils.moderateScale(5)
    },
})

RoomConfiguratorContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

function mapStateToProps(state) {
    return {
        mixerState: state.mixerReducer,
        cameraState: state.cameraReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        mixerActions: bindActionCreators(mixerActions, dispatch),
        cameraActions: bindActionCreators(cameraActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomConfiguratorContainer)