import React from 'react'
import { View, TouchableHighlight, Vibration, Text, StyleSheet } from 'react-native'
import IconedButton from '../../components/IconedButton'


import * as videoConstants from '../../constants/video'

// Rec
var recIcon = require('../../design/icons/rec_start3.png')
var recActiveIcon = require('../../design/icons/rec_start.png')

// Params
var paramsIcon = require('../../design/icons/params.png')

// Video
var videoIcon = require('../../design/icons/option_video.png')


export default class MainScreenFloatingMenu extends React.Component {

    onPressRecButton() {
        this.props.onPressRecButton()
    }

    render() {

        let recColor = (this.props.sessionState === videoConstants.SESSION_STATES.LIVE) ?
            '#FF0000' : '#FFFFFF'

        let opacity = 1

        const buttonSize = this.props.buttonSize
        const diffSize = 10
        const noBorderButtonSize = buttonSize - diffSize

        if (!this.props.isVisible)
            opacity = 0

        return (
            <View
                pointerEvents='box-none'
                style={StyleSheet.flatten([{
                    opacity: opacity,
                    position: 'absolute',
                    left: 0, right: 0, bottom: diffSize, top: 0,
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }, this.props.style])}
            >
                <TouchableHighlight
                    onPress={this.props.onPressCenterButton}
                    underlayColor={recColor}
                    style={{
                        position: 'absolute',
                        backgroundColor: recColor,
                        borderRadius: buttonSize,
                        width: buttonSize,
                        height: buttonSize,
                        zIndex: 10,
                        borderColor: '#22222222',
                        borderWidth: 3
                    }}
                >
                    <View></View>
                </TouchableHighlight>

                <View style={{
                    top: -this.props.buttonOffsetY,
                    alignItems: 'center'
                }}>
                    <IconedButton
                        image={recIcon}
                        activeImage={recActiveIcon}
                        onPress={this.onPressRecButton.bind(this)}
                        onPressIn={() => this.props.startClosingMenuTimer()}
                        onPressOut={() => this.props.startClosingMenuTimer()}
                        isActive={this.props.sessionState === videoConstants.SESSION_STATES.LIVE}
                        canVibrate={true}
                        maxIconSize={noBorderButtonSize}
                        style={{
                            position: 'absolute',
                            width: noBorderButtonSize,
                            height: noBorderButtonSize,
                            bottom: noBorderButtonSize / 2 - noBorderButtonSize / 2 + diffSize / 2,
                            backgroundColor: "#22222222",
                            borderRadius: noBorderButtonSize,
                            padding: 0
                        }}
                    />
                </View>

                <View style={{
                    right: this.props.buttonOffsetX,
                    top: -this.props.buttonOffsetY / 2,
                    alignItems: 'center'
                }}>
                    <IconedButton
                        image={paramsIcon}
                        onPress={() => { this.props.onPressParamButton()}}
                        canVibrate={true}
                        maxIconSize={noBorderButtonSize}
                        style={{
                            position: 'absolute',
                            width: noBorderButtonSize,
                            height: noBorderButtonSize,
                            bottom: buttonSize / 2 - buttonSize / 2 + diffSize / 2,
                            backgroundColor: "#22222222",
                            borderRadius: buttonSize,
                            padding: 0
                        }}
                    />
                </View>

                <View style={{
                    left: this.props.buttonOffsetX,
                    top: -this.props.buttonOffsetY / 2,
                    alignItems: 'center'
                }}>
                    <IconedButton
                        image={videoIcon}
                        onPress={() => { this.props.onPressVideoButton() }}
                        canVibrate={true}
                        maxIconSize={noBorderButtonSize}
                        style={{
                            position: 'absolute',
                            width: noBorderButtonSize,
                            height: noBorderButtonSize,
                            bottom: buttonSize / 2 - buttonSize / 2 + diffSize / 2,
                            backgroundColor: "#22222222",
                            borderRadius: buttonSize,
                            padding: 0
                        }}
                    />
                </View>
            </View>
        )
    }
}