import React from 'react'
import { View, Text, ScrollView, Button, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import * as Progress from 'react-native-progress';

import ModalFilterPickerWeb from '../../containers/ModalFilterPickerWeb'
import ModalFilterPicker from 'react-native-modal-filter-picker'

import IconedButton from '../../components/IconedButton'

import * as utils from '../../utils'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../actions/globals'

const PropTypes = require('prop-types')

// Icons
var muteIcon = require('../../design/icons/mic_green.png')
var muteActiveIcon = require('../../design/icons/mic_disabled.png')

let instance = undefined
class OptionsAudioContainer extends React.Component {

    mount = false
    barBorder = 1
    barHeight = utils.moderateScale(15)

    constructor(props, context) {
        super(props, context)

        this.state = {
            inputsList: [],
            currentInput: undefined,
            inputsListVisible: false,
            volume: 0,
            boost: 0,
            indeterminate: true,
            muted: false
        }

        this.context.webSocketClient.audio.onContext(
            this.onContext, this
        )

        this.getContext()

        instance = this
    }

    componentWillUnmount() {
        this.mount = false
        
        this.context.webSocketClient.audio.offContext(
            this.onContext
        )
    }

    onContext(context) {
        if (this.mount) {
            let _inputsList = []
            if (this.mount) {
                context.sources.forEach((value, i) => {
                    _inputsList.push({ key: value.toLowerCase(), label: value })
                }, this)

                let _currentInput = undefined
                _inputsList.forEach(function(_input) {
                    if (_input.label === context.source)
                        _currentInput = _input
                }, this)

                this.setState({
                    inputsList: _inputsList,
                    indeterminate: false,
                    boost: context.boost / 100,
                    volume: context.volume / 100,
                    muted: context.muted,
                    currentInput: _currentInput
                })
            }
        }
    }

    componentDidMount() {
        this.mount = true
    }

    showInputList() {
        this.setState({
            inputsListVisible: true
        })
    }

    volumeUp() {
        this.context.webSocketClient.audio.volumeUp()
    }

    volumeDown() {
        if (this.state.volume >= 0.2)
            this.context.webSocketClient.audio.volumeDown()
    }

    gainUp() {
        this.context.webSocketClient.audio.boostUp()
    }

    gainDown() {
        this.context.webSocketClient.audio.boostDown()
    }

    toggleMute() {
        this.context.webSocketClient.audio.toggleMute()
    }

    getContext() {
        this.context.webSocketClient.audio.context()
    }

    onSelect(inputKey) {
        this.state.inputsList.forEach(function(_input) {
            if (inputKey === _input.key) {
                const name = _input.label
                this.context.webSocketClient.audio.setSource({
                    source: name
                })

                this.setState({ inputsListVisible: false })
                return
            }
        }, this);
    }

    onCancel() {
        this.setState({
            inputsListVisible: false
        })
    }

    onRequestClose() {}

    render() {

        let { roomPickerVisible, currentInput } = this.state
        if (!currentInput)
            currentInput = { key: -1, label: 'Unknown' }
        let label = "Current input : " + currentInput.label

        let volume = Math.ceil(this.state.volume * 100) + '%'
        let boost = Math.ceil(this.state.boost * 100) + '%'
        let volumeLabel = (this.state.muted) ? volume + ' (muted)' : volume
        let boostLabel = (this.state.muted) ? boost + ' (muted)' : boost

        let barColor = (this.state.muted) ? "rgba(1, 1, 1, 0.2)" : "#02FF24"
        let barBorderColor = "#222"

        const ModalFilterPickerComponent = Platform.OS === "web" ? ModalFilterPickerWeb : ModalFilterPicker

        return (
            <View style={styles.container} className="option-audio-container-legacy">
                <View style={styles.toolbar}>
                    <View style={styles.dropdown}>
                        <TouchableOpacity style={styles.button}>
                            <Text style={styles.buttonText} onPress={this.showInputList.bind(this)}>{label}</Text>
                        </TouchableOpacity>
                    </View>
                </View>

                <ScrollView style={styles.containerScroll}>
                    <View style={styles.column}>
                        <ModalFilterPickerComponent
                            visible={this.state.inputsListVisible}
                            onSelect={this.onSelect.bind(this)}
                            onCancel={this.onCancel.bind(this)}
                            options={this.state.inputsList}
                            modal={{
                                onRequestClose: this.onRequestClose.bind(this),
                                transparent: true,
                                hardwareAccelerated: true,
                                animationType: 'fade'
                            }}
                            cancelButtonText='Cancel'
                            showFilter={false}
                            title='Select an audio input'
                            noResultsText='No input(s) avaliable'
                        />
                    </View>
                    <View style={[styles.column, { marginTop: utils.moderateScale(10) }]}>
                        <Text style={styles.text}>Volume : {volumeLabel}</Text>
                        <View style={styles.row}>
                            <Button
                                color={barColor}
                                title="-"
                                onPress={this.volumeDown.bind(this)}
                            />
                            <Progress.Bar useNativeDriver={true} color={barColor} borderColor={barBorderColor} borderWidth={this.barBorder} height={this.barHeight} indeterminate={this.state.indeterminate} progress={this.state.volume} width={null} style={styles.progress} borderRadius={0} />
                            <Button
                                color={barColor}
                                title="+"
                                onPress={this.volumeUp.bind(this)}
                            />
                        </View>
                    </View>
                    {/*
                    <View style={[styles.column, { marginTop: utils.moderateScale(10) }]}>
                        <Text style={styles.text}>Boost : {boostLabel}</Text>
                        <View style={styles.row}>
                            <Button
                                color={barColor}
                                title="-"
                                onPress={this.gainDown.bind(this)}
                            />
                            <Progress.Bar useNativeDriver={true} color={barColor} borderColor={barBorderColor} borderWidth={this.barBorder} height={this.barHeight} indeterminate={this.state.indeterminate} progress={this.state.boost} width={null} style={styles.progress} borderRadius={0} />
                            <Button
                                color={barColor}
                                title="+"
                                onPress={this.gainUp.bind(this)}
                            />
                        </View>
                    </View>
                    */}
                    <View style={[styles.column, { marginTop: utils.moderateScale(10) }]}>
                    <Text style={styles.text}>Microphone recording : {!this.state.muted ? "ON" : "OFF"}</Text>
                        <IconedButton
                            image={muteIcon}
                            activeImage={muteActiveIcon}
                            isActive={this.state.muted}
                            onPress={this.toggleMute.bind(this)}
                            canVibrate={true}
                            style={styles.icon}
                        />
                    </View>
                </ScrollView>
            </View>
        )
    }
}

OptionsAudioContainer.contextTypes = {
    webSocketClient: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsAudioContainer)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#262626'
    },
    column: {
        flexDirection: 'column'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: utils.moderateScale(15)
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    toolbar: {
        backgroundColor: '#222222',
        padding: utils.moderateScale(10),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    dropdown: {
        width: '100%'
    },
    progress: {
        flex: 1,
        marginHorizontal: utils.moderateScale(10)
    },
    icon: {
        width: 100,
        height: 100,
        alignSelf: 'center'
    },
    containerScroll: {
        flex: 1,
        padding: utils.moderateScale(10)
    },
})