import { ActionConst } from 'react-native-router-flux';

const initialState = {
  routeName: "Home",
};

export default function routerReducer(state = initialState, action = {}) {
  switch (action.type) {
    // focus action is dispatched when a new screen comes into focus
    case ActionConst.FOCUS:
      return {
        ...state,
        routeName: action.routeName,
      };

    default:
      return state;
  }
}