import React from "react"
import { View, Text, ScrollView, TouchableOpacity, StyleSheet } from "react-native"
import * as Progress from 'react-native-progress';


import * as utils from '../../../utils'

const PropTypes = require('prop-types')

export default class PublishingContainerValidation extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            pickedConnectorType: null,
            connectorName: ''
        }
    }

    cancel() {
        this.props.onCancel()
    }

    renderContent() {
        const content = this.props.content || {}
        if ('validation_data' in content && 'messages' in content['validation_data']) {
            const messages = content['validation_data']['messages']
            return (
                <View>
                    {messages.map((message, index) => {
                        const additionalsStyles = message.style ? message.style.map((styleName) => {
                            return styles[styleName]
                        }) : []

                        return (
                            <Text key={'text_' + index} style={[styles.text, additionalsStyles]}>{message.content}</Text>
                        )
                    })}
                </View>
            )
        }
    }

    renderLoader() {
        const content = this.props.content || {}
        if ('validation_data' in content && content.validation_data.show_waiting_loader) {
            return (
                <View style={{
                    flex: 1,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <Progress.CircleSnail
                        size={utils.getButtonGridUnit()}
                        color={'#02FF24'}
                        thickness={2}
                    />
                </View>
            )
        }
    }

    render() {
        const createDisableStyle = !this.state.connectorName ? { color: '#FFFFFF50' } : {}

        return (
            <View style={styles.container}>
                <View style={[styles.toolbar, {
                    backgroundColor: '#FFF',
                    marginBottom: utils.moderateScale(10)
                }]}>
                    <Text style={[styles.text, styles.centered, {
                        color: '#000',
                        fontWeight: 'bold'
                    }]}>Publishing profile: Validation</Text>
                </View>
                <ScrollView style={[styles.container, styles.formWrapper, {
                    flexShrink: 1
                }]}>
                    {this.renderContent()}
                    {this.renderLoader()}
                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button} onPress={this.cancel.bind(this)}>
                        <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
}

PublishingContainerValidation.contextTypes = {
    webSocketClient: PropTypes.object
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626'
    },
    formWrapper: {
        margin: utils.moderateScale(10),
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    marginTop: {
        marginTop: utils.moderateScale(20),
    },
    centered: {
        textAlign: 'center'
    },
    verticalMargin: {
        marginVertical: utils.moderateScale(20)
    },
    smallVerticalMargin: {
        marginVertical: utils.moderateScale(10)
    },
    primaryColor: {
        color: '#FFF'
    },
    secondaryColor: {
        color: '#00ff24'
    },
    bold: {
        fontWeight: 'bold'
    },
    background: {
        backgroundColor: '#11111150',
        padding: utils.moderateScale(20),
    }
})