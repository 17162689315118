import React from 'react'
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native'

import ModalDropdown from 'react-native-modal-dropdown'
import IconedButton from './IconedButton'

import * as utils from '../utils'

export default class DropdownVod extends React.Component {

    constructor(props, context) {
        super(props, context)
        
        this.state = { selectedItem: {} }
    }

    adjustFrame(style) {
        style.top += utils.moderateScale(20) / 2
        return style
    }

    onSelect(index, selectedItem) {

        if (selectedItem !== this.state.selectedItem.item) {
            this.setState({ 
                selectedItem: {
                    item: selectedItem,
                    rowID: index
                } 
            })
    
            if (this.props.onSelect)
                this.props.onSelect(index, selectedItem)
        }
    }

    renderSeparator(sectionID, rowID, adjacentRowHighlighted) {
        return (
            <View style={styles.modalSeparator} />
        )
    }

    renderRowImage(item, rowID, highlighted) {
        if ('item' in item)
            item = item.item

        if (this.props.renderRowImage && rowID > -1) {
            return (
                <IconedButton
                image={this.getIconFromId(item.provider_id)}
                style={styles.rowImage}
                maxIconSize={utils.moderateScale(20)}
                isDisabled={true}
                />
            )
        }
    }

    getIconFromId(id) {
        switch (id) {
            case 0: 
                return(require("../design/icons/publisher_icons/publisher_ftp.png"))
            case 1: 
                return(require("../design/icons/publisher_icons/publisher_youtube.png"))
            case 2: 
                return(require("../design/icons/publisher_icons/publisher_dropbox.png"))
            case 3: 
                return(require("../design/icons/publisher_icons/publisher_googledrive.png"))
            case 4:
                return(require("../design/icons/publisher_icons/publisher_facebook.png"))
            case 5:
                return(require("../design/icons/params.png"))
            case 6:
                return(require("../design/icons/publisher_icons/publisher_ftp.png"))
            default:
                return(require("../design/icons/publisher_icons/publisher_custom.png"))

        }
    }
    
    renderRow(item, rowID, highlighted) {
        const rowStyle = this.props.renderRowImage ? { flexDirection: 'row' } : {}

        if (item.provider_id === 0 || item.provider_id === 1 || item.provider_id === 2 || item.provider_id === 3 || item.provider_id === 6 || item.provider_id === 7) {
            return (
                <View style={[rowStyle, styles.rowStyle]}>
                    {this.renderRowImage(item, rowID, highlighted)}
                    <Text style={styles.modalDropdownTextStyle}>{this.props.renderRowText(item, rowID, highlighted)}</Text>
                </View>
            )
        } else return(<View></View>)
    }

    renderButtonImage() {
        if (this.state.selectedItem) {
            return this.renderRowImage(this.state.selectedItem, this.state.selectedItem.rowID, true)
        }
    }

    renderButtonText(item) {
        return item.name + '  ▼ '
    }

    select(idx, triggerOnSelect = true) {
        idx = parseInt(idx)
        this.refs.dropdown.select(idx)

        if (idx === -1)
            this.onSelect(-1, {})
        else
            this.onSelect(idx, this.props.options[idx])
    }

    optionsFilter() {
        
    }

    render() {

        const modalStyle = !this.state.selectedItem ? { borderRadius: 30 } : {}
        const containerStyle = this.props.disabled ? { opacity: 0.35 } : {}
        return (
            <View style={[styles.containerStyle, containerStyle]}>
                {this.renderButtonImage()}
                <ModalDropdown
                    defaultIndex={this.props.defaultIndex || -1}
                    defaultValue={this.props.defaultValue || "No selection"}
                    animated={false}
                    showsVerticalScrollIndicator={true}
                    options={this.props.options}
                    style={[styles.modalStyle, modalStyle]}
                    textStyle={styles.modalTextStyle}
                    dropdownStyle={styles.modalDropdownStyle}
                    renderRow={this.renderRow.bind(this)}
                    renderSeparator={this.renderSeparator.bind(this)}
                    renderButtonText={this.renderButtonText.bind(this)}
                    adjustFrame={this.adjustFrame.bind(this)}
                    onSelect={this.onSelect.bind(this)}
                    disabled={this.props.disabled || false}
                    ref={"dropdown"}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({

    containerStyle: {
        backgroundColor: 'gray',
        height: utils.moderateScale(30),
        justifyContent: "center",
        borderRadius: 30,
        flexDirection: 'row',
        paddingLeft: utils.moderateScale(10),
    },

    modalStyle: {
        paddingHorizontal: utils.moderateScale(5),
        justifyContent: "center"
    },

    modalTextStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        fontSize: utils.moderateScale(10),
    },
    modalTextStyleDisabled: {

    },

    rowStyle: {
        padding: utils.moderateScale(5),
    },

    modalSeparator: {
        height: StyleSheet.hairlineWidth,
        backgroundColor: '#444444',
    },
    
    modalDropdownStyle: {
        backgroundColor: '#333333',
        borderColor: '#444444',
        borderRadius: 15
    },
    modalDropdownTextStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        fontSize: utils.moderateScale(10),
        padding: utils.moderateScale(5),
    },

    rowImage: {
        paddingRight: utils.moderateScale(5),
        borderRightColor: '#444444',
        borderRightWidth: StyleSheet.hairlineWidth,
        maxHeight: utils.moderateScale(20),
        alignSelf: "center",
        padding: 0,
        overflow: 'hidden',
    }
})