import React from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Dimensions, FlatList, ScrollView } from 'react-native'
import IconedButton from '../../../components/IconedButton'

import * as utils from '../../../utils'

const PropTypes = require('prop-types')
var invalidIcon = require('../../../design/icons/publisher_invalid.png')
var validIcon = require('../../../design/icons/publisher_valid.png')

class FacebookContainer extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            name: ''
        }
    }

    renderFacebookListPageItem(page) {
        const _page = page.item
        const source = require("../../../design/icons/publisher_icons/publisher_facebook.png")

        const backgroundColor = '#333333'
        const isValid = true

        let validationStateIcon = validIcon
        if (isValid === undefined) {
            validationStateIcon = unknownIcon
        } else if (!isValid) {
            validationStateIcon = invalidIcon
        }

        const padding = utils.moderateScale(1)

        return (
            <TouchableOpacity style={[styles.formWrapper, styles.row, {
                paddingVertical: utils.moderateScale(5),
                paddingHorizontal: utils.moderateScale(5),
                height: utils.moderateScale(50),
            }, backgroundColor]}
                    onPress={() => this.selectedPage(_page)}>
                    <View style={styles.row}>
                        <IconedButton
                            image={source}
                            style={[styles.rowImage, { marginRight: utils.moderateScale(10) }]}
                            maxIconSize={utils.moderateScale(30)}
                            aspectRatio={1}
                            isDisabled={true}
                        />
                        <Text style={[styles.text, {
                            paddingTop: 0
                        }]}>{_page.name}</Text>
                    </View> 
            </TouchableOpacity>
        )
    }

    connectorSelected() {
        this.context.webSocketClient.publication.setLiveData({ provider_id: 4, user_id: this.props.selectedConnector.user_id,  page: false, auto: this.props.liveOnOff })
        this.props.onCancel()
    }

    selectedPage(pageData) {
        this.context.webSocketClient.publication.setLiveData({ provider_id: 4, user_id: this.props.selectedConnector.user_id,  page: true, page_token: pageData.access_token, page_id: pageData.id, auto: this.props.liveOnOff})
        this.props.onCancel()
    }

    stateWaiter() {
        if (this.props.facebookPagesData !== null) {
            return(
                <FlatList
                    data={this.props.facebookPagesData.data}
                    renderItem={this.renderFacebookListPageItem.bind(this)}
                    keyExtractor={(item) => '' + item.id}
                />
            )
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <Text style={{ fontSize: utils.moderateScale(13), color: '#FFFFFF', marginTop: utils.moderateScale(10), marginLeft: utils.moderateScale(10)}}>Enter a name for the new profile :</Text>
                <View style={[styles.container, styles.formWrapper, { flex: 1}]} >
                    <View style={{ flexDirection: 'row' }}>
                        {this.stateWaiter()}
                    </View>
                </View>
                <TouchableOpacity style={styles.button} onPress={this.connectorSelected.bind(this)}>
                        <Text style={styles.buttonText}>Stream on your facebook account</Text>
                    </TouchableOpacity>
                <View style={styles.toolbar}>
                    <TouchableOpacity style={styles.button} onPress={this.props.onCancel.bind(this)}>
                        <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
}

FacebookContainer.contextTypes = {
    webSocketClient: PropTypes.object
};

const styles = StyleSheet.create({
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    container: {
        flex: 1,
        backgroundColor: '#262626',
    },
    formWrapper: {
        margin: utils.moderateScale(10),
        flexShrink: 1
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
        paddingTop: utils.moderateScale(10)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    rowImage: {
        paddingRight: utils.moderateScale(5),
        maxHeight: utils.moderateScale(30),
        alignSelf: "center",
        padding: 0,
    },
})

export default FacebookContainer