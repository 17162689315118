import * as types from '../constants/system'

/** public **/
export function setCanStartUpdate(canStartUpdate) {
    return {
        type: types.CAN_START_UPDATE,
        canStartUpdate
    }
}

export function setWebsocketConnected(websocketConnected) {
    return {
        type: types.WEBSOCKET_CONNECTED,
        websocketConnected
    }
}