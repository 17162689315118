import React from 'react'
import { View, Text, Button, TouchableOpacity, StyleSheet } from 'react-native'

import { Actions } from "react-native-router-flux";

import IconedButton from '../../../components/IconedButton'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../../actions/globals'

const PropTypes = require('prop-types')

var leftIcon = require('./../../../design/icons/arrow-left.png')
var rightIcon = require('./../../../design/icons/arrow-right.png')
var upIcon = require('./../../../design/icons/arrow-up.png')
var downIcon = require('./../../../design/icons/arrow-down.png')

var zoomInIcon = require('./../../../design/icons/zoom-in.png')
var zoomOutIcon = require('./../../../design/icons/zoom-out.png')

import * as utils from '../../../utils'

class JoystickContainer extends React.Component {

    mount = false

    constructor(props, context) {
        super(props, context)
    }

    componentDidMount() {
        this.mount = true
    }

    componentWillUnmount() {
        this.mount = false
    }

    leftPressed() {
        this.context.webSocketClient.camera.startLeft()
    }

    rightPressed() {
        this.context.webSocketClient.camera.startRight()
    }

    upPressed() {
        this.context.webSocketClient.camera.startUp()
    }

    downPressed() {
        this.context.webSocketClient.camera.startDown()
    }

    release() {
        this.context.webSocketClient.camera.stop()
    }

    zoomIn() {
        this.context.webSocketClient.camera.zoomIn()
    }

    zoomOut() {
        this.context.webSocketClient.camera.zoomOut()
    }

    zoomStop() {
        this.context.webSocketClient.camera.zoomStop()
    }

    render() {
        const { setTrackingActive } = this.props.cameraState

        return (
            <View
                style={StyleSheet.flatten([styles.container, this.props.style, { opacity: this.props.opacity }])}
                pointerEvents='box-none'
                className="joystick-container"
            >
                <View style={{
                    alignItems: 'center',
                    bottom: -utils.getButtonGridUnit() * 3,
                    left: -utils.getButtonGridUnit() * 1.5 + utils.getButtonGridUnit() - utils.getButtonGridUnit() * 0.3
                }}>
                    <IconedButton
                        image={zoomInIcon}
                        onPressIn={this.zoomIn.bind(this)}
                        onPressOut={this.zoomStop.bind(this)}
                        style={styles.icon}
                        isDisabled={setTrackingActive}
                    />
                </View>
                <View style={{
                    alignItems: 'center',
                    bottom: -utils.getButtonGridUnit() * 3,
                    right: -utils.getButtonGridUnit() * 1.5 + utils.getButtonGridUnit() - utils.getButtonGridUnit() * 0.3
                }}>
                    <IconedButton
                        image={zoomOutIcon}
                        onPressIn={this.zoomOut.bind(this)}
                        onPressOut={this.zoomStop.bind(this)}
                        style={styles.icon}
                        isDisabled={setTrackingActive}
                    />
                </View>
                <View style={{
                    alignItems: 'center',
                    left: -utils.getButtonGridUnit(),
                    top: utils.getButtonGridUnit()
                }}>
                    <IconedButton
                        image={leftIcon}
                        onPressIn={this.leftPressed.bind(this)}
                        onPressOut={this.release.bind(this)}
                        style={styles.icon}
                        isDisabled={this.props.isTracking || setTrackingActive}
                    />
                </View>
                <View style={{
                    alignItems: 'center',
                    right: -utils.getButtonGridUnit(),
                    top: utils.getButtonGridUnit()
                }}>
                    <IconedButton
                        image={rightIcon}
                        onPressIn={this.rightPressed.bind(this)}
                        onPressOut={this.release.bind(this)}
                        style={styles.icon}
                        isDisabled={this.props.isTracking || setTrackingActive}
                    />
                </View>
                <View style={{
                    alignItems: 'center',
                    top: 0
                }}>
                    <IconedButton
                        image={upIcon}
                        onPressIn={this.upPressed.bind(this)}
                        onPressOut={this.release.bind(this)}
                        style={styles.icon}
                        isDisabled={this.props.isTracking || setTrackingActive}
                    />
                </View>
                <View style={{
                    alignItems: 'center',
                    bottom: -utils.getButtonGridUnit() * 2
                }}>
                    <IconedButton
                        image={downIcon}
                        onPressIn={this.downPressed.bind(this)}
                        onPressOut={this.release.bind(this)}
                        style={styles.icon}
                        isDisabled={this.props.isTracking || setTrackingActive}
                    />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        height: utils.getButtonGridUnit() * 4,
        width: utils.getButtonGridUnit() * 3
    },
    icon: {
        position: 'absolute',
        padding: utils.moderateScale(utils.getButtonGridUnit() / 8),
        backgroundColor: '#222222',
        borderRadius: utils.getButtonGridUnit(),
        width: utils.getButtonGridUnit(),
        height: utils.getButtonGridUnit()
    }
})

JoystickContainer.contextTypes = {
    webSocketClient: PropTypes.object
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer,
        cameraState: state.cameraReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalActions: bindActionCreators(globalActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(JoystickContainer)