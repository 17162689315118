import * as types from '../constants/mixer'

/** public **/
export function switchSceneSpeaker() {
    return {
        type: types.SCENE_SPEAKER
    }
}
export function switchSceneScreen() {
    return {
        type: types.SCENE_SCREEN
    }
}
export function switchSceneSpeakerScreen() {
    return {
        type: types.SCENE_SPEAKER_SCREEN
    }
}
export function switchSceneFull() {
    return {
        type: types.SCENE_FULL
    }
}
export function setWatermarkEnabledState(watermarkEnabled) {
    return {
        type: types.WATERMARK_ENABLED_STATE,
        watermarkEnabled
    }
}