import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import * as Progress from 'react-native-progress';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as utils from '../../../utils'

const PropTypes = require('prop-types')

const barHeight = utils.moderateScale(5)

class DiskSpaceRemaining extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            progress: 0
        }
    }

    componentDidUpdate() {
        this.redrawProgress()
    }

    redrawProgress() {
        const { freeSpace, totalSpace } = this.props

        if (freeSpace !== undefined && totalSpace !== undefined) {
            const usedSpace = totalSpace - freeSpace
            const progress = (usedSpace / this.props.totalSpace) || 0

            if (this.state.progress !== progress) {
                this.setState({ progress })
            }
        }
    }

    onLayout() {
        this.setState({ progress: this.state.progress - 0.001 })
        this.redrawProgress()
    }

    render() {
        const moPerHour = this.props.capabilitiesState.averageMoPerHour
        const moPerSec = moPerHour / 3600
        const remainingTimeSec = (this.props.freeSpace / moPerSec) || 0

        const visibleProgress = 1 - this.state.progress

        let progressColor = "#02FF24"
        if (visibleProgress <= 0.05) {
            progressColor = "#FF0000"
        } else if (visibleProgress <= 0.2) {
            progressColor = "rgb(255, 136, 36)"
        }

        return (
            <View>
                <View
                    style={styles.container}
                    onLayout={this.onLayout.bind(this)}
                >
                    <View style={styles.toolbar}>
                        <Text style={styles.buttonText}>About
                        <Text style={{ color: progressColor }}> {utils.getVideoDuration(
                            Math.round(remainingTimeSec) * 1000, false
                        )}</Text>
                            recording time left
                    </Text>
                    </View>
                </View>
                <View style={styles.progressContainer}>
                    <Progress.Bar
                        useNativeDriver={true}
                        color={progressColor}
                        borderWidth={0}
                        height={barHeight}
                        progress={visibleProgress}
                        width={utils.getSize().width}
                        style={styles.progress}
                        borderRadius={0}
                        unfilledColor={"#262626"}
                    />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    sectionHeader: {
        paddingHorizontal: utils.moderateScale(10),
        paddingVertical: utils.moderateScale(15),
        backgroundColor: '#222222',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'column',
        alignContent: 'center',
    },
    container: {
        justifyContent: 'space-between',
        backgroundColor: '#222222',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    progressContainer: {
        justifyContent: 'space-between',
        backgroundColor: '#222222',
        justifyContent: 'center',
        flexDirection: 'column',
        height: barHeight
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: 10,
        fontSize: utils.moderateScale(12)
    },
    text: {
        color: '#FFFFFF',
        position: 'absolute',
        left: 0,
        right: 0,
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)'
    },
    progress: {
        flex: 1,
        position: 'absolute',
    },
})

function mapStateToProps(state) {
    return {
        capabilitiesState: state.capabilitiesReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiskSpaceRemaining)