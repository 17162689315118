import React from 'react'
import { LayoutAnimation } from 'react-native'

import { Actions } from "../../navigation/Navigator";

import MainScreenFloatingMenu from '../../components/mainScreen/MainScreenFloatingMenu'

import { bindActionCreators } from 'redux'
import * as mixerActions from '../../actions/mixer'
import * as cameraActions from '../../actions/camera'
import * as globalsActions from '../../actions/globals'
import * as videoActions from '../../actions/video'

import * as videoConstants from '../../constants/video'

import { connect } from 'react-redux'
import * as utils from '../../utils'

import Messenger from '../../components/Messenger'

const PropTypes = require('prop-types')


class MainScreenFloatingMenuContainer extends React.Component {

    timeoutTimer = undefined;

    constructor(props, context) {
        super(props, context)

        this.state = {
            buttonOffsetX: 0,
            buttonOffsetY: 0,
            menuOpened: false,
            hasNetAccess: false,
            liveActive: false
        }

        this.context.webSocketClient.video.onCurrentSession(
            this.onCurrentSession, this
        )

        this.context.webSocketClient.context.onUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.publication.onGetLiveData(
            this.onGetLiveData, this
        )
        
        this.context.webSocketClient.video.currentSession()
        this.context.webSocketClient.publication.getLiveData()
    }

    componentWillUnmount() {
        this.context.webSocketClient.video.offCurrentSession(
            this.onCurrentSession
        )

        this.context.webSocketClient.context.offUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.publication.offGetLiveData(
            this.onGetLiveData, this
        )
    }

    startClosingMenuTimer() {
        if (this.timeoutTimer != undefined) {
            clearTimeout(this.timeoutTimer)
        }

        this.timeoutTimer = setTimeout( () => {
            this.setState({ menuOpened: false })
        }, 3000)
    }

    onCurrentSession(session) {
        this.setState({ sessionState: session.state })
    }

    onContextUpdated(context) {
        const { is_connected } = context.network
        this.setState({
            hasNetAccess: is_connected
        })
    }

    onGetLiveData(params) {
        this.setState({
            liveActive: params.auto
        })
    }

    resizeButtons() {
        this.updateButtonsPositions()
    }

    updateButtonsPositions() {
        this.setState({
            buttonOffsetX: this.state.menuOpened ? this.props.height : 0,
            buttonOffsetY: this.state.menuOpened ? this.props.height + 10 : 0
        })
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.state.menuOpened !== prevStates.menuOpened) {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
            this.updateButtonsPositions()
        }

        if (this.props.height !== prevProps.height) {
            this.updateButtonsPositions()
        }
    }

    onPressCenterButton() {
        this.startClosingMenuTimer()
        this.setState({ menuOpened: !this.state.menuOpened })
    }

    onPressRecButton() {

        this.context.webSocketClient.context.get()
        this.context.webSocketClient.video.currentSession()
        
        this.startClosingMenuTimer()
        if (this.state.sessionState === videoConstants.SESSION_STATES.PENDING) {
            
            if (!this.state.liveActive || (this.state.liveActive && this.state.hasNetAccess))
                this.startRecord()
            else {
                Messenger.prompt(
                    'Connection required', 
                    "The live feature won't be available because the Kast-Box has not internet connection.\nDo you want to start recording anyway ?",
                    [{ text: 'Cancel', onPress: () => { }, style: 'cancel' },{
                        text: 'Start record', onPress: this.startRecord.bind(this)
                    }],
                    { cancelable: true }
                )
            }
        
        } else if (this.state.sessionState === videoConstants.SESSION_STATES.LIVE)
            this.context.webSocketClient.video.stopRecord()
    }
    
    startRecord() {
        this.context.webSocketClient.video.startRecord()
    }

    onPressParamButton() {
        this.startClosingMenuTimer()
        Actions.OptionsPanelContainer()
    }

    onPressVideoButton() {
        this.startClosingMenuTimer()
        Actions.videoOverlay()
    }

    render() {
        return (
            <MainScreenFloatingMenu
                buttonSize={this.props.height}

                onPressCenterButton={this.onPressCenterButton.bind(this)}
                onPressRecButton={this.onPressRecButton.bind(this)}
                onPressParamButton={this.onPressParamButton.bind(this)}
                onPressVideoButton={this.onPressVideoButton.bind(this)}
                startClosingMenuTimer={this.startClosingMenuTimer.bind(this)}

                sessionState={this.state.sessionState}

                buttonOffsetX={this.state.buttonOffsetX}
                buttonOffsetY={this.state.buttonOffsetY}

                resizeButtons={this.resizeButtons.bind(this)}

                isVisible = {this.props.isVisible}
                style={[this.props.style, {borderColor: '#FF0000', borderWidth: 0 }] || {}}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        mixerState: state.mixerReducer,
        cameraState: state.cameraReducer,
        globalsState: state.globalsReducer,
        videoState: state.videoReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        mixerActions: bindActionCreators(mixerActions, dispatch),
        cameraActions: bindActionCreators(cameraActions, dispatch),
        globalsAction: bindActionCreators(globalsActions, dispatch),
        videoActions: bindActionCreators(videoActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreenFloatingMenuContainer)

MainScreenFloatingMenuContainer.contextTypes = {
    webSocketClient: PropTypes.object
};