import React from 'react'
import { View, Text, StyleSheet, KeyboardAvoidingView, LayoutAnimation, TouchableOpacity, FlatList, ScrollView, Platform } from 'react-native'
import { Switch } from 'react-native-switch'

import PublishingContainerCreator from "./PublishingContainerCreator"
import PublishingContainerValidation from "./PublishingContainerValidation"
import EditContainer from "./EditContainer"
import ModalContainer from "../../ModalContainer"
import NamingContainer from "./NamingContainer"
import FacebookContainer from "./FacebookContainer"

import * as utils from '../../../utils'

import IconedButton from '../../../components/IconedButton'
import Messenger from '../../../components/Messenger'
import { Actions } from '../../../navigation/Navigator'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../../actions/globals'

import DropdownLive from '../../../components/DropdownLive'
import DropdownVod from '../../../components/DropdownVod'
import DropdownPodUsers from '../../../components/DropdownPodUsers'
import Button from 'react-native-button'

const PropTypes = require('prop-types')

var paramsIcon = require('../../../design/icons/params.png')
var trashIcon = require('../../../design/icons/trash.png')
var editIcon = require('../../../design/icons/editIcon.png')
var validateIcon = require('../../../design/icons/validate.png')

var invalidIcon = require('../../../design/icons/publisher_invalid.png')
var validIcon = require('../../../design/icons/publisher_valid.png')
var unknownIcon = require('../../../design/icons/publisher_unknown.png')

let instance = undefined
class OptionsPublishingContainer extends React.Component {
    mount = false

    static getPopAllOnClose = function () {
        if (instance) {
            const { popAllOnClose } = instance.props.globalState
            instance.props.globalActions.setPopAllOnClose(false)
            return popAllOnClose
        }

        return false
    }

    constructor(props, context) {
        super(props, context)
        instance = this

        this.state = {
            autoLive: false,
            connectors: [],
            podUsers: [],
            activeLiveConnector: null,
            activeVodConnector: null,
            activePodUser: null,
            activePage: null,
            connectorTypes: [
                /*{name: "fbk", verbose_name: "Facebook", providerId: 4, iconPath: "publisher_facebook.png", providerType: 1},
                {name: "ytb", verbose_name: "Youtube", providerId: 1, iconPath: "publisher_youtube.png", providerType: 1},
                {name: "dbx", verbose_name: "Dropbox", providerId: 2, iconPath: "publisher_dropbox.png", providerType: 1},
                {name: "drv", verbose_name: "Drive", providerId: 3, iconPath: "publisher_googledrive.png", providerType: 1},*/
                {name: "sftp", verbose_name: "SFTP", providerId: 0, iconPath: "publisher_ftp.png", providerType: 0},
                {name: "ftp", verbose_name: "FTP", providerId: 6, iconPath: "publisher_ftp.png", providerType: 0},
                {name: "rtmp", verbose_name: "RTMP", providerId: 5, iconPath: "publisher_rtmp.png", providerType: 0}
            ],
            creatorVisible: false,
            validationVisible: false,
            namingVisible: false,
            showLiveDropdownIcon: true,
            validationData: null,
            processing: false,
            hasNetAccess: false,
            code : "",
            selectedProvider : null,
            liveOnOff: false,
            autoUpload: false,
            autoRemove: false,
            pageOnOff: null,
            editVisible: false,
            editConnector: [],
            facebookSelector: false,
            facebookPagesData: null,
            pageData: {page_token: null, page_id: null},
        }

        this.context.webSocketClient.context.onUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.publication.onSave(
            this.onSave, this
        )

        this.context.webSocketClient.publication.onGetConnectors(
            this.onGetConnectors, this
        )

        this.context.webSocketClient.publication.onGetConnectorTypes(
            this.onGetConnectorTypes, this
        )

        this.context.webSocketClient.publication.onRemove(
            this.onRemove, this
        )

        this.context.webSocketClient.publication.onGetLiveData(
            this.onGetLiveData, this
        )

        this.context.webSocketClient.publication.onGetUploadData(
            this.onGetUploadData, this
        )

        this.context.webSocketClient.connection.onOpen(
            this.onConnectionOpened, this
        )

        this.context.webSocketClient.publication.onGetIsValid(
            this.onGetIsValid, this
        )

        this.context.webSocketClient.publication.onValidationData(
            this.onValidationData, this
        )

        this.context.webSocketClient.publication.onGetUsers(
            this.onGetUsers, this
        )

        this.context.webSocketClient.publication.onGetUser(
            this.onGetUser, this
        )

        this.context.webSocketClient.publication.onGetUploadUser(
            this.onGetUploadUser, this
        )

        this.context.webSocketClient.publication.onGetFacebookPages(
            this.onGetFacebookPages, this
        )

        this.context.webSocketClient.publication.onGetPodUsers(
            this.onGetPodUsers, this
        )

        this.context.webSocketClient.context.get()
    }

    componentWillUnmount() {


        this.mount = false

        this.context.webSocketClient.context.offUpdated(
            this.onContextUpdated, this
        )

        this.context.webSocketClient.publication.offSave(
            this.onSave
        )
        this.context.webSocketClient.publication.offGetConnectors(
            this.onGetConnectors
        )

        this.context.webSocketClient.publication.offGetConnectorTypes(
            this.onGetConnectorTypes
        )

        this.context.webSocketClient.publication.offRemove(
            this.onRemove
        )


        this.context.webSocketClient.publication.offGetLiveData(
            this.onGetLiveData
        )

        this.context.webSocketClient.publication.offGetUploadData(
            this.onGetUploadData
        )

        this.context.webSocketClient.publication.offGetIsValid(
            this.onGetIsValid
        )

        this.context.webSocketClient.publication.offValidationData(
            this.onValidationData
        )
    }

    componentDidMount() {
        this.mount = true
        this.context.webSocketClient.publication.getUsers()
        this.context.webSocketClient.publication.getPodUsers()
        this.context.webSocketClient.publication.getLiveData()
        this.context.webSocketClient.publication.getConnectorTypes()
    }

    onGetFacebookPages(data) {
        let fbData = JSON.parse(data)
        this.setState({facebookPagesData: fbData})
    }

    onGetUser(data) {
        this.setState({
            activeLiveConnector: data
        })
    }

    onGetUploadUser(data) {
        this.setState({
            activeVodConnector: data
        })
    }

    onGetUsers(data) {
        this.setState({
            connectors: data
        })
    }

    onContextUpdated(context) {
        const { is_connected } = context.network
        this.setState({ hasNetAccess: is_connected })
    }

    onSave(connector) {
        if (this.state.creatorVisible) {
            if (Object.keys(connector.fields).length > 1) {
                const toPush = { 'connector': connector, }
                if (Platform.OS !== "web")
                    toPush['deletePressed'] = this.deletePressed.bind(this)
                else 
                    toPush['profileId'] = connector.id

                Actions.PublishingContainerEditor(toPush)
            }
            this.setState({ creatorVisible: false })
        }
        this.context.webSocketClient.publication.getConnectors()
    }

    onGetConnectorTypes(_connectorTypes) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        let connectorTypes = []


        for (const connectorTypeName in _connectorTypes) {
            const connectorType = _connectorTypes[connectorTypeName]
            connectorType.iconPath = connectorType.icon
            connectorTypes.push(connectorType)
        }

        connectorTypes.sort((a, b) => {
            const x = a.verbose_name.toLowerCase();
            const y = b.verbose_name.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        })
        this.setState({ connectorTypes }, () => {
            this.context.webSocketClient.publication.getConnectors()
        })
    }

    getConnectorIcon(connector) {
        for (const connectorTypeI in this.state.connectorTypes) {
            let connectorType = this.state.connectorTypes[connectorTypeI]
            if (connectorType.name === connector.name) {
                return utils.getPublishingIcon(connectorType.iconPath)
            }
        }
    }

    onGetConnectors(connectors) {
        let formatedConnectors = []

        for (const connectordId in connectors) {
            const connector = connectors[connectordId]
            connector.icon = this.getConnectorIcon(connector)
            formatedConnectors.push(connector)
        }

        this.setState({
            connectors: formatedConnectors,
        }, () => {
            for (let i = 0; i < this.state.connectors.length; i++) {
                const connector = this.state.connectors[i]
                this.context.webSocketClient.publication.getIsValid({
                    'service': connector
                })

                if (i === this.state.connectors.length - 1) {
                    this.context.webSocketClient.publication.getLiveData()
                }
            }
        })
    }

    onLiveConnectorSelect(_, connector) {
        if (connector) {
            this.setState({
                activeLiveConnector: connector,
            })
            if (connector.provider_id != 4) {
                this.context.webSocketClient.publication.setLiveData({
                    provider_id: connector.provider_id,
                    user_id: connector.user_id,
                    page: false,
                    auto: this.state.liveOnOff
                })
            } else {
                this.context.webSocketClient.publication.getFacebookPages({
                    user_id: connector.user_id
                })
                this.setState({
                    facebookSelector: true
                })
            }
        }
    }

    onGetPodUsers = (usersRow) => {
        let users = JSON.parse(usersRow)
        console.log("USERS", users)
        if ('error' in users) {
            this.setState({
                podUsers: -1
            })
        }else if ('results' in users) {
            this.setState({
                podUsers: users.results
            })
        }
        this.context.webSocketClient.publication.getUploadData()
    }

    onVodConnectorSelect(_, connector) {
        if (connector) {
            this.setState({
                activeVodConnector: connector,
            })
            if (connector.provider_id !== 7 || this.state.activePodUser != null) {
                this.context.webSocketClient.publication.setUploadData({
                    provider_id: connector.provider_id,
                    user_id: connector.user_id,
                    pod_user: this.state.activePodUser,
                    auto: this.state.autoUpload,
                    remove: this.state.autoUpload ? this.state.autoRemove : false
                })
            }
        }
    }

    onGetLiveData(liveData) {
        this.context.webSocketClient.publication.getUser({user_id: liveData.data.user_id})
        if (liveData.data.page == false) {
            this.setState({
                liveOnOff: liveData.auto,
                pageOnOff: liveData.data.page,
            })
        } else if  (liveData.data.page == true) {
            this.setState({
                liveOnOff: liveData.auto,
                pageData: { page_token: liveData.data.page_token, page_id: liveData.data.page_id}
            })
        }

        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        let liveConnectors = this.getLiveConnectors().filter((connector) => {
            return connector && connector.isValid
        })

        const activeConnector = liveData.data
        for (const i in liveConnectors) {
            const _connector = liveConnectors[i]

            if (_connector.id == activeConnector.id) {
                this.setState({
                    activeConnector: _connector
                }, () => {
                    this.refs.liveDropdown.select(i, false)
                })
            }
        }
    }

    onGetUploadData(vodData) {
        this.context.webSocketClient.publication.getUploadUser({user_id: vodData.provider_id === 7 ? -1 : vodData.user_id})
        this.setState({
            autoUpload: vodData.auto,
            autoRemove: vodData.remove
        })

        console.log(vodData)
        if (vodData.provider_id === 7) {
            console.log(this.state.podUsers)
            if (this.state.podUsers !== -1) {
                this.state.podUsers.forEach((user) => {
                    if (user.id === vodData.user_id) {
                        this.setState({ activePodUser: user })
                    }
                })
            }

        }

        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        let vodConnectors = this.getLiveConnectors().filter((connector) => {
            return connector && connector.isValid
        })

        console.log(vodConnectors)
        const activeConnector = vodData
        for (const i in vodConnectors) {
            const _connector = vodConnectors[i]

            if (_connector.id == (provider_id === 7 ? -1 : activeConnector.id)) {
                this.setState({
                    activeVodConnector: _connector
                }, () => {
                    this.refs.vodDropdown.select(i, false)
                })
            }
        }
    }



    onConnectionOpened() {
        this.context.webSocketClient.publication.getConnectorTypes()
        this.context.webSocketClient.publication.getConnectors()
    }

    onGetIsValid(isValidData) {
        if ('is_valid' in isValidData && 'service' in isValidData) {
            const isValid = isValidData.is_valid
            const _connector = isValidData.service

            let connectors = [... this.state.connectors]

            for (let i = 0; i < connectors.length; i++) {
                const connector = connectors[i]
                if (connector.id == _connector.id) {
                    connector.isValid = isValid
                    break
                }
            }

            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)

            const validationData = this.state.validationData
            let mustCloseValidationModal = false

            if (isValid && validationData) {
                if ('service' in validationData && validationData.service.id == isValidData.service.id) {
                    mustCloseValidationModal = true
                }
            }

            this.setState({ connectors: [...connectors], validationData }, () => {
                if (mustCloseValidationModal) {
                    setTimeout(() => {
                        this.setState({
                            validationVisible: false,
                            validationData: null
                        })
                    }, 2000)
                }
            })
        }
    }

    onValidationData(validationData) {
        this.setState({
            validationVisible: true,
            validationData
        })
    }

    /*onRemove(_connector) {
        let connectors = [... this.state.connectors]

        for (let i = connectors.length - 1; i >= 0; i--) {
            const connector = connectors[i]
            if (connector.id == _connector.id) {
                connectors.splice(i, 1)
                break
            }
        }

        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        this.setState({
            connectors
        }, () => {
            if (this.state.activeLiveConnector && this.state.activeLiveConnector.id == _connector.id) {
                this.refs.liveDropdown.select(-1)
                this.setState({
                    activeLiveConnector: null
                })
            }
        })
    }*/

    getLiveConnectors() {
        return this.state.connectors.filter((connector) => {
            return 1 > -1
        })
    }

    createNewConnector() {
        this.setState({
            creatorVisible: true
        })
    }

    namingNewConnector = (code, providerId) => {
        this.setState({
            namingVisible: true,
            code: code,
            selectedProvider: providerId
        })
    }

    onCancelNamingModal() {
        this.setState({
            namingVisible: false,
            code: "",
            selectedProvider: null
        })
        this.context.webSocketClient.publication.getUsers()
    }

    renderConnectorSelectText(connector) {
        return connector.name
    }

    editPressed(connector) {
        this.setState({
            editVisible: true,
            editConnector: connector
        })
    }

    deletePressed(connector/*, onRemovePressed*/) {
        Messenger.prompt(
            'Confirmation required',
            "Are you sure your want to remove the following publishing profile : " + connector.name + " ?",
            [{ text: 'Cancel', onPress: () => { }, style: 'cancel' }, {
                text: 'Remove', onPress: () => {
                    this.context.webSocketClient.publication.removeUser({
                        user_id: connector.user_id
                    })
                    this.context.webSocketClient.publication.getUsers()
                    /*if (onRemovePressed)
                        onRemovePressed()*/
                }
            }],
            { cancelable: true }
        )
    }

    validatePressed(connector) {
        if (this.state.hasNetAccess) {
            this.context.webSocketClient.publication.validate({
                'service': connector
            })
        } else {
            Messenger.error("You need to have an internet access to validate this profile")
        }
    }

    renderEditButton(connector) {
        if (connector.provider_id == 0 || connector.provider_id == 5 || connector.provider_id == 6 || connector.provider_id === 7) {
            return (
                <IconedButton
                    key={'edit_' + this.state.connectors.indexOf(connector)}
                    image={editIcon}
                    style={[styles.rowImage, { zIndex: 666 }]}
                    maxIconSize={utils.moderateScale(30)}
                    onPress={() => this.editPressed(connector)}
                    aspectRatio={1}
                />
            )
        }
    }

    renderDeleteButton(connector) {
        if (connector && connector.provider_id !== 7) {
            return (
                <IconedButton
                    key={'delete_' + this.state.connectors.indexOf(connector)}
                    image={trashIcon}
                    style={[styles.rowImage, { zIndex: 666 }]}
                    maxIconSize={utils.moderateScale(30)}
                    onPress={() => this.deletePressed(connector)}
                    aspectRatio={1}
                />
            )
        }
    }

    renderValidateButton(connector) {
        if (connector && !connector.isValid) {
            return (
                <Button
                    key={'validate_' + this.state.connectors.indexOf(connector)}
                    containerStyle={styles.buttonContainer}
                    onPress={() => this.validatePressed(connector)}
                    style={[styles.buttonText2, {
                        paddingHorizontal: utils.moderateScale(utils.getButtonGridUnit() / 4),
                        margin: 0
                    }]}
                    _
                >
                    <IconedButton
                        image={validateIcon}
                        aspectRatio={1}
                        disabled={true}
                        style={[styles.rowImage, {
                            right: - utils.moderateScale(15)
                        }]}
                    />Validation
                </Button>
            )
        }
    }

    upLive() {
        if (this.state.liveOnOff == true && this.state.pageOnOff == true) {
            this.context.webSocketClient.publication.setLiveData({ provider_id: this.state.activeLiveConnector.provider_id, user_id: this.state.activeLiveConnector.user_id,  page: true, page_token: this.state.pageData.page_token, page_id: this.state.pageData.page_id, auto: false })
            this.setState({
                liveOnOff: false
            })
        } else if (this.state.liveOnOff == false && this.state.pageOnOff == true) {
            this.context.webSocketClient.publication.setLiveData({ provider_id: this.state.activeLiveConnector.provider_id, user_id: this.state.activeLiveConnector.user_id,  page: true, page_token: this.state.pageData.page_token, page_id: this.state.pageData.page_id, auto: true })
            this.setState({
                liveOnOff: true
            })
        } else if (this.state.liveOnOff == true && this.state.pageOnOff == false) {
            this.context.webSocketClient.publication.setLiveData({ provider_id: this.state.activeLiveConnector.provider_id, user_id: this.state.activeLiveConnector.user_id,  page: false, auto: false })
            this.setState({
                liveOnOff: false
            })

        } else if (this.state.liveOnOff == false && this.state.pageOnOff == false) {
            this.context.webSocketClient.publication.setLiveData({ provider_id: this.state.activeLiveConnector.provider_id, user_id: this.state.activeLiveConnector.user_id,  page: false, auto: true })
            this.setState({
                liveOnOff: true
            })
        }
    }

    upRemove() {
        this.context.webSocketClient.publication.setUploadData({ provider_id: this.state.activeVodConnector.provider_id, user_id: this.state.activeVodConnector.user_id, pod_user: this.state.activePodUser === null ? -1 : this.state.activePodUser.id, auto: true, remove: !this.state.autoRemove })
        this.setState({
            autoRemove: !this.state.autoRemove
        })
    }

    upVod() {
        if (this.state.autoUpload == true) {
            this.context.webSocketClient.publication.setUploadData({ provider_id: this.state.activeVodConnector.provider_id, user_id: this.state.activeVodConnector.user_id, pod_user: this.state.activePodUser === null ? -1 : this.state.activePodUser.id, auto: false, remove: false })
            this.setState({
                autoUpload: false,
                autoRemove: false
            })
        } else if (this.state.autoUpload == false) {
            this.context.webSocketClient.publication.setUploadData({ provider_id: this.state.activeVodConnector.provider_id, user_id: this.state.activeVodConnector.user_id, pod_user: this.state.activePodUser === null ? -1 : this.state.activePodUser.id, auto: true, remove: this.state.autoRemove })
            this.setState({
                autoUpload: true
            })
        }
    }

    isLiveSelectedProvider() {
        if (this.state.activeLiveConnector) {
            return(
                <View style={[styles.row, styles.input]}>
                    <Text style={styles.text}>Enable live streaming</Text>
                    <Switch
                    maxIconSize={utils.moderateScale(50)}
                    onValueChange={() => {
                        this.upLive()
                    }}
                    value={this.state.liveOnOff}
                    backgroundActive='#02FF24'
                    renderActiveText={false}
                    renderInActiveText={false}
                    circleSize={utils.moderateScale(18)}
                    changeValueImmediately={true}
                    />
                </View>
            )
        }
    }

    isVodSelectedProvider() {
        if (this.state.activeVodConnector && (this.state.activeVodConnector.provider_id !== 7 || this.state.activePodUser !== null)) {
            return(
                <View>
                <View style={[styles.row, styles.input]}>
                    <Text style={styles.text}>Enable Auto-upload</Text>
                    <Switch
                        maxIconSize={utils.moderateScale(50)}
                        onValueChange={() => {
                            this.upVod()
                        }}
                        value={this.state.autoUpload}
                        backgroundActive='#02FF24'
                        renderActiveText={false}
                        renderInActiveText={false}
                        circleSize={utils.moderateScale(18)}
                        changeValueImmediately={true}
                    />
                    </View>
                    { this.state.autoUpload ? 
                        <View style={[styles.row, styles.input]}>
                            <Text style={styles.text}>Enable Auto-remove</Text>
                            <Switch
                                maxIconSize={utils.moderateScale(50)}
                                onValueChange={() => {
                                    this.upRemove()
                                }}
                                value={this.state.autoRemove}
                                backgroundActive='#02FF24'
                                renderActiveText={false}
                                renderInActiveText={false}
                                circleSize={utils.moderateScale(18)}
                                changeValueImmediately={true}
                            />
                        </View> : <></>
                    }
                    </View>
            )
        }
    }

    getIconFromId(id) {
        switch (id) {
            case 0: 
                return(require("../../../design/icons/publisher_icons/publisher_ftp.png"))
            case 1: 
                return(require("../../../design/icons/publisher_icons/publisher_youtube.png"))
            case 2: 
                return(require("../../../design/icons/publisher_icons/publisher_dropbox.png"))
            case 3: 
                return(require("../../../design/icons/publisher_icons/publisher_googledrive.png"))
            case 4:
                return(require("../../../design/icons/publisher_icons/publisher_facebook.png"))
            case 5:
                return(require("../../../design/icons/publisher_icons/rtmp.png"))
            case 6:
                return(require("../../../design/icons/publisher_icons/publisher_ftp.png"))
            default:
                return(require("../../../design/icons/publisher_icons/publisher_custom.png"))

        }
    }

    renderConnectorListItem(connector) {
        const _connector = connector.item
        const source = this.getIconFromId(_connector.provider_id)
        const index = this.state.connectors.indexOf(_connector)

        const backgroundColor = { backgroundColor: (index % 2 === 0) ? '#33333355' : '#333333' }
        const isValid = _connector.isValid

        let validationStateIcon = validIcon
        if (isValid === undefined) {
            validationStateIcon = unknownIcon
        } else if (!isValid) {
            validationStateIcon = invalidIcon
        }

        const padding = utils.moderateScale(1)

        return (
            <View style={[styles.row, {
                paddingVertical: utils.moderateScale(5),
                paddingHorizontal: utils.moderateScale(5),
                height: utils.moderateScale(50),
            }, backgroundColor]}
            >
                <View style={styles.row}>
                    <IconedButton
                        image={source}
                        style={[styles.rowImage, { marginRight: utils.moderateScale(10) }]}
                        maxIconSize={utils.moderateScale(30)}
                        aspectRatio={1}
                        isDisabled={true}
                    />
                    <Text style={[styles.text, {
                        paddingTop: 0
                    }]}>{_connector.name}</Text>
                </View>
                <View style={styles.row}>
                    {/*this.renderValidateButton(_connector)*/}
                    {this.renderEditButton(_connector)}
                    {this.renderDeleteButton(_connector)}
                </View>
            </View>
        )
    }

    renderProfiles() {
        if (this.state.connectors.length > 0) {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <FlatList
                        data={this.state.connectors}
                        renderItem={this.renderConnectorListItem.bind(this)}
                        keyExtractor={(item) => '' + item.id}
                    />
                </View>
            )
        } else {
            return (
                <TouchableOpacity
                    style={styles.button}
                    onPress={this.createNewConnector.bind(this)}
                    activeOpacity={1}
                >
                    <Text style={[styles.textGrey, { textAlign: 'center' }]}>
                        No profile(s) found.{'\r'}Please, create your first publication profile using the
                        <Text
                            style={{
                                fontWeight: 'bold',
                                color: '#00FF2490'
                            }}
                        > Add new profile... </Text>button.
                    </Text>
                </TouchableOpacity>
            )
        }
    }

    onPodUserSelected(_, user) {
        this.setState({
            activePodUser: user,
        })
        this.context.webSocketClient.publication.setUploadData({
            pod_user: user.id,
            provider_id: 7,
            user_id: -1,
            auto: this.state.autoUpload,
            remove: this.state.autoUpload ? this.state.autoRemove : false
        })
    }

    render() {
        const liveConnectors = this.state.connectors
        let str = JSON.stringify(this.state.num)

        return (
            <View style={styles.container}>

                {/* Edit overlay modal */}
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.state.editVisible}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-creator-modal-container"
                >
                    <KeyboardAvoidingView enabled behavior="padding" style={[styles.modalWrapper]}>
                        <View style={styles.modal}>
                            <EditContainer
                                data={this.state.editConnector}
                                onCancel={() => { this.setState({ editVisible: false })
                                                    this.context.webSocketClient.publication.getUsers() }}
                            />
                        </View>
                    </KeyboardAvoidingView>
                </ModalContainer>

                {/* Creation overlay modal */}
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.state.creatorVisible}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-creator-modal-container"
                >
                    <KeyboardAvoidingView enabled behavior="padding" style={[styles.modalWrapper]}>
                        <View style={styles.modal}>
                            <PublishingContainerCreator
                                data={this.state.connectorTypes}
                                onCancel={() => { this.setState({ creatorVisible: false })
                                                    this.context.webSocketClient.publication.getUsers() }}
                                onWebViewEnd={this.namingNewConnector.bind(this)}
                            />
                        </View>
                    </KeyboardAvoidingView>
                </ModalContainer>

                {/* Validation overlay modal */}
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.state.validationVisible}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-creator-modal-container"
                >
                    <View style={[styles.modalWrapper]}>
                        <View style={styles.modal}>
                            <PublishingContainerValidation
                                content={this.state.validationData}
                                onCancel={() => { this.setState({ validationVisible: false, validationData: null }) }}
                            />
                        </View>
                    </View>
                </ModalContainer>

                {/* Naming & Validation overlay modal */}
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.state.facebookSelector}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-creator-modal-container"
                >
                    <View style={[styles.modalWrapper]}>
                        <View style={[styles.modal, { maxHeight: '70%', maxWidth: '70%' }]}>
                            <FacebookContainer
                                onCancel={() => { this.setState({ facebookSelector: false, selectedProvider: null }) }}
                                selectedConnector={this.state.activeLiveConnector}
                                facebookPagesData={this.state.facebookPagesData}
                                liveOnOff={this.state.liveOnOff}
                            />
                        </View>
                    </View>
                </ModalContainer>
                
                <ModalContainer
                    animationType="fade"
                    transparent={true}
                    visible={this.state.namingVisible}
                    supportedOrientations={['portrait', 'landscape']}
                    onRequestClose={() => { }}
                    overlayClassName="services-creator-modal-container"
                >
                    <View style={[styles.modalWrapper]}>
                        <View style={[styles.modal, { maxHeight: '70%', maxWidth: '70%'}]}>
                            <NamingContainer
                                content={this.state.validationData}
                                onCancel={ this.onCancelNamingModal.bind(this) }
                                code={this.state.code}
                                selectedProvider={this.state.selectedProvider}
                            />
                        </View>
                    </View>
                </ModalContainer>

                <View style={[styles.formWrapper]}>
                    <Text style={[styles.text, styles.title]}>Live diffusion</Text>

                    <View style={[styles.row, styles.input]} className="option-publishing-container-active-profile-row">
                        <Text style={styles.text}>Select a Live Profile</Text>
                        <View style={{ flexDirection: 'row' }} className="option-publishing-container-active-profile">
                            <DropdownLive
                                defaultValue={(this.state.activeLiveConnector != null) ? this.state.activeLiveConnector.name + "   ▼" : "select connector   ▼"}
                                onSelect={this.onLiveConnectorSelect.bind(this)}
                                options={liveConnectors}
                                renderRowText={this.renderConnectorSelectText.bind(this)}
                                renderRowImage={this.state.showLiveDropdownIcon}
                                ref={"liveDropdown"}
                                disabled={liveConnectors.length === 0}
                            />
                        </View>
                    </View>
                    {this.isLiveSelectedProvider()}
                </View>

                <View style={[styles.secondFormWrapper]}>   

                    <View style={[styles.row, styles.input]} className="option-publishing-container-active-profile-row">
                        <Text style={styles.text}>Select an upload profile{str}</Text>
                        <View style={{ flexDirection: 'row' }} className="option-publishing-container-active-profile">
                            <DropdownVod
                                defaultValue={(this.state.activeVodConnector != null) ? this.state.activeVodConnector.name + "   ▼" : "select connector   ▼"}
                                onSelect={this.onVodConnectorSelect.bind(this)}
                                options={liveConnectors}
                                renderRowText={this.renderConnectorSelectText.bind(this)}
                                renderRowImage={this.state.showLiveDropdownIcon}
                                ref={"liveDropdown"}
                                disabled={liveConnectors.length === 0}
                            />
                            {this.state.activeVodConnector && this.state.activeVodConnector.provider_id === 7 ? (this.state.podUsers !== -1 ?
                                <DropdownPodUsers
                                    defaultValue={this.state.activePodUser !== null ? this.state.activePodUser.username + "   ▼" : "select user   ▼"}
                                    onSelect={this.onPodUserSelected.bind(this)}
                                    options={this.state.podUsers}
                                    renderRowText={(connector) => { return connector.name }}
                                    renderRowImage={true}
                                    ref={"liveDropdown"}
                                /> : <Text style={[styles.text, { textAlign: "center" }]}>No users found</Text>) : <></>
                            }
                        </View>
                    </View>
                    {this.isVodSelectedProvider()}
        </View>

                <Text style={[styles.text, styles.title, {
                    marginBottom: utils.moderateScale(20), marginTop: utils.moderateScale(25)
                }]}>Uploading/Live Profiles</Text>
                <ScrollView style={[styles.formWrapper]}>
                    {this.renderProfiles()}
                </ScrollView>

                <View style={styles.toolbar}>
                    <TouchableOpacity
                        style={styles.button}
                        onPress={this.createNewConnector.bind(this)}
                    >
                        <Text
                            style={styles.buttonText}
                        >Add new profile...</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
}

OptionsPublishingContainer.contextTypes = {
    webSocketClient: PropTypes.object,
    buildIsDemo: PropTypes.bool,
    boxIP: PropTypes.string
}

function mapStateToProps(state) {
    return {
        globalState: state.globalsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        globalsActions: bindActionCreators(globalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPublishingContainer)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#262626',
    },
    formWrapper: {
        marginTop: utils.moderateScale(0),
        margin: utils.moderateScale(10),
        flexShrink: 1
    },
    secondFormWrapper: {
        margin: utils.moderateScale(10),
        marginTop: utils.moderateScale(35),
        flexShrink: 1
    },
    textInput: {
        height: 30,
        borderWidth: 1,
        paddingVertical: utils.moderateScale(20),
        paddingHorizontal: utils.moderateScale(10)
    },
    text: {
        fontSize: utils.moderateScale(13),
        color: '#FFFFFF',
        paddingTop: utils.moderateScale(10)
    },
    textGrey: {
        fontSize: utils.moderateScale(13),
        color: '#555',
        paddingTop: utils.moderateScale(10)
    },
    title: {
        textAlign: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        flex: 1,
        margin: 0,
        width: 100,
        height: 100
    },
    toolbar: {
        backgroundColor: '#222222',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        padding: utils.moderateScale(10),
    },
    buttonText: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: utils.moderateScale(10),
        fontSize: utils.moderateScale(13)
    },
    buttonContainer: {
        backgroundColor: '#22222255',
        alignSelf: 'center',
        marginRight: utils.moderateScale(10),
        borderRadius: utils.moderateScale(10),
        height: utils.moderateScale(30),
        alignContent: 'center',
        justifyContent: 'center'
    },
    buttonText2: {
        color: '#FFFFFF',
        fontSize: utils.moderateScale(12),
    },
    global: {
        color: '#FFFFFF'
    },
    input: {
        marginTop: utils.moderateScale(2)
    },
    modalStyle: {
        backgroundColor: 'gray',
        height: utils.moderateScale(20),
        justifyContent: "center",
        paddingHorizontal: utils.moderateScale(20),
        borderRadius: 30
    },
    modalTextStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        fontSize: utils.moderateScale(10)
    },
    modalDropdownTextStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        fontSize: utils.moderateScale(12),
        padding: utils.moderateScale(5)
    },
    modalDropdownStyle: {
        backgroundColor: '#333333',
        borderColor: '#444444'
    },

    rowImage: {
        paddingRight: utils.moderateScale(15),
        maxHeight: utils.moderateScale(30),
        alignSelf: "center",
        padding: 0,
    },

    modalWrapper: {
        justifyContent: 'center',
        alignContent: 'center',
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#000000AA'
    },

    modal: {
        flex: 1,
        maxWidth: '90%',
        maxHeight: '90%',
        alignSelf: 'center'
    },
})