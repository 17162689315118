import * as types from '../constants/camera'

const initialState = {
    currentView: undefined,
    setTrackingActive: false
}

export default function cameraReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.VIEW_SPEAKER:
            return { ...state, currentView: types.VIEW_SPEAKER }

        case types.VIEW_MEDIUM:
            return { ...state, currentView: types.VIEW_MEDIUM }

        case types.VIEW_FULL:
            return { ...state, currentView: types.VIEW_FULL }

        case types.SET_TRACKING:
            return { ...state, setTrackingActive: action.setTrackingActive}

        default:
            return state || initialState;
    }
}